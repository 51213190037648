import styled, { css } from "styled-components";

interface IInstallmentsPaidProps {
	paid: number;
	cancelled?: boolean;
}

interface IProps {
	primary_color?: string;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: space-between;
	height: 100vh;
`;

export const Content = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	max-width: 1273px;
	width: 100%;
	margin: auto;
	padding: 44px;
	gap: 20px;
	font-size: 14px;

	@media (max-width: 645px) {
		padding: 24px;
	}
`;

export const LinkDetailsContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: 14px;
	padding: 16px 20px;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
	color: #526484;

	h4 {
		width: 100%;
		font-weight: normal;
		margin-top: 15px;
		margin-bottom: 10px;
	}

	h4 + p {
		font-weight: bold;
		font-size: 16px;
	}

	hr {
		border: 0;
		margin: 20px 0px 20px 0px;
		height: 0.15px;
		background: #526484;
	}

	.goBackBtn {
		width: 100%;
		margin-bottom: 15px;
		p {
			width: fit-content;
			display: flex;
			color: #8094ae;
			font-size: 16px;
			cursor: pointer;
		}
		svg {
			align-self: center;
			font-size: 18px;
			margin-right: 5px;
		}
		:hover {
			font-weight: 600;
			transition: 0.1s;
			svg {
				transform: scale(1.2);
				transition: 0.2s ease-in-out;
			}
		}
	}

	.linkHeader {
		width: 100%;
		font-size: 18px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: 10px;

		.linkStatus {
			width: fit-content;
			height: fit-content;
			position: relative;
			font-weight: 650;
			margin-left: 20%;
			font-size: 16px;
			margin-top: 6px;

			span:first-child {
				content: "";
				position: absolute;
				top: 50%;
				left: -50%;
				transform: translateY(-50%);
				width: 10px;
				height: 10px;
				border-radius: 50%;
			}
		}

		section {
			display: flex;
			flex-wrap: wrap;
			h2 {
				width: 100%;
			}
			.createdAt {
				margin-top: 10px;
				margin-bottom: 10px;
				font-size: 12.5px;
				font-weight: lighter;
				color: #757575;
			}
		}

		button {
			width: fit-content;
			cursor: pointer;
			padding: 5px 10px;
			border: 0;
			border-radius: 10px;
			background: #f5f6fa;
			color: #364a63;
			transition: 0.4s;

			:hover {
				background: #e85347;
				color: #fff;
			}
			svg {
				font-size: 20px;
				vertical-align: middle;
				margin-left: 5px;
			}
		}
		.inactive {
			:hover {
				background: #f5f6fa;
				color: inherit;
			}
		}
	}

	#linkArea {
		display: flex;
		cursor: pointer;
		flex-direction: row;
		width: fit-content;

		:hover {
			input {
				transition: 0.2s ease-in-out;
				color: #0096ff;
			}
			svg {
				transition: 0.2s ease-in-out;
				transform: scale(1.2);
			}
		}
		input {
			cursor: pointer;
			font-size: 16px;
			background-color: transparent;
			justify-content: center;
			width: 400px;
			border: 0;
			color: #526484;
			font-weight: 900;
			margin: 0;
			padding: 0;
		}

		svg {
			margin-right: 10px;
			font-size: 18px;
			color: #526484;
		}
	}

	#whatsAppLink {
		cursor: pointer;
		margin-top: 5px;
		width: fit-content;

		svg {
			margin-left: 5px;
			width: 20px;
			height: 20px;
			vertical-align: middle;
		}
		:hover {
			transition: 0.2s ease-in-out;
			font-weight: 600;
			svg {
				transition: 0.2s ease-in-out;
				transform: scale(1.2);
			}
		}
	}

	.LinkDetails {
		display: flex;
		flex-direction: row;
		gap: 20px;

		@media (max-width: 500px) {
			flex-direction: column;
		}

		section {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 30px;

			:first-child {
				border-bottom: 3px solid #1ee0ac;
			}

			:nth-child(2) {
				display: flex;
				justify-content: flex-start;

				div:nth-child(2) {
					display: flex;
					flex-direction: row;

					div {
						display: flex;
						flex-direction: column;

						p {
							margin: 0;
							padding: 0;
							font-weight: 900;
						}
					}
				}
			}

			.LinkOptions {
				display: flex;
				flex-direction: column;

				p {
					padding: 0;
					margin: 0;
					font-weight: 900;
				}
			}

			.paymentMethod {
				p {
					padding: 0;
					margin: 0;
					font-weight: 900;
				}
			}
		}
	}
`;

export const LinkTransactionsContent = styled.div<IProps>`
	display: flex;
	width: 100%;
	margin-right: 20px;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
	color: #526484;

	.exportExcel {
		margin-bottom: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		p {
			font-size: 14px;
			color: #8094ae;
		}
		.btnExcel {
			svg {
				margin-left: 10px;
			}

			:hover {
				background: #ccd3e5;
				color: #003967;
			}
		}
	}

	button {
		background: #f7f8fc;
		border: 1px solid #c3cce1;
		font-size: 12px;
		color: #526484;
		border: 0px;
		padding: 5px 10px;
		border-radius: 10px;
		cursor: pointer;
		transition: 0.2s ease-in-out;
		display: flex;
		align-items: center;

		svg {
			margin: 0px;
			font-size: 15px;
		}
	}
	.btnForPages {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
		font-size: 12px;
		color: #526484;

		.btnPrevious,
		.btnNext {
			:hover {
				background: #ccd3e5;
				color: #003967;
			}
		}

		.btnDefault {
			cursor: default;
		}

		@media (max-width: 500px) {
			svg {
				padding: 0;
				margin: 0;
			}

			.btnPrevious,
			.btnNext,
			.btnDefault {
				p {
					display: none;
				}
			}
		}
	}
`;

export const TransactionsList = styled.div`
	@media (max-width: 1250px) {
		overflow-x: auto;
	}
	overflow-x: auto;
	table {
		border-collapse: collapse;
		border-spacing: 0;
		table-layout: auto;
		width: fit-content;
		font-size: 14px;

		@media (max-width: 568px) {
			font-size: 12px;
		}

		font-weight: lighter;
		color: #526484;
		transition: 0.2s;

		tr {
			display: flex;
			align-items: center;
			padding: 5px;
			border-bottom: 1px solid #e5e9f2;

			:hover {
				font-weight: 900;
			}

			:first-child {
				text-transform: capitalize;
			}

			:nth-child(n + 2) {
				cursor: pointer;
			}

			:last-child {
				border: 0px;
				margin-bottom: 10px;
			}

			img {
				width: 30px;
				vertical-align: middle;
			}

			td,
			th {
				text-align: center;
				width: 120px;
				padding: 10px;
				font-weight: lighter;
			}

			div {
				position: relative;

				span {
					content: "";
					position: absolute;
					width: 6px;
					height: 6px;
					left: 12px;
					top: 50%;
					transform: translateY(-50%);
					border-radius: 50%;
				}
			}

			td:last-child {
				position: relative;
			}
		}
	}

	.noSales {
		width: 100%;
		margin-bottom: 20px;
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		p {
			text-align: center;
			width: 100%;
		}
		@media (max-width: 500px) {
			justify-content: left;
		}
	}
`;
export const InstallmentsPaid = styled.p<IInstallmentsPaidProps>`
	width: 100%;
	content: "";
	position: absolute;
	height: 5px;
	bottom: 0;
	left: 0;
	border: 0px;
	border-radius: 20px;

	${(props) =>
		!props.cancelled
			? css`
					background: #ddd;
			  `
			: css`
					background: #e85347;
			  `}

	:after {
		${(props) =>
			props.paid &&
			css`
				width: ${props.paid}%;
			`}

		content:'';
		position: absolute;
		height: 100%;
		top: 0px;
		left: 0px;
		border: 0px;
		border-radius: 20px;
		background: #526484;
	}
`;
