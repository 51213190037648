/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Children, useCallback, useEffect, useState } from "react";
import {
	Container,
	Content,
	FilterSection,
	CreateLink,
	Filter,
	ModuleLink,
	LinksContent,
} from "./styles";
import HeaderComponent from "../../components/Header";
import FooterComponent from "../../components/Footer";
import LoaderComponent from "../../components/Loader";
import Button from "../../components/Button";
import { AiOutlineDownload } from "react-icons/ai";
import { useToast } from "../../hooks/toast";
import { useHistory } from "react-router-dom";
import moment from "moment";
import formatValue from "../../utils/formatValue";
import { FiLink, FiSearch } from "react-icons/fi";
import LinkModal from "../../components/LinkModalPayment";
import { BiFirstPage, BiLastPage } from "react-icons/bi";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import api from "../../services/api";
import downloadFile from "../../utils/downloadCSVFile";

interface ILinkData {
	status: string;
	link: string;
	id: string;
	clientMerchantID: string;
	code: string;
	createdAt: string;
	description: string;
	expirationAt: string;
	fee: number;
	installments: number;
	isActive: boolean;
	customerName: string;
	transactionList: null;
	amount: number;
	isPaid: boolean;
}

interface IFilterObj {
	startDate: string | null;
	endDate: string | null;
	expireStartDate: string | null;
	expireEndDate: string | null;
	description: string | null;
	isActive: boolean | null;
	hasExpiration: boolean | null;
}

const LinksPage: React.FC = () => {
	const history = useHistory();
	const { addToast } = useToast();
	//PAGE VALUES
	const [loading, setLoading] = useState(false);
	const [loadingExcel, setLoadingExcel] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [linkData, setLinkData] = useState<ILinkData[]>([]);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [totalRows, setTotalRows] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [orderDir, setOrderDir] = useState("desc");

	//FILTER
	const [filterObj, setFilterObj] = useState<IFilterObj>();
	const [description, setDescription] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [expirationDateStart, setExpirationDateStart] = useState("");
	const [expirationDateEnd, setExpirationDateEnd] = useState("");
	const [hasExpireDate, setHasExpireDate] = useState(true);
	const [activationStatus, setActivationStatus] = useState("");

	const toggleModal = useCallback(() => {
		if (document.documentElement.style.overflowY === "hidden")
			document.documentElement.style.overflowY = "scroll";
		else document.documentElement.style.overflowY = "hidden";
		setModalOpen(!modalOpen);
	}, [modalOpen, setModalOpen]);

	useEffect(() => {
		try {
			setLoading(true);
			api
				.get("/pagamentos/links/", {
					params: {
						page: 1,
						orderDir: "desc",
						perPage: 10,
					},
				})
				.then((response) => {
					setPage(response.data.currentPage);
					setTotalPages(response.data.pageCount);
					setTotalRows(response.data.rowCount);
					setLinkData(response.data.items);
				})
				.finally(() => setLoading(false));
		} catch (err) {
			addToast({
				title: "Erro ao carregar lista de links de pagamentos",
				description:
					"Ocorreu um erro ao processar sua requisição, tente novamente mais tarde.",
				type: "error",
			});
		}
	}, [addToast, perPage]);

	const urlHandler = useCallback(
		(id: any) => {
			history.push({
				pathname: `/detalhes-link/${id}`,
				state: id,
			});
		},
		[history]
	);

	const handlePageChange = useCallback(
		(value) => {
			if (value <= 0 || value > totalPages) return;
			setPage(value);
			try {
				setLoading(true);
				!!filterObj
					? fetchApi(value, perPage, orderDir, filterObj)
					: fetchApi(value, perPage, orderDir);
			} catch (err) {
				addToast({
					type: "error",
					title: "Erro ao atualizar informações",
					description:
						"Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
				});
			}
		},
		[addToast, fetchApi, totalPages, filterObj, orderDir, perPage]
	);

	const handleFilterLinkList = useCallback(() => {
		setLoading(true);
		let filter: IFilterObj;
		setPage(1);
		filter = {
			isActive:
				activationStatus === "isActive"
					? true
					: activationStatus === "isInactive"
					? false
					: null,
			description: description,
			endDate: endDate,
			expireEndDate: expirationDateEnd,
			expireStartDate: expirationDateStart,
			hasExpiration: hasExpireDate,
			startDate: startDate,
		};

		setFilterObj(filter);

		fetchApi(1, perPage, orderDir, filter);
	}, [
		activationStatus,
		description,
		fetchApi,
		expirationDateStart,
		expirationDateEnd,
		endDate,
		startDate,
		perPage,
		hasExpireDate,
		orderDir,
		setPage,
	]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	function fetchApi(
		page: number,
		perPage: number,
		orderDir: string,
		filter?: IFilterObj
	) {
		let apiFilter = filter;
		if (!!filter) apiFilter = filter;
		else apiFilter = filterObj;

		api
			.get(
				`/pagamentos/links/?page=${page}&orderDir=${orderDir}&perPage=${perPage}`,
				{
					params: {
						isActive: apiFilter?.isActive,
						description: apiFilter?.description,
						endDate: apiFilter?.endDate,
						expireDateStart: apiFilter?.expireStartDate,
						expireDateEnd: apiFilter?.expireEndDate,
						hasExpiration: apiFilter?.hasExpiration,
						initialDate: apiFilter?.startDate,
					},
				}
			)
			.then((response) => {
				setPage(response.data.currentPage);
				setTotalPages(response.data.pageCount);
				setTotalRows(response.data.rowCount);
				setLinkData(response.data.items);
			})
			.finally(() => setLoading(false));
	}

	const getTransactionData = async () => {
		let apiFilter = filterObj;

		try {
			setLoadingExcel(true);
			await api
				.get("/pagamentos/links/report/", {
					responseType: "blob",
					params: {
						isActive: apiFilter?.isActive,
						description: apiFilter?.description,
						initialDate: apiFilter?.startDate,
						endDate: apiFilter?.endDate,
						expireDateStart: apiFilter?.expireStartDate,
						expireDateEnd: apiFilter?.expireEndDate,
						hasExpiration: apiFilter?.hasExpiration,
					},
				})
				.then((response) => {
					!!apiFilter?.startDate && !!apiFilter?.endDate
						? downloadFile(
								`links${apiFilter?.startDate}_a_${apiFilter?.endDate}.xlsx`,
								response
						  )
						: downloadFile(
								`links${moment().format("DD-MM-YYYY")}.xlsx`,
								response
						  );
				})
				.finally(() => {
					setLoadingExcel(false);
				});
		} catch (err) {
			addToast({
				type: "error",
				title: "Erro ao baixar informações",
				description:
					"Ocorreu um erro ao baixar a planilha, tente novamente mais tarde.",
			});
		}
	};

	const refreshLinkData = useCallback(
		(data: ILinkData) => {
			setLinkData((state) => [data, ...state]);
			setTotalRows(totalRows + 1);
			setPage(1);
			setTotalPages(Math.ceil((totalRows + 1) / perPage));
		},
		[perPage, totalRows]
	);

	return (
		<>
			<Container>
				<LinkModal
					setIsOpen={toggleModal}
					isOpen={modalOpen}
					refreshLinkData={refreshLinkData}
				/>
				<HeaderComponent linkValue={"links"} />
				<Content>
					<FilterSection>
						<CreateLink>
							<p className="title">LINKS DE PAGAMENTO</p>
							<p>Crie e compartilhe links de pagamento com os seus clientes.</p>
							<Button onClick={toggleModal}>
								Criar link de pagamento <FiLink />
							</Button>
						</CreateLink>
						<Filter>
							<span>Filtre seus links</span>
							<div className="customFilters">
								<div className="customDate">
									<p>Data de criação</p>
									<div>
										<label>De:</label>
										<input
											type="date"
											value={startDate}
											onChange={(e) =>
												setStartDate(
													moment(e.currentTarget.value).format("YYYY-MM-DD")
												)
											}
										/>
									</div>

									<div>
										<label>Até:</label>
										<input
											type="date"
											value={endDate}
											onChange={(e) =>
												setEndDate(
													moment(e.currentTarget.value).format("YYYY-MM-DD")
												)
											}
										/>
									</div>
								</div>
								<div className="customDescription">
									<p>Descrição do link</p>
									<div>
										<FiSearch />
										<input
											type="text"
											placeholder="Filtre pela descrição do link"
											onChange={(e) => {
												setDescription(e.currentTarget.value);
											}}
											value={description}
										></input>
									</div>
								</div>
								<div className="customActivation">
									<p>Status</p>
									<select
										value={activationStatus}
										onChange={(e) => {
											setActivationStatus(e.currentTarget.value);
										}}
									>
										<option value="">Todos</option>
										<option value="isActive">Ativos</option>
										<option value="isInactive">Inativos</option>
									</select>
								</div>

								<div className="expirationSection">
									<div className="hasExpiration">
										<p>Data de Expiração</p>
										<input
											id="activation"
											name="activation"
											checked={!hasExpireDate}
											onChange={() => {
												setHasExpireDate(!hasExpireDate);
												setExpirationDateStart("");
												setExpirationDateEnd("");
											}}
											type="checkbox"
										/>
										<label htmlFor="activation">Sem data de expiração</label>
									</div>
									{hasExpireDate && (
										<div className="expirationDate">
											<div>
												<label>De:</label>
												<input
													type="date"
													value={expirationDateStart}
													onChange={(e) =>
														setExpirationDateStart(
															moment(e.currentTarget.value).format("YYYY-MM-DD")
														)
													}
												/>
											</div>

											<div>
												<label>Até:</label>
												<input
													type="date"
													value={expirationDateEnd}
													onChange={(e) =>
														setExpirationDateEnd(
															moment(e.currentTarget.value).format("YYYY-MM-DD")
														)
													}
												/>
											</div>
										</div>
									)}
								</div>
								<div>
									<button
										onClick={() => {
											handleFilterLinkList();
										}}
									>
										Filtrar
									</button>
								</div>
							</div>
						</Filter>
					</FilterSection>
					<ModuleLink>
						<div className="exportExcel">
							<p>Meus links de pagamento</p>
							<Button
								className="btnExcel"
								loading={loadingExcel}
								onClick={() => {
									getTransactionData();
								}}
							>
								Exportar Excel <AiOutlineDownload />
							</Button>
						</div>
						<LinksContent>
							<table>
								<tbody>
									<tr>
										<th>Status</th>
										<th>Cobrar de</th>
										<th>Descrição do Link</th>
										<th>Data de Criação</th>
										<th>Data de Expiração</th>
										<th>URL do Link</th>
										<th>Valor</th>
										<th>Parcelas</th>
									</tr>

									{linkData.map((value) => (
										<tr key={value.id} onClick={() => urlHandler(value.id)}>
											<td>
												{value.isActive ? (
													<span style={{ background: "#1ee0ac" }} />
												) : (
													<span style={{ background: "#f00" }} />
												)}
												<span>{value.isActive ? "Ativo" : "Inativo"}</span>
											</td>
											<td>{value.customerName ? value.customerName : "-"}</td>
											<td>{value.description ? value.description : "-"}</td>
											<td>{moment(value.createdAt).format("DD/MM/YYYY")}</td>
											<td>
												{value.expirationAt
													? moment(value.expirationAt).format("DD/MM/YYYY")
													: "Sem data de expiração"}
											</td>
											<td>{value.link}</td>
											<td>{formatValue(value.amount)}</td>
											<td>{value.installments}x</td>
										</tr>
									))}
								</tbody>
							</table>
						</LinksContent>
						<div className="btnForPages">
							<button
								onClick={() => {
									if (page !== 1) handlePageChange(1);
								}}
								className={page === 1 ? "btnDefault" : "btnNext"}
							>
								<BiFirstPage /> <p>Primeira Página</p>
							</button>

							<button
								onClick={() => handlePageChange(page - 1)}
								className={page === 1 ? "btnDefault" : "btnNext"}
							>
								<GrFormPrevious /> <p>Anterior</p>
							</button>

							<p>
								Página <span>{page}</span> de <span>{totalPages}</span>{" "}
							</p>

							<button
								onClick={() => handlePageChange(page + 1)}
								className={totalPages === page ? "btnDefault" : "btnNext"}
							>
								<p>Próximo</p> <GrFormNext />
							</button>
							<button
								onClick={() => {
									if (page !== totalPages) handlePageChange(totalPages);
								}}
								className={totalPages === page ? "btnDefault" : "btnNext"}
							>
								<p>Última página</p> <BiLastPage />
							</button>
						</div>
					</ModuleLink>
				</Content>
				<FooterComponent />
			</Container>
		</>
	);
};

export default LinksPage;
