import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
	Container,
	Content,
	ModuleAccount,
	ModuleTop,
	ModuleInfo,
	LastSales,
	FilterExtract,
	ModuleGraphic,
	ModuleTable,
} from "./styles";
import HeaderComponent from "../../components/Header";

import Carousel from "../../components/Carousel";
import FooterComponent from "../../components/Footer";
import api from "../../services/api";
import formatValue from "../../utils/formatValue";
import { useAuth } from "../../hooks/auth";
import { cnpjMask } from "../../utils/masks";
import moment from "moment";
import Button from "../../components/Button";
import { useToast } from "../../hooks/toast";
import { FiRefreshCw } from "react-icons/fi";
import ICompany from "../../@types/company";
import generateTheme from "../../utils/generateTheme";
import { redirectOnlyUserSale } from "../../utils/verifyOnlySaleUser";
import { Chart } from "../../components/DashboardChart/indext";
import TableReceive from "../../components/Table/TableReceive";
import LastSalesContentData from "../../components/LastSalesContentData";

interface ICarouselData {
	title: string;
	description: string;
	content: string;
	isActive: boolean;
	id: number;
}

interface ISales {
	PaidAmount: number;
	date: string;
	grossAmount: number;
	type_sale: string;
	id: string;
	installments: number;
	brand: string;
	status: string;
}

interface IWeekPaymentAgenda {
	date: string;
	active: boolean;
	adjustments: number;
	advance: number;
	chargebacks: string;
	boleto: number;
	status: string;
	paid: number;
	refunds: number;
	sales: number;
}

interface IIndexData {
	brands: IBrands[];
	ticket_medio: number;
	extract: IExtract;
}

interface IBrands {
	name: string;
	value: number;
}

interface IExtract {
	already_received: number;
	to_receive: number;
	refunded: number;
	received: number;
	captured: number;
}

interface IChartData {
	id: string;
	label: string;
	value: number;
	color?: string;
}

interface IMedia {
	height: number;
	width: number;
	desktop: boolean;
}

type Filter =
{
	label: string
	type: string
	startDate: string
	endDate: string
}

const filters: Filter[] = [
	{
		label: 'Este Mês',
		type: 'this_month',
		startDate: moment().startOf('month').format("YYYY-MM-DD"),
		endDate: moment().endOf('month').format("YYYY-MM-DD"),
	},
	{
		label: 'Hoje',
		type: 'today',
		startDate: moment().startOf('day').format("YYYY-MM-DD"),
		endDate: moment().endOf('day').format("YYYY-MM-DD"),
	},
	{
		label: 'Ontem',
		type: 'yesterday',
		startDate: moment().subtract(1, 'day').startOf('day').format("YYYY-MM-DD"),
		endDate: moment().subtract(1, 'day').endOf('day').format("YYYY-MM-DD"),
	},
	{
		label: 'Ultimos 7 dias',
		type: 'week',
		startDate: moment().subtract(7, 'day').startOf('day').format("YYYY-MM-DD"),
		endDate: moment().endOf('day').format("YYYY-MM-DD")
	},
	{
		label: 'Mês Passado',
		type: 'prev_month',
		startDate: moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD"),
		endDate: moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD")
	},
	{
		label: 'Customizado',
		type: 'custom',
		startDate: '',
		endDate: ''
	}
]

const defaultFilter = {
	label: 'Ultimos 7 dias',
	type: 'week',
	startDate: moment().subtract(7, 'day').startOf('day').format("YYYY-MM-DD"),
	endDate: moment().endOf('day').format("YYYY-MM-DD")
};

const Dashboard: React.FC = () => {
	const { user } = useAuth();
	const history = useHistory();
	const { addToast } = useToast();
	const [weekPaymentAgenda, setWeekPaymentAgenda] = useState<
		IWeekPaymentAgenda[]
	>([]);
	const [loadingLastSales, setLoadingLastSales] = useState(true);
	const [loadingWeekPayment, setLoadingWeekPayment] = useState(true);
	const [loadingChart, setLoadingChart] = useState(true);
	const [carouselData, setCaroulselData] = useState<ICarouselData[]>([]);
	const [chartData, setChartData] = useState<IChartData[]>([]);

	const [extractFilter, setExtractFilter] = useState<Filter>(defaultFilter)
	const [lastSales, setLastSales] = useState<ISales[]>([]);
	const [link, setLink] = useState<ICompany>();
	const [chartMediaQuery, setChartMediaQuery] = useState<IMedia>({
		height: 400,
		width: 440,
		desktop: true,
	});

	const fetchWeekPaymentAgenda = useCallback(() => {
		api
			.get("/resumo/agenda")
			.then((response) => {
				setWeekPaymentAgenda(response.data);
			})
			.finally(() => setLoadingWeekPayment(false));
	}, []);

	const fetchExtractAPI = useCallback(
		(startOfMonth: string, endOfMonth: string) => {
			setLoadingChart(true);
			try {
				api
					.get<IIndexData>("/resumo/indices", {
						params: {
							start_date: startOfMonth,
							end_date: endOfMonth,
						},
					})
					.then((response) => {
						setChartData(() => {
							const newState = response.data.brands.map((value) => ({
								id: value.name,
								label: value.name,
								value: value.value,
							}));
							return newState;
						});
						setCaroulselData([
							{
								title: "Extrato",
								description: "Capturado",
								content: response.data.extract.captured
									? formatValue(response.data.extract.captured)
									: "R$ 0,00",
								isActive: true,
								id: 0,
							},
							{
								title: "Extrato",
								description: "Recebido",
								content: response.data.extract.received
									? formatValue(response.data.extract.received)
									: "R$ 0,00",
								isActive: false,
								id: 1,
							},
							{
								title: "Extrato",
								description: "Cancelado",
								content: response.data.extract.refunded
									? formatValue(response.data.extract.refunded)
									: "R$ 0,00",
								isActive: false,
								id: 2,
							},
							{
								title: "Saldo",
								description: "A receber",
								content: response.data.extract.to_receive
									? formatValue(response.data.extract.to_receive)
									: "R$ 0,00",
								isActive: false,
								id: 3,
							},
							{
								title: "Saldo",
								description: "Total já recebido",
								content: response.data.extract.already_received
									? formatValue(response.data.extract.already_received)
									: "R$ 0,00",
								isActive: false,
								id: 4,
							},
							{
								title: "Transações",
								description: "Ticket médio",
								content: response.data.ticket_medio
									? formatValue(response.data.ticket_medio)
									: "R$ 0,00",
								isActive: false,
								id: 5,
							},
						]);
					})
					.finally(() => setLoadingChart(false));
			} catch (err) {
				addToast({
					type: "error",
					title: "Erro ao atualizar informações",
					description:
						"Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
				});
			}
		},
		[addToast]
	);

	const handleExtractFilterSelection = useCallback(
		(value: Filter) => {
			setExtractFilter(value);
			fetchExtractAPI(value.startDate, value.endDate);		
		},
		[]
	);



	const handleRefreshSales = useCallback(() => {
		//REAL TIME SALES
		setLoadingLastSales(true);

		api
			.get("/vendas", {
				params: {
					perpage: 5,
					page: 1,
					dateend: moment().format('YYYY-MM-DD'),
					datestart: moment().subtract(7, 'day').format('YYYY-MM-DD'),
					date: "thisMonth",
					orderdirection: "desc",
					orderby: "date",
					dashboard: false
				},
			})
			.then((response) => {
				setLastSales(response.data.result.items || []);
			})
			.finally(() => {
				setLoadingLastSales(false);
			});
	}, []);

	useEffect(() => {
		const mediaQuery = window.matchMedia("(max-width: 645px)");
		setChartMediaQuery(() => {
			if (mediaQuery.matches) {
				return {
					height: 300,
					width: 360,
					desktop: false,
				};
			} else {
				return {
					height: 400,
					width: 440,
					desktop: true,
				};
			}
		});

		handleRefreshSales()
	}, []);

	useEffect(() => {
		redirectOnlyUserSale(history, user);
		setLink(generateTheme);

		try {
			const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
			const today = moment().format("YYYY-MM-DD");

			fetchWeekPaymentAgenda();
			fetchExtractAPI(startOfMonth, today);
		} catch (err) {
			addToast({
				type: "error",
				title: "Erro ao atualizar informações de perfil.",
				description:
					"Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
			});
		}
	}, [
		addToast,
		history,
		fetchExtractAPI,
		fetchWeekPaymentAgenda,
		user.only_sale,
		user,
	]);

	return (
		<Container>
			<HeaderComponent linkValue={"dashboard"} />

			<Content>
				<ModuleTop>
					<ModuleAccount>
						<p>Bem vindo!</p>
						<h1>{user.name}</h1>
						<p>{cnpjMask(user.tax_id)}</p>
					</ModuleAccount>
					<FilterExtract>
						<div className="form-group">
							<label>Filtrar Extrato</label>
							<select
								value={extractFilter.type}
								onChange={(e) =>{		
									const type = e.currentTarget.value;
									const filter = filters.find(x => x.type == type);		
									if(!filter) return;								
									handleExtractFilterSelection(filter)
								}}
							>
								{
									filters.map(x => (
										<option key={x.type} value={x.type}>{x.label}</option>
									))
								}
							</select>
						</div>
						{ extractFilter.type === 'custom' && (
							<div className="custom-date"> 
								<div className="form-group">
									<label>Data inicial</label>
									<input
										type="date"
										value={extractFilter.startDate}
										alt="abrir o calendário"
										onChange={(e) => setExtractFilter(old => ({ ...old, startDate: e.target.value})) }
									/>
								</div>
								<div className="form-group">
									<label>Data Final</label>
									<input
										type="date"
										value={extractFilter.endDate}
										onChange={(e) => setExtractFilter(old => ({ ...old, endDate: e.target.value})) }
									/>
								</div>
								<Button type="submit" onClick={() => fetchExtractAPI(extractFilter.startDate, extractFilter.endDate)}>
									Filtrar
								</Button>
							</div>
						)}
					</FilterExtract>
					<Carousel data={carouselData} />
				</ModuleTop>
				<ModuleInfo>
					<LastSales primary_color={link?.style.primary_color}>
						<div className="widgetNext">
							<p>Últimas vendas</p>
							<FiRefreshCw
								className="refreshSales"
								title="Atualizar novas vendas"
								onClick={handleRefreshSales}
							/>
						</div>

						<LastSalesContentData
							loading={loadingLastSales}
							lastSales={lastSales}
						/>

						{lastSales.length > 0 && (
							<div className="seeMore" title="Veja mais">
								<Link to="/vendas">ver mais &gt;</Link>
							</div>
						)}
					</LastSales>
					<ModuleGraphic>
						<div className="widgetNext">
							<p>Bandeiras por Transação</p>
						</div>

						<Chart
							loading={loadingChart}
							chart={chartData}
							chartMediaQuery={chartMediaQuery}
							total={chartData.reduce((sum, li) => sum + li.value, 0)}
						/>
					</ModuleGraphic>
				</ModuleInfo>
				<ModuleTable primary_color={link?.style.primary_color}>
					<div className="widgetNext">
						<p>Agenda de recebimentos</p>
					</div>
					<TableReceive
						weekPaymentAgenda={weekPaymentAgenda}
						loading={loadingWeekPayment}
					/>
				</ModuleTable>
			</Content>
			<FooterComponent />
		</Container>
	);
};

export default Dashboard;
