import styled from "styled-components";

export const Content = styled.footer`
	position: relative;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: transparent;
	backdrop-filter: blur(2px);

	p {
		font-family: "Nunito";
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		width: fit-content;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: rgb(0, 57, 103);
		font-size: 16px;
		font-style: italic;

		svg {
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			margin-bottom: 20px;
		}

		:before {
			position: relative;
			content: "";
			white-space: pre-line;
			width: 25px;
			height: 25px;
			top: 27px;
			left: 39%;
			border: 4px solid;
			border-color: #fff;
			vertical-align: middle;
			border-top-color: rgba(0, 57, 103, 0.5);
			border-bottom-color: rgba(0, 57, 103, 0.5);
			border-radius: 50%;
			animation: rotateLoader 1s ease-in-out infinite;
		}

		@keyframes rotateLoader {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}
`;

export const ContentSpinner = styled.div`
	div {
		font-family: "Nunito";
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		transform: translate(-50%, -50%);

		svg {
			position: relative;
			left: 60%;
			transform: translateX(-50%);
		}

		:before {
			position: relative;
			content: "";
			white-space: pre-line;
			width: 25px;
			height: 25px;
			top: 27px;
			left: 39%;
			border: 4px solid;
			border-color: #fff;
			vertical-align: middle;
			border-top-color: rgba(0, 57, 103, 0.5);
			border-bottom-color: rgba(0, 57, 103, 0.5);
			border-radius: 50%;
			animation: rotateLoader 1s ease-in-out infinite;
		}

		@keyframes rotateLoader {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}
`;
