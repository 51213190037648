import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ModalContainer,
  LinkContainer,
  LinkHeader,
  LinkContent,
  LinkSuccess,
} from "./styles";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { FormHandles } from "@unform/core";
import {
  FiCheckCircle,
  FiCopy,
  FiCreditCard,
  FiUser,
  FiX,
} from "react-icons/fi";
import Button from "../Button";
import Input from "../Input";
import { RiBillLine, RiWhatsappLine } from "react-icons/ri";
import getValidationErrors from "../../utils/getValidationErrors";
import { useToast } from "../../hooks/toast";
import { formatLinkValue } from "../../utils/valueLinkMask";
import "moment/locale/pt-br";
import moment from "moment";
import api from "../../services/api";
import formatValue from "../../utils/formatValue";

interface IInputProps {
  isOpen: boolean;
  setIsOpen: () => void;
  refreshLinkData: any;
}

interface ILinkFormData {
  value: string;
  description: string;
  payer: string;
  hasExpiration: boolean;
  installments: number;
  expiration: string;
  creationDate: string;
}

interface IInstallmentsData {
  value: string;
  installment: number;
}

const LinkModal: React.FC<IInputProps> = ({
  isOpen,
  setIsOpen,
  refreshLinkData,
}: IInputProps) => {
  const formRef = useRef<FormHandles>(null);
  const linkRef = useRef<HTMLInputElement>(null);

  moment.locale("pt-br");

  const { addToast } = useToast();
  const [modalStatus, setModalStatus] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  const [linkValue, setLinkValue] = useState("");
  const [linkExpirationMessage, setLinkExpirationMessage] = useState("");
  const [linkCreated, setLinkCreated] = useState(false);
  const [expireDate, setExpireDate] = useState("");
  const [createdLinkUrl, setCreatedLinkUrl] = useState("");
  const [hasExpiration, setHasExpiration] = useState(false);
  const [installments, setInstallments] = useState(1);
  const [installmentsValue, setInstallmentsValue] = useState<
    IInstallmentsData[]
  >([]);

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  const resetLinkValues = useCallback(() => {
    handleInstallmentsValue(
      formatLinkValue("")
    );
    setLinkValue("");
    setLinkCreated(false);
    setInstallments(1);
    setExpireDate("");
    setLinkExpirationMessage("");
    setHasExpiration(false);
    setInstallmentsValue([])
    formRef.current?.setErrors({
      description: "",
      name: "",
      value: "",
    });
  }, []);

  const copyToClipboard = useCallback(
    () => {
      try {
        if (linkRef.current) linkRef.current.select();
        document.execCommand("copy");
        addToast({
          title: "Link copiado!",
          type: "info",
        });
      } catch (err) {
        console.log(err);
      }
    },
    [addToast]
  );

  const handleLinkExpiration = useCallback((e) => {
    let expDate = e.currentTarget.value;
    moment(expDate).isBefore(moment())
      ? setLinkExpirationMessage("Data inválida, selecione outra data.")
      : setLinkExpirationMessage(
        `O link irá expirar em: ${moment(expDate).format("dddd DD/MM/YYYY")}`
      );
  }, []);

  function handleInstallmentsValue(value: any) {
    value = value.replaceAll(".", "").replaceAll(",", ".");
    value = parseFloat(value);
    let installmentsObj: IInstallmentsData[] = [];
    for (let index = 2; index <= 21; index++) {
      if (value / index >= 20) {
        installmentsObj.push({
          value: `${index}x de ${formatValue(value / index)}`,
          installment: index,
        });
      } else {
        break;
      }
    }
    setInstallmentsValue(installmentsObj);
  }

  const handleSubmit = useCallback(
    async (data: ILinkFormData) => {
      try {
        formRef.current?.setErrors({});
        let schema;
        hasExpiration
          ? (schema = Yup.object().shape({
            expiration: Yup.string().required("Escolha o tempo de expiração"),
            value: Yup.string().required("Digite o valor do link"),
            payer: Yup.string().required(`Obrigatório`),
            description: Yup.string().required(`Obrigatório`),
          }))
          : (schema = Yup.object().shape({
            value: Yup.string().required("Digite o valor do link"),
            payer: Yup.string().required(`Obrigatório`),
            description: Yup.string().required(`Obrigatório`),
          }));

        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        let linkValue;
        linkValue = data.value.replaceAll(".", "").replaceAll(",", ".");
        await api
          .post("/pagamentos/links", {
            payer: data.payer,
            fee: 0,
            description: data.description,
            expirationDate: !!data.expiration && hasExpiration === true ? data.expiration : null,
            value: linkValue,
            installments: installments,
          })
          .then((response) => {
            refreshLinkData(response.data);
            setCreatedLinkUrl(response.data.link);
            setLoading(false);
            setLinkCreated(true);
          }).catch(() => {
            addToast({
              title: "Erro ao criar link de pagamento",
              type: "error",
              description:
                "Ocorreu um erro ao criar o link, tente novamente mais tarde",
            });
          })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        resetLinkValues();
        setIsOpen();
        setLoading(false);
      }
    },
    [addToast, setIsOpen, hasExpiration, installments, refreshLinkData, resetLinkValues]
  );
  return (
    <>
      <ModalContainer
        isOpen={modalStatus}
        onClick={() => {
          setIsOpen();
          resetLinkValues();
        }}
      />
      <LinkContainer isOpen={modalStatus}>
        {linkCreated && createdLinkUrl ? (
          <LinkSuccess>
            <LinkHeader>
              <h3>
                Link criado com sucesso!
                <FiCheckCircle style={{ color: "#1ee0ac" }} />
              </h3>
              <FiX
                style={{ cursor: "pointer" }}
                onClick={() => {

                  resetLinkValues();
                  setIsOpen();
                }}
              />
            </LinkHeader>
            <p style={{ fontSize: "14px" }}>
              Compartilhe o link com seu cliente, clique abaixo para copiar.
            </p>

            <div className="generatedLink">
              <input ref={linkRef} value={createdLinkUrl} id="linkArea" />
              <p onClick={copyToClipboard}>
                Copiar Link para área de transferência
                <FiCopy style={{ color: "#0087e5" }} />
              </p>
              <p
                onClick={() =>
                  window.open(`https://wa.me/?text=${createdLinkUrl}`, "_blank")
                }
              >
                Enviar link via Whats App
                <RiWhatsappLine style={{ color: "#1ebea5" }} />
              </p>
            </div>
            <Button
              onClick={() => {
                setLinkCreated(false);
                resetLinkValues();
              }}
            >
              Gerar outro link
            </Button>
          </LinkSuccess>
        ) : (
          <LinkContent>
              <Form id="linkForm" ref={formRef} onSubmit={handleSubmit}>
              <LinkHeader>
                <h3>Criar link de pagamento</h3>
                  <FiX
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      resetLinkValues();
                      setIsOpen();
                    }}
                  />
              </LinkHeader>
              <div>
                <Input
                  value={linkValue}
                    onChange={(e) => {
                      setLinkValue(formatLinkValue(e.currentTarget.value));
                      handleInstallmentsValue(
                        formatLinkValue(e.currentTarget.value)
                      );
                  }}
                  name="value"
                  type="text"
                  placeholder="Valor (R$)"
                  icon={FiCreditCard}
                />
              </div>
              <div>
                <p className="linkInfo">* Valor mínimo de parcela: R$20,00</p>
                  <select
                    value={installments}
                    onChange={(e) =>
                      setInstallments(parseInt(e.currentTarget.value))
                    }
                  >
                    <option key={1} value={1}>À vista R$ {linkValue}</option>
                    {
                      !!installmentsValue &&

                      installmentsValue.map((value, index) => (
                        <option key={index} value={value.installment}>{value.value}</option>
                      ))
                    }

                </select>
              </div>
                <div className="chooseExpiration">
                <div>
                    <input
                      defaultChecked
                      type="radio"
                      id="NoneID"
                      value="none"
                      name="expire"
                      onClick={() => {
                        setHasExpiration(false);
                        setLinkExpirationMessage("");
                        setExpireDate("");
                      }}
                    />
                    <label id="labelExpiration" htmlFor="NoneID">
                      Link sem expiração
                  </label>
                </div>
                <div>
                    <input
                      type="radio"
                      id="expirationID"
                      value="expiration"
                      name="expire"
                      onClick={() => {
                        setHasExpiration(true);
                      }}
                    />
                    <label id="labelExpiration" htmlFor="expirationID">
                      Link com data de expiração
                  </label>
                  </div>
              </div>

                {hasExpiration && (
                  <div>
                    <Input
                      name="expiration"
                      className="expirationInput"
                      type="date"
                      value={expireDate}
                      onChange={(e) => {
                      setExpireDate(
                        moment(e.currentTarget.value).format("YYYY-MM-DD")
                      );
                      handleLinkExpiration(e);
                    }}
                  />
                </div>
                )}
              <div>
                <div>
                  <Input
                    name="payer"
                    type="text"
                    placeholder="Cobrar de"
                    icon={FiUser}
                  />
                </div>
                <div>
                  <Input
                    name="description"
                    type="text"
                    placeholder="Descrição do pagamento"
                    icon={RiBillLine}
                  />
                </div>
                  {hasExpiration && (
                    <span
                      className="LinkExp"
                      style={{
                        color: linkExpirationMessage.includes("Data inválida")
                          ? "#e85347"
                          : "#526484",
                      }}
                    >
                    {linkExpirationMessage}
                  </span>
                  )}
              </div>
                <Button loading={loading} type="submit">
                  Finalizar
              </Button>
            </Form>
          </LinkContent>
        )}
      </LinkContainer>
    </>
  );
};
export default LinkModal;
