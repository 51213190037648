import styled, { css } from "styled-components";

interface IProps {
	primaryColor?: string;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: space-between;
	height: 100vh;
`;
export const Content = styled.div`
	max-width: 1273px;
	margin: auto;
	flex: 1;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	padding: 44px;

	@media (max-width: 500px) {
		padding: 24px;
	}

	@media (max-width: 875px) {
		flex-direction: column;
	}
`;
export const InfoContent = styled.div<IProps>`
	padding: 16px 20px;
	width: 100%;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);

	${(props) =>
		props.primaryColor &&
		css`
			border-bottom: 3px solid ${props.primaryColor};
		`}
	margin-top: 20px;

	form {
		display: flex;
		flex: 1;
		width: 100%;
		flex-direction: column;

		div {
			width: 40%;
		}
		p {
			font-size: 15px;
			color: #364a63;
			font-weight: bold;
			text-transform: uppercase;
			margin: 10px 0px;
			width: 100%;
		}
	}

	@media (max-width: 875px) {
		width: 100% !important;
		form {
			div {
				width: 80%;
			}
		}
	}
`;
