import React from "react";

import { Container, Content } from "./styles";

import { AiOutlineWarning } from "react-icons/ai";

const LinkError: React.FC = () => {
  return (
    <Container>
      <Content>
        <div>
          <h1 className="notFoundTitle">
            <AiOutlineWarning /> <p>404</p>
          </h1>
          <h2>Oops! Erro 404 A página não pode ser encontrada!</h2>
          <p className="notFoundDesc">
            A página que você está procurando não está disponível ou não existe.
          </p>
        </div>
      </Content>
    </Container>
  );
};

export default LinkError;
