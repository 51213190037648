import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 935px) {
		margin: 0 auto;
		width: 100%;
		height: 100%;
	}
`;

export const LastSalesContent = styled.div`
	flex: 1;

	@media (max-width: 500px) {
		overflow-y: scroll;
	}

	table {
		width: 100%;
	}

	td,
	th {
		width: 100%;
	}

	tr {
		display: flex;
		height: fit-content;
		justify-content: space-between;
		font-size: 15px;
		width: 100%;

		@media (max-width: 500px) {
			font-size: 12px;
			width: 460px;
		}

		font-weight: lighter;
		color: #526484;
		border-bottom: 1px solid #e5e9f2;
		box-shadow: white;
		list-style: none;
		/* padding: 10px; */
		cursor: pointer;

		:hover {
			font-weight: 900;
		}

		th {
			cursor: default;
			font-weight: 300;

			:first-child {
				text-align: left;
			}

			:nth-child(n + 2) {
				text-align: center;
			}

			:last-child {
				text-align: right;
			}
		}

		td {
			position: relative;
			padding: 20px 0px;
			vertical-align: middle;

			img {
				width: 30px;
				vertical-align: middle;
			}

			:nth-child(n + 2) {
				text-align: center;
			}

			:last-child {
				text-align: center;

				p {
					font-weight: 900;
					font-size: 12px;
					letter-spacing: 0px;
				}
			}
		}
	}

	.noSales {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 75px;
		font-style: italic;

		:hover {
			cursor: default;
			font-weight: normal;
		}
	}
`;
