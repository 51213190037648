import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ISalesList } from "../../../@types/sales";
import { InstallmentsPaid } from "../../../pages/Sales/SalesNonSplit/styles";
import { IsEmpty } from "../../../styles/IsEmpty";
import cardBrand from "../../../utils/CardBrand";
import formatValue from "../../../utils/formatValue";
import { setStatusColor, setStatusTranslation } from "../../../utils/setStatus";
import { SalesList } from "./styles";

interface ITableTransactionsProps {
	salesList: ISalesList;
	children: React.ReactFragment;
}

const TableTransactions = (props: ITableTransactionsProps) => {
	const { salesList, children } = props;
	const history = useHistory();

	const urlHandler = useCallback(
		(id: any) => {
			history.push({
				pathname: `/detalhes-transacao/${id}`,
				state: id,
			});
		},
		[history]
	);

	if (!salesList.result) {
		return (
			<>
				<Skeleton variant="rect" height={50} animation="wave" />
				<br />
				<Skeleton variant="rect" height={50} animation="wave" />
				<br />
				<Skeleton variant="rect" height={50} animation="wave" />
				<br />
				<Skeleton variant="rect" height={50} animation="wave" />
			</>
		);
	}

	if (Number(salesList.result.items.length) === 0) {
		return (
			<IsEmpty>
				<p>Sem vendas para o período</p>
			</IsEmpty>
		);
	}

	return (
		<div>
			<SalesList>
				<table>
					<tbody>
						<tr>
							<th>Id</th>
							<th>Status</th>
							<th>Bandeira</th>
							<th>Data de Venda</th>
							<th>Captura</th>
							<th>Tipo</th>
							<th>Valor Bruto</th>
							<th>Taxa Cartão</th>
							<th>Valor Líquido</th>
							<th>Valor Recebido</th>
							<th>Recebimento</th>
						</tr>
						{salesList.result.items.map((value) => (
							<tr key={value.id} onClick={() => urlHandler(value.id)}>
								<td>{value.paymentReference}</td>
								<div>
									<span
										style={{
											background: `${setStatusColor(value.status)}`,
										}}
									/>
									<td>{setStatusTranslation(value.status)}</td>
								</div>
								<td>
									<img src={cardBrand(value.brand)} alt={value.brand}></img>
								</td>
								<td>{moment(value.date).format(`DD/MM/YYYY HH:mm`)}</td>
								<td>{value.capture_method}</td>
								<td>
									{value.type_sale} {value.installments}x
								</td>
								<td>{formatValue(value.grossAmount)}</td>
								<td>{formatValue(value.mdrAmount)}</td>
								<td>{formatValue(value.netAmount)}</td>
								<td>
									{value.status === "refunded"
										? "Cancelado"
										: formatValue(value.PaidAmount)}
								</td>
								<td className="elementInstallments">
									{value.installments_paid}/{value.installments}
									<InstallmentsPaid
										cancelled={value.status === "refunded" ? true : false}
										paid={(value.installments_paid / value.installments) * 100}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</SalesList>
			{children}
		</div>
	);
};

export default TableTransactions;
