import React from 'react';
import formatValue from './formatValue';
import { setStatusTranslation } from './setStatus';

export default function generateCalendarStyle(status: string, amount: number, index: any) {
  let borderColor = ''
  let backgroundColor = ''
  let fontColor = ''

  if (amount === 0.001) {
    borderColor = '#999999'
    fontColor = '#999999'
  }
  
  if (status.toLowerCase() === 'compensated' && 'paid') {
    borderColor = '#00bb89'
    fontColor = '#00bb89'
  }
  else if (status.toLowerCase() === 'processing') {
    borderColor = 'rgb(130, 180, 219)'
    fontColor = '#8094ae'

  } else if (status.toLowerCase() === 'authorized') {
    borderColor = '#5f6061'
    fontColor = '#999999'
    }
  else if (status.toLowerCase() === 'feriado') {
    borderColor = '#e000bb'
    fontColor = '#999999'
  } else if (status.toLowerCase() === 'refunded' && 'chargedback') {
    borderColor = '#cf0e00'
    fontColor = '#cf0e00'
  } else if (status.toLowerCase() === 'receivable' && 'antecipated') {
    borderColor = '#0095ff'
    fontColor = '#999999'
  }

  if (amount < 0) {
    borderColor = '#cf0e00'
    fontColor = '#cf0e00'
  }

  return (
    <>
      <span key={index} className='statusValue' style={{color: 'rgba(0, 0, 0, .5'}}>{setStatusTranslation(status)}</span>
      <p id='amountValue' style={{backgroundColor: `${backgroundColor}`, borderLeft: `2px solid ${borderColor}`, color: fontColor}}>{formatValue(amount)}</p>
    </>
  )
}
    