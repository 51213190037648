import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  justify-content: space-between;

  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }
  a {
    text-decoration: none;
  }
`;

const appearFromLeft = keyframes`
  to {
    transform: translateX(0);
    opacity: 1;

  }
  from {
    transform: translateX(-200px);
    opacity: 0;
  }
`;

export const Content = styled.div`
  height:100%;
  display:flex;
  width:100%;
  align-self: center;
  justify-content:center;
  animation: ${appearFromLeft} 1s;

  div {
    display:flex;
    text-align: center;
    flex-direction:column;
    justify-self:center;
    align-self:center;


    h1 {
      font-size: 7rem;
      color: #008dcf;

      svg {
        font-size: 9rem;
        align-self: center;
        justify-content:center;
      }
      p {
        font-size: 7rem;
      }
    }
    p {
      margin: 10px;
      font-size: 20px;
    }
  }
`;
