import api from "../services/api";
import { generateEncryption } from "./generateDNSEncryption";

const generateTheme = () => {
  let theme = localStorage.getItem("@PortalBC:theme");
  if (theme) {
    return JSON.parse(theme);
  } else {
    api
      .get("/acesso", {
        params: {
          encrypted: generateEncryption().toString(),
        },
      })
      .then((response) => {
        localStorage.setItem("@PortalBC:theme", JSON.stringify(response.data));
      });
  }
};

export default generateTheme;
