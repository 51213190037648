import styled,{css} from "styled-components";
interface IProps{
    primary_color?:string;
}

export const Content = styled.footer<IProps>`
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    ${
  (props) => props && css`
    background-color: ${props.primary_color};
    opacity:0.25;
  `}
    backdrop-filter: blur(2px);

    p {
        font-family: 'Nunito';
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: fit-content;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 20px;
        font-style: italic;
        ${
        (props) => props && css`
         text-shadow: 2px 2px 3px ${props.primary_color};
        `}
       

        svg {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: 20px;
        }

        :before {
            position: relative;
            content: '';
            white-space: pre-line;
            width: 25px;
            height: 25px;
            top: 27px;
            left: 83px;
            border: 4px solid;
            border-color: #fff;
            vertical-align: middle;
            border-top-color: rgba(0, 57, 103, .0);
            border-bottom-color: rgba(0, 57, 103, .0);
            border-radius: 50%;
            animation: rotateLoader 1s ease-in-out infinite;
        }

        @keyframes rotateLoader {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }
`;