import React, { useCallback, useEffect, useState } from "react";

import { BsFillPersonFill } from "react-icons/bs";
import { FiLink, FiLogOut, FiFileText } from "react-icons/fi";
import {
	MdAccountCircle,
	MdLocalGroceryStore,
	MdPayment,
} from "react-icons/md";
import { HiOutlineHome } from "react-icons/hi";
import {
	RiArrowDropDownLine,
	RiLockLine,
	RiUserSettingsLine,
} from "react-icons/ri";
import { VscGraph } from "react-icons/vsc";

import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { cnpjMask } from "../../utils/masks";

import {
	Header,
	HeaderContent,
	UserOptions,
	ModalMenu,
	UserProfile,
	UserSetting,
	UserLogout,
} from "./styles";
import ICompany from "../../@types/company";
import generateTheme from "../../utils/generateTheme";

interface IHeaderProps {
	linkValue: string;
}

const HeaderComponent: React.FC<IHeaderProps> = ({ linkValue }) => {
	const { signOut } = useAuth();
	const [modalMenu, setModalMenu] = useState(false);
	const [menuIsActive] = useState(false);
	const history = useHistory();
	const { user } = useAuth();
	const [link, setLink] = useState<ICompany>();

	document.addEventListener(
		"click",
		function () {
			return true;
		},
		false
	);

	const HandleOpenMenu = useCallback(() => {
		const Hamb = document.querySelector(".hamb");
		Hamb?.classList.toggle("active");
		const Menu = document.querySelector(".NavMenu");
		Menu?.classList.toggle("active");
		const Button = document.querySelector(".btnIcon");
		Button?.classList.toggle("active");
		const show = document.querySelector(".ground");
		show?.classList.toggle("show");
		setModalMenu(false);
	}, []);

	const HandleOpenModal = useCallback((value: boolean) => {
		const Arrow = document.querySelector(".ArrowUpDown");
		Arrow?.classList.toggle("active");
		setModalMenu(value);
	}, []);

	useEffect(() => {
		setLink(generateTheme);
	}, []);

	return (
		<Header primary_color={link?.style.primary_color}>
			<HeaderContent
				name={link?.style.name}
				IsSideMenuActive={menuIsActive}
				primary_color={link?.style.primary_color}
			>
				<div className="ground" onClick={HandleOpenMenu} />
				<div className="NavIcon">
					<button
						className="btnIcon"
						onClick={() => {
							HandleOpenMenu();
						}}
					>
						<div className="hamb">
							{/* {menuIsActive ? <RiCloseLine style={{position: 'fixed'}}/> : <FiMenu />} */}
						</div>
					</button>
					{link?.style.name.toLowerCase() === "aqpago" ? (
						<img
							style={{ height: "80px", width: "80px" }}
							className="navLogo"
							onClick={() => history.push("/resumo")}
							src={link?.style.url_logo_default}
							alt="Logo aqpago"
						></img>
					) : (
						<img
							style={{ width: link?.style.logo_width }}
							className="navLogo"
							onClick={() => history.push("/resumo")}
							src={link?.style.url_logo_white}
							alt="Logo"
						></img>
					)}
				</div>
				<div className="NavMenu">
					{link?.style.name.toLowerCase() === "aqpago" ? (
						<img
							className="menuLogo"
							src={link?.style.url_logo_default}
							alt="Logo AqPago"
						></img>
					) : (
						<img
							className="menuLogo"
							src={link?.style.url_logo_white}
							alt="Logo"
						></img>
					)}
					<div>
						{user && user.only_sale ? (
							<Link
								to="/vendas"
								className={linkValue === "vendas" ? "active" : "menu"}
							>
								<MdLocalGroceryStore /> Vendas
							</Link>
						) : (
							<>
								<Link
									to="/resumo"
									className={linkValue === "dashboard" ? "active" : "menu"}
								>
									<HiOutlineHome /> Resumo
								</Link>
								<Link
									to="/vendas"
									className={linkValue === "vendas" ? "active" : "menu"}
								>
									<MdLocalGroceryStore /> Vendas
								</Link>
								<Link
									to="/recebiveis"
									className={linkValue === "pagamentos" ? "active" : "menu"}
								>
									<MdPayment /> Recebíveis
								</Link>
								<Link
									to="/movimentacoes"
									className={linkValue === "movimentacoes" ? "active" : "menu"}
								>
									<VscGraph /> Movimentações
								</Link>

								<Link
									to="/taxas"
									className={linkValue === "taxas" ? "active" : "menu"}
								>
									<MdAccountCircle /> Taxas
								</Link>

								{
									user.showSimulator && (
										<Link
											to="/simulador"
											className={linkValue === "simulador" ? "active" : "menu"}
										>
											<MdPayment /> Simulador
										</Link>
									)
								}

								{user.show_payment_link && (
									<Link
										to="/links"
										className={linkValue === "links" ? "active" : "menu"}
									>
										<FiLink /> Links
									</Link>
								)}

								{
									user && user.showRequestAntecipation && (
										<Link
											to="/antecipacao"
											className={linkValue === "antecipacao" ? "active" : "menu"}
										>
											<MdPayment /> Antecipação
										</Link>
									)
								}
								
							</>
						)}
					</div>
				</div>
				<UserOptions secundary_color={link?.style.secundary_color}>
					<div className="UserAvatar" onClick={() => HandleOpenModal(true)}>
						<BsFillPersonFill />
					</div>
					<div className="UserStatusLogin">
						{/* <div className='UserStatus'>Unverified</div> */}
						<div className="UserLogin" onClick={() => HandleOpenModal(true)}>
							<section>
								<p>{user.name}</p>
								<p>{cnpjMask(user.tax_id)}</p>
							</section>
							<button className="ArrowUpDown">
								<RiArrowDropDownLine />
							</button>
						</div>
						{modalMenu && (
							<ModalMenu secundary_color={link?.style.secundary_color}>
								<div
									className="btnMenu"
									onClick={() => HandleOpenModal(false)}
								></div>
								<UserProfile secundary_color={link?.style.secundary_color}>
									<div
										className="userPhoto"
										// style={{ backgroundImage: `url(${photo})` }}
									>
										<BsFillPersonFill />
									</div>
									<div className="userName">
										<p>{user.name}</p>
										<p>{user.email}</p>
									</div>
								</UserProfile>
								{user.only_sale ? (
									<></>
								) : (
									<UserSetting>
										<div>
											<Link to="/perfil">
												<RiUserSettingsLine /> Ver Perfil
											</Link>
										</div>
										<div>
											<Link to="perfil/seguranca">
												<RiLockLine /> Trocar Senha
											</Link>
										</div>
										{user.show_payment_link && (
											<div>
												<Link to="perfil/links">
													<FiLink /> Links de Pagamento
												</Link>
											</div>
										)}
										<div>
											<a href="https://assets.brasilcash.com.br/docs/Contrato_Portal_Credenciamento_v1.pdf" target="_blank">
												<FiFileText /> Contrato
											</a>
										</div>	
										{/* <div>
                    <Link to='perfil/notificacoes'>
                      <FaRegBell /> Notificações
                    </Link>
                  </div> */}
										{/* <div>
                    <Link to='perfil/atividade-login'>
                      <RiPulseFill /> Atividade de Login
                    </Link>
                  </div> */}
									</UserSetting>
								)}

								<UserLogout>
									<div onClick={signOut}>
										<FiLogOut /> Sair da Conta
									</div>
								</UserLogout>
							</ModalMenu>
						)}
					</div>
					<div className="btnSection">
						{/* <Notification onClick={()=>{setModalNotify(true)}} hasNewNotification={hasNewNotifications}>
              <FaRegBell />
              <div className='statusInfo'></div>
            </Notification>

            {modalNotify && (
              <ModalNotify>
                <div className='btnNotify' onClick={() => setModalNotify(false)}></div>
                <HeaderNotify>
                  <div>Notificações</div>
                  <div onClick={()=>{setHasNewNotifications(false)}}>Marcar todas como lidas</div>
                </HeaderNotify>
                <SectionNotify>
                  {notification.map((value, index) => (
                    <div key={index} className='eachNotify'>
                      <span>{value.message}</span>
                      <span>{value.time}</span>
                    </div>
                  ))}
                </SectionNotify>
                <FooterNotify>
                  <div onClick={()=>{
                        history.push('/perfil/notificacoes')
                        setHasNewNotifications(false)
                        }}>Ver todas
                  </div>
                </FooterNotify>
              </ModalNotify>
            )} */}
					</div>
					<div className="btnSection">
						<button onClick={signOut}>
							<FiLogOut />
						</button>
					</div>
				</UserOptions>
			</HeaderContent>
		</Header>
	);
};

export default HeaderComponent;
