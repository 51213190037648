import IUser from '../@types/user';

export function redirectOnlyUserSale(history: any, user: IUser) {
    let url = document.URL;
    if (user && user.only_sale) {
        if (!url.includes('vendas') || !url.includes('perfil')) {
            history.push('/vendas')
        } else
            return;
    }    
}
