import React, { useEffect, useMemo, useState } from "react";
import ICompany from "../../@types/company";
import { Helmet } from "react-helmet";
import generateTheme from "../../utils/generateTheme";

const MetaDecorator: React.FC = () => {
  const [link, setLink] = useState<ICompany | undefined>(undefined);

  const theme = generateTheme();
  useEffect(() => {
    if (theme?.metadata && link === undefined) {
      setLink(theme);
    }
  }, [theme, link]);

  return (
    <Helmet>
      <title>{`Portal ${link?.metadata.white_label.name}`}</title>
      <meta name="theme-color" content={link?.style.primary_color} />
      <meta
        name="description"
        content="Navegue pelo portal do cliente e tenha acesso aos extratos de movimentações, vendas e recebíveis de seu negócio"
      />
      <link rel="icon" href={link?.style.url_logo_default} type="image/png" />
    </Helmet>
  );
};

export default MetaDecorator;
