import styled from 'styled-components'


export const Container = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
    @media screen and (max-width: 935px) {
        margin:0 auto;
         width: 100%;
        height:100%;
    }
  `
