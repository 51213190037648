import React, { useCallback, useEffect, useState } from 'react'
import {
  Container,
  Content,
  FilterSection,
  ModuleInfo,
  SectionTransaction,
  Filter,
  ModuleExtract,
  SalesContent
} from './styles'
import HeaderComponent from '../../components/Header'
import FooterComponent from '../../components/Footer'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { GrFormPrevious, GrFormNext } from 'react-icons/gr'
import { BiFirstPage, BiLastPage } from 'react-icons/bi'
import api from '../../services/api'
import LoaderComponent from '../../components/Loader'
import formatValue from '../../utils/formatValue'
import moment from 'moment';
import Button from '../../components/Button'
import cardBrand from '../../utils/CardBrand'
import { useToast } from '../../hooks/toast'
import ICompany from '../../@types/company'
import generateTheme from '../../utils/generateTheme'


interface ISerialList {
  result:
  [
    { serial_number: string; }
  ]
}

interface ITransactionsStatus {
  gross_amount: number,
  net_amount: number,
  quantity: number
}


interface ISalesList {
  result: {
    items: [
      {
        paymentReference: string;
        capture_method: string;
        status: string;
        cashbackAmount: number;
        installments: number;
        installments_paid: number;
        netAmount: number;
        AntecipatednetAmount: number;
        type_sale: number;
        serial: any;
        date: string;
        grossAmount: number;
        id: string;
        mdrAmount: number;
        PaidAmount: number;
        brand: string;
        aproval: number;
        card_number: string;
      }
    ]
    count: string
  }
  token: string;
}
interface IBrandList {
  result:
  [
    {
      brand_id: number,
      name: string
    }
  ]
  token: string;
}

const Sales: React.FC = () => {

  const { addToast } = useToast();
  //PAGE VALUES
  const [loading, setLoading] = useState(true);
  const [serialList, setSerialList] = useState<ISerialList>()
  const [salesList, setSalesList] = useState<ISalesList>()
  const [brandList, setBrandList] = useState<IBrandList>()
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  //API VALUES
  const [customDayFilter, setCustomDayFilter] = useState(false);
  const [dateFilterType, setDateFilterType] = useState("Este mês");
  const [salesSubtitle, setSalesSubtitle] = useState("Este mês");
  const [serialNumber, setSerialNumber] = useState("Todos");
  const [capturedTransactions, setCapturedTransactions] = useState<ITransactionsStatus>();
  const [firstDayRange, setFistDayRange] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [lastDayRange, setLastDayRange] = useState(moment().format('YYYY-MM-DD'));
  const [salesStatus, setsalesStatus] = useState("Todos");
  const [salesType, setSalesType] = useState("Todos");
  const [salesID, setSalesID] = useState("");
  const [brands, setBrands] = useState("Todas");
  const [link, setLink] = useState<ICompany>()


  const handlePageChange = useCallback((value) => {
    if (value <= 0 || value > totalPages)
      return

    setPage(value)
    try {
      setLoading(true);
      api.get('/vendas',
        {
          params: {
            perpage: pageSize,
            orderby: 'date',
            orderdirection: 'desc',
            date: dateFilterType,
            datestart: firstDayRange,
            dateend: lastDayRange,
            serialnumber: serialNumber !== 'Todos' ? serialNumber : null,
            status: salesStatus !== 'Todos' ? salesStatus : null,
            typesale: salesType !== 'Todos' ? salesType : null,
            page: value,
            id: salesID ? salesID : null,
          }

        }).then(response => {
          setSalesList(response.data);
          api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
        }).finally(() => setLoading(false));
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar informações',
        description:
          'Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.',
      });
    }
  }, [pageSize, addToast, dateFilterType, firstDayRange, lastDayRange, salesStatus, salesType, salesID, serialNumber, totalPages]);

  const handleSalesFilters = useCallback((value) => {
    setDateFilterType(value)

    //DATE 
    switch (value) {
      case 'Este mês': {
        setFistDayRange(moment().startOf('month').format('YYYY-MM-DD'))
        setLastDayRange(moment().format('YYYY-MM-DD'));
        return
      }
      case 'Hoje': {
        setFistDayRange(moment().format('YYYY-MM-DD'))
        setLastDayRange(moment().format('YYYY-MM-DD'));
        return
      }
      case 'Últimos 7 dias': {
        setFistDayRange(moment().subtract(8, 'd').format('YYYY-MM-DD'))
        setLastDayRange(moment().subtract(1, 'd').format('YYYY-MM-DD'));
        return
      }
      case 'Mês passado':
        {
          setFistDayRange(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
          setLastDayRange(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
          return
        }
      case 'Ontem':
        {
          setFistDayRange(moment().subtract(1, 'd').format('YYYY-MM-DD'))
          setLastDayRange(moment().subtract(1, 'd').format('YYYY-MM-DD'));
          return
        }
      case 'Escolha os dias':
        {
          return
        }
      default:
        return;
    }
  }, []);

  //FILTER
  const fetchAPI = useCallback(() => {
    try {
      setLoading(true)
      api.get('/vendas',
        {
          params: {
            perpage: pageSize,
            orderby: 'date',
            orderdirection: 'desc',
            date: dateFilterType,
            datestart: firstDayRange,
            dateend: lastDayRange,
            serialnumber: serialNumber !== 'Todos' ? serialNumber : null,
            status: salesStatus !== 'Todos' ? salesStatus : null,
            typesale: salesType !== 'Todos' ? salesType : null,
            page: 1,
            id: salesID ? salesID : null,
          }

        }).then(response => {
          api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
          setSalesList(response.data);
          setPage(response.data.result.Page);
          if (response.data.result.TotalPage === 0)
            setPage(0)
          setTotalPages(response.data.result.TotalPage);
          setPageSize(response.data.result.pageSize);
          setSalesSubtitle(dateFilterType);
          setCapturedTransactions(response.data.result.summary.captured);
        }).finally(() => setLoading(false));
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar informações',
        description:
          'Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.',
      });
    }

  }, [addToast, pageSize, dateFilterType, firstDayRange, lastDayRange, salesStatus, salesType, salesID, serialNumber]);


  //FIRST CALL
  useEffect(() => {
    setLink(generateTheme)
    try {
      api.get('/vendas/serial').then(response => {
        setSerialList(response.data)
      });

      api.get('/vendas/bandeiras').then(response => {
        setBrandList(response.data);
      });

      api.get('/vendas',
        {
          params: {
            perpage: 10,
            page: 1,
            datestart: moment().startOf('month').format('YYYY-MM-DD'),
            dateend: moment().format('YYYY-MM-DD'),
            date: 'thisMonth',
            orderdirection: 'desc',
            orderby: 'date',
          }
        }).then(response => {
          if (response.data.result.TotalPage === 0) {
            setPage(0)

          }
          setSalesList(response.data);
          setPage(response.data.result.Page);
          setTotalPages(response.data.result.TotalPage);
          setPageSize(response.data.result.pageSize);
          setCapturedTransactions(response.data.result.summary.captured);
          api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
        }).finally(() => setLoading(false));
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar informações',
        description:
          'Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.',
      });
    }
  }, [addToast, pageSize])

  return (
    <Container>
      <HeaderComponent linkValue={'vendas'} />
      {!loading ?
        <Content>
          <FilterSection primary_color={link?.style.primary_color}>
            <p style={{ fontWeight: 900, textTransform: 'uppercase' }}>Filtre suas vendas</p>

            <div className='multipleFilters'>
              <Filter>
                <p>Filtrar Extrato</p>
                <select value={dateFilterType} onChange={e => {
                  handleSalesFilters(e.currentTarget.value)
                  if (e.currentTarget.value === "Escolha os dias")
                    setCustomDayFilter(true)
                  else
                    setCustomDayFilter(false)
                }}>
                  <option value="Este mês">Este mês</option>
                  <option value="Hoje" >Hoje</option>
                  <option value="Ontem" >Ontem</option>
                  <option value="Últimos 7 dias" >Últimos 7 dias</option>
                  <option value="Mês passado" >Mês passado</option>
                  <option value="Escolha os dias">Escolha os dias</option>
                </select>
              </Filter>
              <Filter>
                <p>Número de Série </p>
                <select value={serialNumber} onChange={e => setSerialNumber(e.currentTarget.value)}>
                  <option value={'todos'}>Todos</option>
                  {serialList && serialList.result.map(value => (
                    <option key={value.serial_number} value={value.serial_number}>{value.serial_number}</option>
                  ))}
                </select>
              </Filter>
              <Filter>
                <p>Status do Pagamento </p>
                <select value={salesStatus} onChange={e => setsalesStatus(e.currentTarget.value)}>
                  <option value={"todos"}>Todos</option>
                  <option value={"receivable"}>A receber</option>
                  <option value={"compensated"}>Compensado</option>
                  <option value={"antecipated"}>Antecipado</option>
                  <option value={"cancelled"}>Cancelado</option>
                </select>
              </Filter>
              <Filter>
                <p>Tipo de Venda </p>
                <select value={salesType} onChange={e => setSalesType(e.currentTarget.value)}>
                  <option value={"todos"}>Todos</option>
                  <option value={"debit"}>Débito</option>
                  <option value={"credit"}>Crédito</option>
                  <option value={"recharge"}>Recarga</option>
                </select>
              </Filter>
              <Filter>
                <p>ID da venda </p>
                <form><input value={salesID} onChange={e => setSalesID(e.currentTarget.value)}></input></form>
              </Filter>
              <Filter>
                <p>Bandeira </p>
                <select>
                  <option value={'todos'}>Todas</option>
                  {brandList && brandList.result.map(value => (
                    <option key={value.brand_id} value={brands} onChange={e => setBrands(e.currentTarget.value)}>{value.name}</option>
                  ))}
                </select>
              </Filter>
            </div>
            <div className='filtersButton'>
              {customDayFilter &&
                <div className='customDate'>
                  <div>
                    <label>Data inicial</label>
                    <input id="startDay" type="date" value={firstDayRange} defaultValue={moment().format('YYYY-MM-DD')} onChange={e => setFistDayRange(moment(e.currentTarget.value).format('YYYY-MM-DD'))} />
                  </div>
                  <div>
                    <label>Data final</label>
                    <input id="endDay" type="date" value={lastDayRange} defaultValue={moment().format('YYYY-MM-DD')} onChange={e => setLastDayRange(moment(e.currentTarget.value).format('YYYY-MM-DD'))} />
                  </div>
                </div>
              }
              <Button onClick={() => fetchAPI()} style={{ marginTop: '16px' }}> Filtrar</Button>
            </div>


          </FilterSection>
          <ModuleInfo>
            {
              capturedTransactions &&
              <SectionTransaction primary_color={link?.style.primary_color}>
                <div className='titleTransactions'>
                  <p>Confirmado</p>
                  {capturedTransactions.quantity <= 1 ?
                    <p>({capturedTransactions.quantity} transação)</p>
                    :
                    <p>({capturedTransactions.quantity} transações)</p>
                  }
                </div>
                <div className='valueTransactions'>
                  <p>{formatValue(capturedTransactions.gross_amount)} <AiOutlineInfoCircle title="valor bruto" /></p>
                </div>
              </SectionTransaction>
            }

          </ModuleInfo>
          <ModuleExtract primary_color={link?.style.primary_color}>
            <div className='exportExcel'>
              <p>Últimas vendas: {salesSubtitle}</p>
            </div>
            <SalesContent>
              <table>
                <tbody>
                  <tr>
                    <th>Id</th>
                    <th>Bandeira</th>
                    <th>Data de Venda</th>
                    <th>Captura</th>
                    <th>Tipo</th>
                    <th>Valor Bruto</th>
                    <th>Número do Cartão</th>
                  </tr>

                  {salesList && salesList.result.items.length > 0 ?

                    salesList.result.items.map(value => (
                      <tr key={value.id}>
                        <td>{value.paymentReference}</td>
                        <td><img src={cardBrand(value.brand)} alt={value.brand}></img></td>
                        <td>{moment(value.date).format(`DD/MM/YYYY HH:mm`)}</td>
                        <td>{value.capture_method}</td>
                        <td>{value.type_sale} {value.installments}x</td>
                        <td>{formatValue(value.grossAmount)}</td>
                        <td>{value.card_number}</td>
                      </tr>
                    ))
                    :
                    <tr className='noSales'>Sem vendas para o período</tr>
                  }
                </tbody>
              </table>
            </SalesContent>

            <div className='btnForPages'>
              <button onClick={() => {
                if (page !== 1)
                  handlePageChange(1)
              }} className={page === 1 ? 'btnDefault' : 'btnNext'}><BiFirstPage /> <p>Primeira Página</p></button>

              <button onClick={() => handlePageChange(page - 1)} className={page === 1 ? 'btnDefault' : 'btnNext'}><GrFormPrevious /> <p>Anterior</p></button>

              <p>Página <span>{page}</span> de <span>{totalPages}</span> </p>

              <button onClick={() => handlePageChange(page + 1)} className={totalPages === page ? 'btnDefault' : 'btnNext'}><p>Próximo</p> <GrFormNext /></button>
              <button onClick={() => {
                if (page !== totalPages)
                  handlePageChange(totalPages)
              }} className={totalPages === page ? 'btnDefault' : 'btnNext'}><p>Última página</p> <BiLastPage /></button>

            </div>
          </ModuleExtract>
        </Content>
        :
        <LoaderComponent />
      }
      <FooterComponent />
    </Container>
  )
};

export default Sales;