import { lighten } from "polished";
import styled, { css } from "styled-components";

interface IProps {
	primary_color?: string;
	secundary_color?: string;
	name?: string;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: space-between;
	height: 100vh;

	li {
		list-style: none;
		display: inline-block;


		button {
			content: "";
			width: 8px;
			height: 8px;
			border: 0px;
			border-radius: 50%;
			background: #dbdfea;
			cursor: pointer;
		}

		button.active {
			background: #364a63;
		}
		:last-child {
			
		}
	}
`;

export const Content = styled.div`
	max-width: 1273px;
	margin: auto;
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
	padding: 44px;

	@media (max-width: 500px) {
		padding: 24px;
	}

	.listHeader {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 10px;

		p {
			font-size: 13px;
			margin-right: 10px;
			color: #8094ae;
		}
		.btnExcel {
			:hover {
				background: #ccd3e5;
				color: #003967;
			}
		}
	}
`;

export const PaymentModule = styled.div`
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;

	@media (max-width: 812px) {
		flex-direction: column;
	}
`;

export const PaymentTableContent = styled.section<IProps>`
	flex: 0.75;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	margin-right: 20px;
	@media (max-width: 812px) {
		margin-right: 0;
		margin-bottom: 20px;
	}

	p {
		font-size: 14px;
		flex: 1;
		width: 100%;
		font-weight: 900;
		color: #8094ae;
	}

	.calendarSection {
		display: flex;
		flex-direction: column;

		.buttonsCalendar {
			display: flex;
			width: 100%;
			justify-content: space-between;
			flex-direction: row;

			button {
				display: flex;
				justify-content: center;
				align-items: center;
				height: fit-content;
				background: #dbdfea;
				color: #8094ae;
				border: 0px;
				padding: 5px 10px;
				border-radius: 10px;
				cursor: pointer;
				transition: 0.2s ease-in-out;

				@media (max-width: 812px) {
					margin-top: 10px;
				}

				svg {
					color: #526484;
					margin-left: 10px;
					font-size: 18px;
					transition: 0.2s ease-in-out;
				}

				:hover {
					color: #003967;
					background: #ccd3e5;

					svg {
						transform: scale(1.1);
						transition: 0.2s ease-in-out;
					}
				}
			}

			.btnExport {
				margin-left: 10px;

				@media (max-width: 500px) {
					margin-left: 0;
				}
			}

			@media (max-width: 568px) {
				flex-direction: column;

				button {
					font-size: 11px;
					padding-right: 10px;
				}
			}
		}
	}
`;

export const PaymentDetails = styled.section`
	flex: 0.25;
	height: fit-content;
	display: flex;
	flex-direction: column;
	padding: 20px;
	background: #fff;
	color: #8094ae;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
	border-bottom: 3px solid #1ee0ac;
`;

export const TotalMonthAmount = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-bottom: 3px solid #1ee0ac;
	padding-bottom: 10px;
	margin-bottom: 20px;

	P {
		font-weight: 900;
		font-size: 18px;
		margin-bottom: 10px;
	}

	p:last-child {
		font-size: 30px;
		padding: 0;
		margin: 0;
		text-align: right;

		@media (max-width: 500px) {
			font-size: 22px;
		}
	}
`;
export const TotalDayAmount = styled.div<IProps>`
	position: relative;

	@media (max-width: 812px) {
		height: fit-content;
	}

	.negative {
		color: #e85347;
	}

	.positive {
		color: #18b389;
	}

	.summary {
		white-space: nowrap;
		.summary-item {
			display: flex;
			align-items: center;
			padding: 15px 0px;
			margin: 10px 0px;

			border-bottom: 1px solid rgba(0,0,0,0.2);

			.brand {
				margin-right: 10px;
				min-width: 35px;
				max-width: 35px;
			}

			.product {
				text-transform: lowercase;
			}

			.amount {
				width: 100%;
				text-align: end;
			}
		}

		.summary-item:last-child {
			border-color: #1ee0ac;
			border-width: 3px;


			.amount {
				color:#1ee0ac;
			}
		}
	}

	div {
		border-bottom: 0;
		margin: 0;

		:first-child {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
			font-weight: 900;

			svg {
				vertical-align: middle;
				font-size: 30px;
				margin-right: 10px;
			}
		}

		.finalBalance {
			font-weight: 900;
		}

		.finalBalanceNegative {
			border-bottom: 2.5px solid #e85347;
		}

		.finalBalancePositive {
			border-bottom: 2.5px solid #1ee0ac;
		}

		.finalBalanceNegative {
			border-bottom: 2.5px solid #e85347;
		}

		.finalBalancePositive {
			border-bottom: 2.5px solid #1ee0ac;
		}

		.detailActionsSection {
			margin-top: 20px;

			button {
				color: #003967;
				background: transparent;
				border: 2px solid #ddd;
				border-radius: 4px;
				padding: 5px;
				width: 100%;
				font-size: 14px;
				text-align: left;
				flex: 1;
				cursor: pointer;
				margin-bottom: 10px;
				transition: 0.4s ease;

				:hover {
					transition: 0.4s ease;
					${(props) => css`
						border-color: ${props.secundary_color};
					`}
				}

				:last-child {
					margin: 0;
				}

				svg {
					font-size: 16px;
					${(props) => css`
						color: ${props.secundary_color};
					`}
					margin-right:5px;
					vertical-align: sub;
				}
			}
		}
	}
`;

export const ModuleExtract = styled.div`
	display: flex;
	width: 100%;
	margin-right: 20px;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);

	button {
		background: #f7f8fc;
		border: 1px solid #c3cce1;
		font-size: 12px;
		color: #526484;
		border: 0px;
		padding: 5px 10px;
		border-radius: 10px;
		cursor: pointer;
		transition: 0.2s ease-in-out;
		display: flex;
		align-items: center;

		svg {
			margin: 0px;
			font-size: 15px;
		}
	}

	.btnExcel {
		:hover {
			svg {
				transform: scale(1.2);
				transition: 0.2s ease-in-out;
			}
		}
		svg {
			margin-left: 10px;
		}
	}

	.btnForPages {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		color: #526484;

		.btnPrevious,
		.btnNext,
		.btnExcel {
			:hover {
				background: #ccd3e5;
				color: #003967;
			}
		}

		.btnDefault {
			cursor: default;
		}

		@media (max-width: 500px) {
			svg {
				padding: 0;
				margin: 0;
			}

			.btnPrevious,
			.btnNext,
			.btnDefault {
				p {
					display: none;
				}
			}
		}
	}
`;

export const SalesContent = styled.div`
	margin-bottom: 10px;

	@media (max-width: 1145px) {
		overflow-x: auto;
	}

	table {
		border: 0px;
		width: 100%;
		font-size: 14px;
		font-weight: lighter;
		color: #526484;
		transition: 0.2s;

		@media (max-width: 500px) {
			font-size: 12px;
		}

		tr {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #ddd;

			:hover {
				font-weight: 900;
			}

			:first-child {
				text-transform: uppercase;
				border-bottom-color: #526484;
			}

			/* :nth-child(n+2) {
        cursor: pointer;
      } */

			:last-child {
				border-bottom: 0;
				margin-bottom: 0;
				padding-bottom: 0;
			}

			img {
				width: 30px;
				vertical-align: middle;
			}

			td,
			th {
				width: 100px;
				text-align: left;
				padding: 5px 10px;
				font-weight: lighter;

				:nth-child(2) {
					width: 136px;
				}

				:nth-child(n + 2) {
					text-align: center;
				}
			}

			th {
				font-weight: bold;
			}

			td:last-child {
				text-align: right;
				position: relative;

				p {
					content: "";
					position: absolute;
					width: 80%;
					height: 25%;
					top: 22px;
					left: 10px;
					border: 0px;
					border-radius: 20px;
					background: #ddd;

					:after {
						content: "";
						position: absolute;
						width: 60%;
						height: 100%;
						top: 0px;
						left: 0px;
						border: 0px;
						border-radius: 20px;
						background: #526484;
					}
				}
			}
		}

		.noReceivables {
			display: flex;
			justify-content: center;
			height: 75px;
			font-style: italic;

			:hover {
				font-weight: normal;
			}
		}
	}
`;
export const CalendarContainer = styled.div`
	width: 100%;
	position: relative;
	margin-top: 25px;
	box-sizing: border-box;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);

	button:first-child {
		position: absolute;
		padding: 5px 10px;
		right: 0;
		top: -45px;
		color: #8094ae;
		background: #fff;
		border: 0;
		border-radius: 10px;
		cursor: pointer;
		box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
		transition: 0.2s ease-in-out;

		@media (max-width: 321px) {
			font-size: 12px;
			svg {
				display: none;
			}
		}

		:hover {
			color: #003967;
			background: #ccd3e5;
			transition: 0.2s ease-in-out;

			svg {
				color: #003967;
				transform: scale(1.05);
			}
		}

		svg {
			width: 20px;
			margin-left: 5px;
			transition: 0.2s;
		}
	}
`;

export const Header = styled.header<IProps>`
	display: flex;
	width: 100%;
	height: 3rem;
	color: black;
	font-weight: 700;
	display: flex;
	align-items: center;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	color: #fff;
	${(props) => css`
		background: ${props.primary_color};
	`}

	.current {
		text-transform: capitalize;
	}

	span {
		flex: 1;
		font-size: 20px;
	}

	svg {
		flex: 1;
		width: 100%;
		vertical-align: middle;
		height: 25px;
		color: #8094ae;
		cursor: pointer;
		transition: 0.2s ease-in-out;

		:hover {
			transition: 0.2s ease-in-out;
			transform: scale(1.1);
			color: #fff;
		}
	}
`;

export const DayNames = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	align-items: center;
`;

export const Current = styled.div``;

export const Week = styled.div`
	width: calc(100% / 7);
	padding: 5px 0;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
	background: #cecece;

	@media (max-width: 500px) {
		width: calc(100% / 5);

		:first-child,
		:last-child {
			display: none;
		}
	}
`;

export const Body = styled.div`
	.calendar {
		position: relative;
		height: 80px;
	}
`;

export const Day = styled.div<IProps>`
	position: relative;
	width: calc(100% / 7);
	height: 100%;
	display: inline-flex;
	background: #fff;
	border: 1px solid #f5f6fa;
	text-align: center;
	cursor: pointer;

	@media (max-width: 500px) {
		width: calc(100% / 5);

		:first-child,
		:last-child {
			display: none;
		}
	}

	div {
		position: relative;
		width: 100%;
		height: 100%;
		transition: 0.2s;
		color: #8094ae;

		.calendarDay {
			font-family: "Roboto";
			position: absolute;
			top: 4.5px;
			right: 5px;
			width: fit-content;
			height: fit-content;
			font-size: 11px;
			text-align: right;
			z-index: 5;
		}

		.statusValue {
			position: absolute;
			top: 16px;
			white-space: nowrap;
			left: 50%;
			font-size: 12px;
			transform: translateX(-50%);
			text-transform: capitalize;
			width: fit-content;
			font-weight: 550;
			letter-spacing: 1px;
			z-index: 5;
			color: #999999 !important;
			@media (max-width: 813px) {
				font-size: 10px;
			}

			@media (max-width: 500px) {
				display: none;
			}
		}

		#amountValue {
			display: flex;
			justify-content: center;
			line-height: 90px;
			position: absolute;
			width: 95%;
			height: 95%;
			font-size: 14px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 3px 6px;
			z-index: 4;
			color: #8094ae;

			@media (max-width: 813px) {
				font-size: 13px;
			}

			@media (max-width: 500px) {
				line-height: 0;
				align-items: center;
				font-weight: 600;
				font-size: 10px;
			}
		}
	}
	.selected {
		width: 100%;
		z-index: 8;

		${(props) => css`
			border-left: 4px solid ${props.secundary_color} !important;
		`}

		#amountValue {
			border: 0 !important;
		}
	}

	/* .today {
  width:100%;
  z-index:10;
  ${(props) =>
		props.secundary_color &&
		css`
     border: 1px solid ${lighten(0.3, props.secundary_color)}}
     `}} */

	.weekend,
	.notThisMonth {
		cursor: default;
		opacity: 0.4;

		p:nth-child(2) {
			color: transparent;
		}
	}
`;
