import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, LinkContent } from './styles';



const ProfileNav: React.FC = () => {

  const [linkName, setLinkName] = useState("perfil")

  useEffect(() => {
    setLinkName(document.URL.substring(document.URL.lastIndexOf('/') + 1))
  }, [linkName])


  return (
    < Container >
      <LinkContent isActive={linkName === "perfil" ? true : false}>
        <Link to='/perfil' >
          Dados
        </Link>
      </LinkContent>
      <LinkContent isActive={linkName === "seguranca" ? true : false}>
        <Link to='/perfil/seguranca'>
          Segurança
        </Link>
      </LinkContent>
      {/* <LinkContent isActive={linkName === "notificacoes" ? true : false}>
        <Link to='/perfil/notificacoes'>
          Notificações
        </Link>
      </LinkContent> */}
      {/* <LinkContent isActive={linkName === "atividade-login" ? true : false}>
        <Link to='/perfil/atividade-login'>
          Atividade de Login
        </Link>
      </LinkContent> */}
    </ Container>
  );
}
export default ProfileNav;
