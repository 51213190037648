import axios from "axios";
import { generateEncryption } from "../utils/generateDNSEncryption";


const getBaseUrl = () => {
	let apiURL = process.env.REACT_APP_PROD_URL_API;

	if(process.env.NODE_ENV === 'development') {
		apiURL = process.env.REACT_APP_DEV_URL_API;
	}

	return apiURL;
}

const api = axios.create({
	baseURL: getBaseUrl(),
	headers: {
		"Content-Type": "application/json",
		dns: generateEncryption().toString(),
	},
});

export default api;

export {
	getBaseUrl
}
