/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import ICompany from "../../@types/company";
import generateTheme from "../../utils/generateTheme";
import { Container, Content, Slider, Button, CarouselContent } from "./styles";

interface ICarouselData {
  description: string;
  content: string;
  isActive: boolean;
  img?: string;
  id: number;
}

interface IProps {
  data: ICarouselData[];
}

const Carousel: React.FC<IProps> = ({ data }: IProps) => {
  const [timer, setTimer] = useState(0);
  const [carouselData, setCarouselData] = useState<ICarouselData[]>(data);
  const [currentID, setCurrentID] = useState(0);
  const [link, setLink] = useState<ICompany>();

  useEffect(() => {
    setLink(generateTheme);

    setCarouselData(data);
  }, [data]);

  function resetTimer() {
    setTimer(0);
  }

  useEffect(() => {
    let interval: any;
    interval = setInterval(() => {
      setTimer((timer) => timer + 1);
      if (timer === 2) {
        if (currentID === carouselData.length - 1) {
          handleSelection(0);
        } else handleSelection(currentID + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer, carouselData, currentID]);

  const handleSelection = useCallback(
    (id: number) => {
      setCurrentID(id);

      let newData = carouselData.map(function (value) {
        // create a new object to store full name.
        var newObj: ICarouselData;

        newObj = {
          content: value.content,
          description: value.description,
          id: value.id,
          img: value.img ? value.img : "",
          isActive: id === value.id ? true : false,
        };

        // return our new object.
        return newObj;
      });
      setCarouselData(newData);
      resetTimer();
    },
    [carouselData]
  );

  return (
    <Container className="carousel">
      <Content>
        {carouselData.map((value) => (
          <CarouselContent
            key={value.id}
            className="content"
            isActive={value.isActive}
          >
            <img src={value.img} alt="img" style={{ display: "block" }}></img>
            <h1>{value.description}</h1>
            <p>{value.content}</p>
          </CarouselContent>
        ))}
        <Slider className="slider">
          {carouselData.map((value) => (
            <li key={value.id}>
              <Button
                secundary_color={link?.style.secundary_color}
                isActive={value.isActive}
                onClick={() => handleSelection(value.id)}
              ></Button>
            </li>
          ))}
        </Slider>
      </Content>
    </Container>
  );
};
export default Carousel;
