import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Container,
  Content,
  InfoContent,
} from './styles'
import HeaderComponent from '../../../components/Header'
import FooterComponent from '../../../components/Footer'
import * as Yup from "yup";
import { Form } from '@unform/web';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ProfileNav from '../../../components/ProfileNav';
import { FormHandles } from '@unform/core';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useAuth } from '../../../hooks/auth';
import ICompany from '../../../@types/company';
import generateTheme from '../../../utils/generateTheme';
import { useHistory } from 'react-router-dom';
import { redirectOnlyUserSale } from '../../../utils/verifyOnlySaleUser';


interface IResetPasswordFormData {
  old_password: string;
  password: string;
  password_confirmation: string;
}

const Security: React.FC = () => {
  const { user } = useAuth()
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState<ICompany>()
  useEffect(() => {
    redirectOnlyUserSale(history, user)
    setLink(generateTheme);
  }, [user, history])

  const handleSubmit = useCallback(

    async (data: IResetPasswordFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          old_password: Yup.string().required('Campo obrigatório'),
          password: Yup.string().when('old_password', {
            is: (val) => !!val.length,
            then: Yup.string().required('Campo obrigatório'),
            otherwise: Yup.string().min(
              4,
              "Senha obrigatória, no mínimo 4 digitos."
            ),
          }),
          password_confirmation: Yup.string().when('old_password', {
              is: (val) => !!val.length,
              then: Yup.string().required('Campo obrigatório'),
            otherwise: Yup.string().min(
              4,
              "Senha obrigatória, no mínimo 4 digitos."
            ),
          }).oneOf(
              [Yup.ref('password'), undefined],
            'As senhas devem ser iguais',
            ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        if (user) {
          await api.post('/estabelecimentos/reset', {
            type: 'reset',
            tax_id: user.tax_id,
            email: user.email,
            current_password: data.old_password,
            password: data.password,
            confirm_password: data.password_confirmation,
          }).then(response => {
            if (response.status === 200)
              addToast({
                title: "Perfil atualizado!",
                type: "success",
                description:
                  "Sua senha foi redefinida com sucesso!"
              })
          }).finally(() => setLoading(false));
        }

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao atualizar informações de perfil.',
          description:
            'Ocorreu um erro ao redefinir senha, tente novamente mais tarde.',
        });
        return;
      }
    },
    [addToast, user],
  );

  return (
    <Container>
      <HeaderComponent linkValue={"perfil"} />
      <Content>
        <ProfileNav />
        <InfoContent primaryColor={link?.style.primary_color} className="singleRow">
          <img style={{ width: '200px' }} src={link?.style.url_logo_default} alt="logo BC"></img>
          <Form ref={formRef} onSubmit={handleSubmit}>

            <Input
              name="old_password"
              type="password"
              placeholder="Senha atual"
            />
            <Input
              name="password"
              type="password"
              placeholder="Nova senha"
            />
            <Input
              name="password_confirmation"
              type="password"
              placeholder="Confirmação da senha"
            />
            <Button type="submit" loading={loading}>Alterar senha</Button>
          </Form>
        </InfoContent>
      </Content>
      <FooterComponent />
    </Container>
  )
};



export default Security;