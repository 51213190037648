import React, { useCallback, useEffect, useState } from "react";
import { Container, Content, InfoTitle, InfoContent } from "./styles";
import HeaderComponent from "../../components/Header";
import FooterComponent from "../../components/Footer";
import { FiHelpCircle } from "react-icons/fi";
import Button from "../../components/Button";
import api from "../../services/api";
import moment from "moment";
import formatValue from "../../utils/formatValue";
import { useToast } from "../../hooks/toast";
import generateTheme from "../../utils/generateTheme";
import ICompany from "../../@types/company";
import { redirectOnlyUserSale } from "../../utils/verifyOnlySaleUser";
import { useAuth } from "../../hooks/auth";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

interface IExtractData {
	amount: number;
	balance: number;
	date: Date;
	desc_balance: number;
	old_balance: number;
	type_balance: string;
}

interface IAccountData {
	available: number;
	freq_payment: number;
	next_payment: number;
	waiting_funds: number;
}

const Statment: React.FC = () => {
	const { addToast } = useToast();
	const { user } = useAuth();
	const history = useHistory();
	const [weekExtract, setWeekExtract] = useState<IExtractData[]>([]);
	const [accountExtract, setAccountExtract] = useState<IAccountData>();
	const [selectedFilter, setSelectedFilter] = useState("Última semana");
	const [loading, setLoading] = useState(true);
	const [customDate, setCustomDate] = useState(false);
	const [startCustomDate, setStartCustomDate] = useState("");
	const [endCustomDate, setEndCustomDate] = useState("");
	const [link, setLink] = useState<ICompany>();

	const handleCustomDateFilter = useCallback(() => {
		try {
			setLoading(true);
			api
				.get("/estabelecimentos/balanco", {
					params: {
						datestart: startCustomDate,
						dateend: endCustomDate,
					},
				})
				.then((response) => {
					api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
					setLoading(false);
					setWeekExtract(response.data.result.transactions);
				});
		} catch (err) {
			addToast({
				type: "error",
				title: "Erro ao atualizar informações",
				description:
					"Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
			});
		}
	}, [startCustomDate, endCustomDate, addToast]);

	const handleExtractFilter = useCallback(
		(value) => {
			let startDate;
			let endDate;

			if (value === selectedFilter) return;
			if (value === "ultima_semana") {
				//Fazendo uma lista de última semana
				startDate = moment().subtract(1, "week").format("YYYY-MM-DD");
				endDate = moment().format("YYYY-MM-DD");
				setCustomDate(false);
				setSelectedFilter("Última semana");
			} else if (value === "ultimo_mes") {
				//Aqui faz uma lista de último mês
				startDate = moment()
					.subtract(1, "month")
					.startOf("month")
					.format("YYYY-MM-DD");
				endDate = moment()
					.subtract(1, "month")
					.endOf("month")
					.format("YYYY-MM-DD");
				setCustomDate(false);
				setSelectedFilter("Último mês");
			} else if (value === "mes_atras") {
				//Aqui faz um mês atrás
				startDate = moment().subtract(4, "week").format("YYYY-MM-DD");
				endDate = moment().format("YYYY-MM-DD");
				setCustomDate(false);
				setSelectedFilter("1 Mês atrás");
			} else if (value === "data_personalizada") {
				//Aqui faz dois dias selecionados
				setCustomDate(true);
				setSelectedFilter("Data pernonalizada");
				return;
			}

			try {
				setLoading(true);
				api
					.get("/estabelecimentos/balanco", {
						params: {
							datestart: startDate,
							dateend: endDate,
						},
					})
					.then((response) => {
						api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

						setWeekExtract(response.data.result.transactions);
					})
					.finally(() => setLoading(false));
			} catch (err) {
				addToast({
					type: "error",
					title: "Erro ao atualizar informações",
					description:
						"Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
				});
			}
		},
		[addToast, selectedFilter]
	);

	useEffect(() => {
		redirectOnlyUserSale(history, user);
		setLink(generateTheme);

		let startDate;
		let endDate;

		//Padrão de última semana
		startDate = moment().subtract(1, "week").format("YYYY-MM-DD");
		endDate = moment().format("YYYY-MM-DD");
		setSelectedFilter("Última semana");

		if (document.URL.includes("?")) {
			let dateParam = document.URL.substring(document.URL.indexOf("=") + 1);
			startDate = dateParam;
			endDate = dateParam;
			setSelectedFilter(moment(dateParam).format("dddd DD/MM/YYYY"));
		}
		try {
			api.get("/estabelecimentos/conta").then((response) => {
				setAccountExtract(response.data.result);
			});

			api
				.get("/estabelecimentos/balanco", {
					params: {
						datestart: startDate,
						dateend: endDate,
					},
				})
				.then((response) => {
					api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
					setLoading(false);
					setWeekExtract(response.data.result.transactions);
				});
		} catch (err) {
			addToast({
				type: "error",
				title: "Erro ao atualizar informações",
				description:
					"Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
			});
		}
		//Exibe os valores de Lançamentos Futuros e Saldo Disponível
	}, [addToast, history, user]);

	if (loading) {
		return (
			<Container>
				<HeaderComponent linkValue="movimentacoes" />
				<Content>
					<InfoTitle>
						<div className="showTitles">
							<h3>Movimentações</h3>
						</div>
						<div className="showValues">
							{accountExtract ? (
								<>
									<section>
										<p>Lançamentos Futuros</p>
										<span>{formatValue(accountExtract.waiting_funds)}</span>
									</section>
									<section>
										<p>Saldo Disponível</p>
										<span>{formatValue(accountExtract.available)}</span>
									</section>
								</>
							) : (
								<>
									<section>
										<p>Lançamentos Futuros</p>
										<Skeleton
											variant="rect"
											height="50px"
											width="100px"
											animation="wave"
										/>
									</section>
									<section>
										<p>Saldo Disponível</p>
										<Skeleton
											variant="rect"
											height="50px"
											width="120px"
											animation="wave"
										/>
									</section>
								</>
							)}
						</div>
					</InfoTitle>
					<InfoContent primary_color={link?.style.primary_color}>
						<section>
							<h3>Histórico de Movimentações</h3>
							<div>
								<Button onClick={() => handleExtractFilter("ultima_semana")}>
									Última Semana
								</Button>
								<Button onClick={() => handleExtractFilter("ultimo_mes")}>
									Último Mês
								</Button>
								<Button onClick={() => handleExtractFilter("mes_atras")}>
									1 Mês Atrás
								</Button>
								<Button
									onClick={() => handleExtractFilter("data_personalizada")}
								>
									Data Personalizada
								</Button>
							</div>
						</section>
						<Skeleton
							variant="rect"
							height="500px"
							width="100%"
							animation="wave"
						/>
					</InfoContent>
				</Content>
			</Container>
		);
	}

	return (
		<Container>
			<HeaderComponent linkValue="movimentacoes" />
			<Content>
				<InfoTitle>
					<div className="showTitles">
						<h3>Movimentações</h3>
					</div>
					<div className="showValues">
						{accountExtract && (
							<>
								<section>
									<p>Lançamentos Futuros</p>
									<span>{formatValue(accountExtract.waiting_funds)}</span>
								</section>
								<section>
									<p>Saldo Disponível</p>
									<span>{formatValue(accountExtract.available)}</span>
								</section>
							</>
						)}
					</div>
				</InfoTitle>
				<InfoContent primary_color={link?.style.primary_color}>
					<section>
						<h3>Histórico de Movimentações</h3>
						<div>
							<Button onClick={() => handleExtractFilter("ultima_semana")}>
								Última Semana
							</Button>
							<Button onClick={() => handleExtractFilter("ultimo_mes")}>
								Último Mês
							</Button>
							<Button onClick={() => handleExtractFilter("mes_atras")}>
								1 Mês Atrás
							</Button>
							<Button onClick={() => handleExtractFilter("data_personalizada")}>
								Data Personalizada
							</Button>
						</div>
					</section>
					<section>
						<div></div>
						{customDate && (
							<div className="customDate">
								<label>Data inicial</label>
								<input
									type="date"
									value={startCustomDate}
									defaultValue={moment().format("YYYY-MM-DD")}
									onChange={(e) =>
										setStartCustomDate(
											moment(e.currentTarget.value).format("YYYY-MM-DD")
										)
									}
								/>
								<label>Data Final</label>
								<input
									type="date"
									value={endCustomDate}
									defaultValue={moment().format("YYYY-MM-DD")}
									onChange={(e) =>
										setEndCustomDate(
											moment(e.currentTarget.value).format("YYYY-MM-DD")
										)
									}
								/>
								<Button type="submit" onClick={handleCustomDateFilter}>
									Filtrar
								</Button>
							</div>
						)}
					</section>
					<p>{selectedFilter}</p>
					<div className="extractTable">
						<table>
							<tbody>
								<tr>
									<th>Data</th>
									<th>Tipo de lançamento</th>
									<th>Saldo anterior</th>
									<th>Valor lançamento</th>
									<th>Saldo final</th>
								</tr>

								{weekExtract.length > 0 ? (
									weekExtract.map((value, index) => (
										<tr key={index}>
											<td>{moment(value.date).format("DD / MM / YYYY")}</td>
											<td>{value.type_balance}</td>
											{value.type_balance === "AJUSTE A DÉBITO" ? (
												<td>
													<span>
														<FiHelpCircle
															title={value.desc_balance.toString()}
														/>
													</span>{" "}
													{formatValue(value.old_balance)}{" "}
												</td>
											) : (
												<td>{formatValue(value.old_balance)}</td>
											)}
											<td>{formatValue(value.amount)}</td>
											<td>{formatValue(value.balance)}</td>
										</tr>
									))
								) : (
									<tr className="noMoves">
										Sem movimentações para este período
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</InfoContent>
			</Content>
			<FooterComponent />
		</Container>
	);
};

export default Statment;
