import React, { ButtonHTMLAttributes, useEffect, useState } from "react";
import { FiLoader } from "react-icons/fi";
import ICompany from "../../@types/company";
import generateTheme from "../../utils/generateTheme";
import { Container } from "./styles";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({ children, loading, ...rest }) => {
  const [link, setLink] = useState<ICompany>();
  useEffect(() => {
    setLink(generateTheme);
  }, []);

  return (
    <Container
      primary_color={link?.style.primary_color}
      secundary_color={link?.style.secundary_color}
    >
      <button type="button" disabled={loading} {...rest}>
        {loading ? (
          <span>
            Carregando... <FiLoader id="loader" />
          </span>
        ) : (
          children
        )}
      </button>
    </Container>
  );
};
export default Button;
