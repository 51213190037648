import styled, { css } from "styled-components";

interface IInstallmentsPaidProps {
	paid: number;
	cancelled?: boolean;
}

interface IPaymentStatusProps {
	cancelled?: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: space-between;
	height: 100vh;

	li {
		list-style: none;
		display: inline-block;
		padding: 0 4px;

		button {
			content: "";
			width: 8px;
			height: 8px;
			border: 0px;
			border-radius: 50%;
			background: #dbdfea;
			cursor: pointer;
		}

		button.active {
			background: #364a63;
		}

		:last-child {
			padding-right: 0px;
		}
	}
`;

export const Content = styled.div`
	flex: 1;
	max-width: 1273px;
	width: 100%;
	margin: auto;
	display: flex;
	flex-direction: column;
	padding: 44px;

	@media (max-width: 500px) {
		padding: 24px;
	}
`;

export const TransactionSection = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);

	.goBackBtn {
		width: fit-content;
		display: flex;
		color: #8094ae;
		margin-bottom: 10px;
		font-size: 14px;
		cursor: pointer;

		:hover {
			font-weight: 600;
			transition: 0.1s;
			svg {
				transform: scale(1.2);
				transition: 0.2s ease-in-out;
			}
		}

		svg {
			align-self: center;
			font-size: 18px;
			margin-right: 5px;
		}
	}

	.transactionTitle {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #dfdfdf;

		@media (max-width: 500px) {
			flex-direction: column;
		}

		p {
			font-size: 13px;
			padding: 0px;
			color: #8094ae;

			:first-child {
				font-weight: 900;
				text-transform: uppercase;
			}

			:nth-child(2) {
				font-weight: 100;
				text-transform: capitalize;

				@media (max-width: 500px) {
					margin: 10px 0;
				}

				span {
					padding: 2px 5px;
					border-radius: 10px;
					color: #fff;
				}
			}

			span {
				font-weight: 900;
			}
		}
	}
`;

export const TransactionValues = styled.ul`
	display: flex;
	flex-direction: column;

	ul {
		padding: 0px;
		font-size: 13px;
		font-weight: lighter;
		color: #526484;
		list-style: none;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@media (max-width: 812px) {
			justify-content: right;
		}

		li {
			margin: 10px 0;
			display: flex;
			flex-direction: column;

			label {
				font-size: 13px;
				color: #8094ae;
			}

			span {
				font-size: 1.1rem;
				font-weight: 900;
			}

			img {
				width: 30px;
			}


		}
	}


`;

export const StatusSection = styled.section`
	margin: 20px 0;
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
	border-bottom: 3px solid #1ee0ac;

	.titleStatus {

		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		color: #8094ae;

		p {
			font-size: 13px;
			margin-right: 10px;
			margin-bottom: 10px;
		}
		:last-child {
			position: relative;
		}

		div {
			width: 100px;
			text-align: left;
			padding: 5px 10px;
			font-weight: lighter;

			position: relative;
		}
	}
`;

export const InstallmentsModule = styled.div`
	display: flex;
	width: 100%;
	margin-right: 20px;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);

	.titleStatus {
		p {
			font-size: 13px;
			margin-right: 10px;
			color: #8094ae;
		}
	}

	button {
		background: #f7f8fc;
		border: 1px solid #c3cce1;
		color: #526484;
		border: 0px;
		padding: 10px;
		padding-right: 0px;
		border-radius: 10px;
		cursor: pointer;
		transition: 0.2s ease-in-out;
		display: flex;
		align-items: center;

		svg {
			margin: 0px 10px;
			font-size: 18px;
		}

		:hover {
			background: #ccd3e5;
			color: #003967;
		}
	}

	.btnForPages {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 13px;
		color: #526484;

		.btnPrevious {
			padding-right: 10px;
			padding-left: 0px;
		}
	}
`;

export const InstallmentsContent = styled.div<IPaymentStatusProps>`
	@media (max-width: 1135px) {
		overflow-x: auto;
	}

	table {
		border: 0px;
		width: 100%;
		font-size: 13px;
		font-weight: lighter;
		color: #526484;
		transition: 0.2s;

		tr {
			display: flex;
			justify-content: space-between;
			align-items: center;
			transition: 0.2s ease-in-out;
			border-bottom: 1px solid #ddd;
			padding: 12px 0;

			:first-child {
				text-transform: uppercase;
				padding: 0;
				border-bottom-color: #526484;
			}

			:last-child {
				border: 0;
				padding-bottom: 0;
			}

			svg {
				font-size: 30px;
				vertical-align: middle;
			}

			td,
			th {
				width: 100px;
				text-align: center;
				font-weight: lighter;
				padding: 0 10px;

				:last-child {
					text-align: right;
					width: 110px !important;
				}
			}

			th {
				font-weight: bold;

				:last-child {
					text-align: center;
				}
			}
		}
	}

	td:first-child {
		text-align: center;
	}

	td:last-child {
		position: relative;

		:before {
			content: "";
			position: absolute;
			width: 7px;
			height: 7px;
			top: 50%;
			left: 15px;
			margin-left: -5px;
			transform: translateY(-50%);
			${(props) =>
				!props.cancelled &&
				css`
					background: #09c2de;
				`}
			${(props) =>
				props.cancelled &&
				css`
					background: #e85347;
				`}
     
      border-radius: 50%;
		}
	}
`;

export const InstallmentsPaid = styled.p<IInstallmentsPaidProps>`
	width: 100%;
	content: "";
	position: relative;
	height: 10px;
	border: 0px;
	border-radius: 20px;

	${(props) =>
		!props.cancelled &&
		css`
			background: #ddd;
		`}
	${(props) =>
		props.cancelled &&
		css`
			background: #e85347;
		`}

  :after {
		${(props) =>
			props.paid &&
			css`
				width: ${props.paid}%;
			`}
		background: #364a63;
		content: "";
		position: absolute;
		height: 10px;
		border: 0px;
		border-radius: 20px;
	}
`;
