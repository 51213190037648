import React from "react";
import { Pie } from "@nivo/pie";
import { Container } from "./styles";
import { IChartData, IMedia } from "../../@types/charts";
import { Skeleton } from "@material-ui/lab";

interface IChartProps {
	chart: Array<IChartData>;
	chartMediaQuery: IMedia;
	total: number;
	loading: boolean;
}

export const Chart: React.FC<IChartProps> = ({
	chart,
	chartMediaQuery,
	total,
	loading,
}) => {
	if (loading) {
		return (
			<Container>
				<Skeleton animation="wave" variant="rect" width="100%" height="100px" />
			</Container>
		);
	}

	if (chart.length === 0) {
		return (
			<div className="noData">
				<p>Sem dados para o período</p>
			</div>
		);
	}
	return (
		<Container>
			<Pie
				height={chartMediaQuery.height}
				width={chartMediaQuery.width}
				data={chart}
				margin={{ top: 30, right: 150, bottom: 40, left: 30 }}
				innerRadius={0.45}
				padAngle={1.5}
				fit={true}
				borderWidth={1}
				borderColor={{ from: "color", modifiers: [["brighter", 0.2]] }}
				cornerRadius={3}
				arcLabelsTextColor="#ffffff"
				arcLabelsSkipAngle={30}
				arcLabel={(d: any) => ((d.value / total) * 100).toFixed(2) + "%"}
				// colors={{ datum: 'data.color' }}
				colors={{ scheme: "dark2" }}
				enableArcLinkLabels={false}
				legends={[
					{
						anchor: "right",
						direction: "column",
						justify: false,
						translateX: 130,
						translateY: 0,
						itemWidth: 100,
						itemHeight: 20,
						itemsSpacing: 0,
						symbolSize: 20,
						itemDirection: "left-to-right",
					},
				]}
			/>
		</Container>
	);
};
