export const cnpjMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const cpfMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2");
};

export const cleanMaskTaxID = (value) => {
  return value?.replace(/\./g, "")?.replace("-", "")?.replace("/", "");
};

export const handleTaxIDInput = (value) => {
  let taxID = cleanMaskTaxID(value);
  if (taxID.length >= 14) {
    taxID = cnpjMask(taxID);
  } else {
    taxID = cpfMask(taxID);
  }
  return taxID;
};
