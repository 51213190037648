import styled, { css } from "styled-components";

interface IProps {
	primaryColor?: string;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: space-between;
	height: 100vh;
`;

export const Card = styled.div<IProps>`
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);

	${(props) => css`
		border-bottom: 3px solid ${props.primaryColor};
	`}

	display: flex;
	flex-direction: column;
`;

export const CardTitle = styled.h3`
	color: #364a63;
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
`;

export const CardBody = styled.div`
	overflow: auto;
	min-height: 150px;
	max-height: 150px;
	flex: 1;
	overflow: auto;
`;

export const Content = styled.div`
	max-width: 1273px;
	margin: auto;
	flex-direction: row;
	flex-wrap: wrap;
	display: flex;
	width: 100%;
	padding: 44px;
`;

export const Info = styled.div`
	p {
		margin-bottom: 20px;
		font-weight: bold;
	}

	span {
		font-weight: normal;
	}
`;

export const Table = styled.table`
	width: 100%;
	font-size: 14px;
	font-weight: lighter;
	color: #526484;
	transition: 0.2s;

	tr {
			display: flex;
			justify-content: space-between;
			align-items: center;
			transition: 0.2s ease-in-out;
			border-bottom: 1px solid #ddd;
			padding: 6px 0;

			:first-child {
				padding: 0;
				border-bottom: 1px solid;
			}

			:last-child {
				border-bottom: 0px;
				padding-bottom: 0;
				margin: 0;
			}

			td,
			th {
				width: 100%;
				text-align: left;
				padding: 4px 10px;
				font-weight: lighter;

				:nth-child(n + 2) {
					text-align: center;
				}
			}

			th {
				font-weight: bold;
			}

			th:last-child {
				text-align: right;
			}

			td:last-child {
				text-align: right;
				position: relative;
			}
		}
`;

export const Link = styled.a`
	color: #01295F;
	background-color: transparent;
	font-size: 14px;
	border: none;
	outline: none;
	cursor: pointer;
`;
