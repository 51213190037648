import styled from "styled-components";

interface IProps {
	primaryColor?: string;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: space-between;
	height: 100vh;
`;
export const Content = styled.div`
	max-width: 1273px;
	margin: auto;

	width: 100%;
	padding: 44px;

`;

export const InfoContent = styled.div<IProps>`
	flex: 1;

	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);

	@media (max-width: 500px) {
		min-width: 100% !important;
	}

	:first-child {
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		flex-wrap: wrap;
	}

	:nth-child(2) {
		margin: 0 0 20px 20px;

		@media (max-width: 500px) {
			margin: 0 0 20px 0px;
		}
	}

	:nth-child(3) {
		margin-right: 20px;

		@media (max-width: 500px) {
			margin-bottom: 20px;
		}
	}

	:nth-child(2),
	:nth-child(4) {
		min-width: 41%;
	}

	:nth-child(2),
	:nth-child(3) {
		min-width: 41%;
		border-bottom-color: #1ee0ac;
	}

	:nth-child(6) {
		border-bottom-color: #1ee0ac;
	}

	p,
	span,
	table {
		color: #526484;
	}

	h3 {
		color: #364a63;
		margin-bottom: 20px;
	}

	p {
		margin-bottom: 20px;
		font-weight: bold;

		span {
			font-weight: normal;
		}
	}

	table {
		width: 100%;
		font-size: 14px;
		font-weight: lighter;
		color: #526484;
		transition: 0.2s;

		tr {
			justify-content: space-between;
			align-items: center;
			transition: 0.2s ease-in-out;

			:last-child {
				border: 0;
				margin: 0;
				padding: 0;
			}

			td,
			th {
				width: 100px;
				text-align: left;
				padding: 5px 10px;
				font-weight: lighter;
				margin: 4px 0px;

				:nth-child(n + 2) {
					text-align: center;
				}
			}

			th {
				font-weight: bold;

				:last-child {
					text-align: right;
				}
			}

			td:last-child {
				text-align: right;

				p {
					font-weight: 100;
					font-size: 10px;
					font-style: italic;
				}
			}
		}
	}
`;

export const InfoTaxes = styled.div<IProps>`

	padding: 16px 20px;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
	width: 100%;

	.taxesTable {
		margin: 0;
		padding: 0;
		height: 400px;
		overflow-y: scroll;
		overflow-x: scroll;
	}
	

	h3 {
		color: #526484;
		margin-bottom: 15px;
		font-size: 14px;
	}

	position: relative;

	table {
		border-collapse: collapse;
		width: 100%;
		font-weight: lighter;
		color: #526484;
		font-size: 14px;
		min-width: 400px;
		

		td, th {
			text-align: center;
			padding: 10px;
		}

		thead {
			th {
				position: sticky;
				top: 0;
				background-color: #FFF;
			}
		}

		thead th:first-child {
			text-align: left;
		}

		tbody td:first-child {
			text-align: left;
		}

		tr {
			border-bottom: 1px solid #ddd;

		}

		@media (max-width: 500px) {
			font-size: 12px;
		}

		img {
			width: 30px;
		}

		svg {
			font-size: 30px;
		}


	}
`;