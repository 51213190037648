import styled, { css } from "styled-components";

interface IMenuProps {
	IsSideMenuActive: boolean;
	IsNavOptionActive?: boolean;
	primary_color?: string;
	secundary_color?: string;
	name?: string;
}

interface INotificationProps {
	hasNewNotification: boolean;
}

interface IProps {
	primary_color?: string;
	secundary_color?: string;
}

export const Header = styled.header<IProps>`
	${(props) =>
		props.primary_color &&
		css`
			background: ${props.primary_color};
		`}

	height: 73px;
	padding: 0 44px;
	position: relative;

	@media (max-width: 500px) {
		padding: 0 24px;
	}
`;

export const HeaderContent = styled.div<IMenuProps>`
	height: 100%;
	margin: auto;
	max-width: 1185px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	img {
		cursor: pointer;
	}

	.ground {
		@media (max-width: 1024px) {
			display: none;
			position: absolute;
			content: "";
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100vh;
			background: rgba(0, 0, 0, 0.4);
			transition: 0.2s ease-in-out;
		}
	}

	.show {
		@media (max-width: 1024px) {
			display: block;
			transition: 0.2s ease-in-out;
			z-index: 9;
		}
	}

	.NavIcon {
		display: flex;
		align-items: center;
		position: relative;

		.btnIcon {
			display: none;
			position: relative;
			width: 30px;
			height: 30px;
			left: -5px;
			background: transparent;
			border: 0;
			border-radius: 50%;
			cursor: pointer;
			z-index: 999;
			transition: 0.2s ease-in-out;

			:hover {
				transform: scale(1.2);
			}

			.hamb {
				position: absolute;
				width: 17.5px;
				height: 2px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 10px;
				background: #cecece;
				transition: 0.2s ease-in-out;

				:before {
					content: "";
					position: absolute;
					top: -5px;
					left: 0px;
					width: 17.5px;
					height: 2px;
					background: #cecece;
					border-radius: 10px;
					transition: 0.2s ease-in-out;

					:hover {
						background: #fff;
						transition: 0.2s ease-in-out;
					}
				}

				:after {
					content: "";
					position: absolute;
					top: 5px;
					left: 0px;
					width: 17.5px;
					height: 2px;
					background: #cecece;
					border-radius: 10px;
					transition: 0.2s ease-in-out;

					:hover {
						background: #fff;
						transition: 0.2s ease-in-out;
					}
				}
			}

			.active {
				background: transparent;
				transition: 0.2s ease-in-out;

				:before {
					transform: rotate(135deg);
					top: 0px;
				}

				:after {
					transform: rotate(-135deg);
					top: 0px;
				}
			}

			@media (max-width: 1239px) {
				display: block;

				@media (max-width: 415px) {
					position: absolute;
					left: 0px;
				}
			}
		}

		.active {
			position: fixed;
			left: 40px;
			transition-delay: 0.15s;
		}

		.navLogo {
			${(props) =>
				props.name?.toLowerCase() === "aqpago" &&
				css`
					height: 80px;
					width: 80px;
				`}
			transition: 0.2s ease-in-out;

			@media (max-width: 1239px) {
				margin-left: 10px;

				@media (max-width: 415px) {
					margin-left: 40px;
				}
			}
		}
	}

	.NavMenu {
		flex: 1;
		display: flex;
		align-items: center;
		height: 72px;
		z-index: 8;

		img {
			display: none;
		}

		div {
			position: relative;
			height: 100%;
			margin-left: 20px;

			@media (max-width: 1245px) {
				height: fit-content;
			}

			a {
				font-family: "Nunito" sans-serif;
				list-style: none;
				display: inline-block;
				position: relative;
				padding: 25px 16px;
				color: #cecece;
				font-weight: 100;
				letter-spacing: 1px;
				font-size: 12px;
				text-transform: uppercase;
				height: 100%;
				transition: 0.2s ease-in-out;

				:hover {
					color: #fff;
				}
			}

			svg {
				display: none;
				vertical-align: middle;
			}
		}

		.active {
			position: relative;
			border-top: 2px solid #fff;
			color: #fff;

			@media (max-width: 1245px) {
				border-top: 0px;
			}
		}

		@media (max-width: 1245px) {
			display: block;
			position: fixed;

			${(props) =>
				props.primary_color &&
				css`
					background: ${props.primary_color};
				`}

			top: 0px;
			left: -100%;
			width: 310px;
			height: 100vh;
			box-shadow: 1px 0px 10px rgb(0, 0, 0, 0.9);
			padding: 22px 44px;
			opacity: 0;
			z-index: 9;
			transition: 0.4s ease-in-out;

			.menuLogo {
				cursor: pointer !important;
				display: block;
				position: relative;
				width: 164.5px;
				left: 43px;

				${(props) =>
					props.name?.toLowerCase() === "aqpago" &&
					css`
						height: 80px;
						width: 80px;
					`}
			}

			div {
				margin-left: 30px;
				width: 140px;

				a {
					padding: 17.5px 16px;

					svg {
						position: absolute;
						display: block;
						font-size: 25px;
						top: 15px;
						left: -30px;
					}
				}
			}

			.menu {
				:nth-child(1) {
					margin-top: 15px;
				}
				:hover {
					:before {
						border-top: 0px;
						border-left: 3px solid #fff;
					}
				}
			}
		}
	}

	.active {
		opacity: 1;
		left: 0%;
		transition: 0.4s ease-in-out;
	}
`;

export const UserOptions = styled.div<IProps>`
	color: #fff;
	display: flex;
	align-items: center;
	color: #cecece;

	transition: 0.2s ease-in-out;

	.UserAvatar {
		font-size: 16px;
		font-weight: 900px;
		width: 32px;
		height: 32px;
		border: 0px;
		border-radius: 50%;

		${(props) =>
			props.secundary_color &&
			css`
				background: ${props.secundary_color};
			`}

		text-align: center;
		line-height: 38px;
		color: #fff;
		cursor: pointer;
		z-index: 8;
	}

	.UserStatusLogin {
		position: relative;
		height: 100%;

		.UserLogin {
			display: flex;
			font-size: 12px;
			color: #cecece;
			font-weight: bolder;
			vertical-align: middle;
			transition: 0.4s ease-in-out;
			padding-left: 10px;
			cursor: pointer;

			:hover {
				color: #fff;

				button svg {
					color: #fff;
					transform: rotate(-180deg);
					transition: 0.4s ease-in-out;
				}
			}

			section {
				display: flex;
				align-items: flex-end;
				flex-direction: column;

				p {
					text-align: right;
				}

				p:last-child {
					font-size: 10px;
				}
			}

			.ArrowUpDown {
				background: transparent;
				border: 0px;

				svg {
					position: relative;
					font-size: 20px;
					top: 5px;
					color: #cecece;

					transition: 0.4s ease-in-out;

					:hover {
						transform: rotate(-180deg);
						transition: 0.4s ease-in-out;
					}
				}
			}

			.active {
				svg {
					transform: rotate(-180deg);
					transition: 0.4s ease-in-out;
				}
			}

			@media (max-width: 640px) {
				display: none;
			}
		}
	}

	.UserLogout {
		padding: 0 10px;
		font-size: 20px;
	}

	.btnSection {
		padding: 10px;
		position: relative;

		button {
			position: relative;
			width: 32px;
			height: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 0;
			border-radius: 50%;
			background: transparent;
			transition: 0.2s ease-in-out;
			transform: scale(1.1);
			cursor: pointer;

			svg {
				font-size: 20px;
				color: #cecece;
				transition: 0.2s ease-in-out;
			}

			:hover {
				${(props) =>
					props.secundary_color &&
					css`
						background: ${props.secundary_color};
					`}
				color: #fff;
				transition: 0.2s ease-in-out;
				transform: scale(1.2);

				svg {
					color: #fff;
				}
			}
		}

		@media (max-width: 556px) {
			display: none;
		}

		:last-child {
			padding-right: 0px;
		}
	}
`;

export const Notification = styled.button<INotificationProps>`
	.statusInfo {
		content: "";
		position: absolute;
		width: 10px;
		height: 10px;
		top: 5px;
		right: 5px;
		background: #09c2de;
		border: 2px solid #fff;
		border-radius: 50%;

		${(props) =>
			!props.hasNewNotification
				? css`
						display: none;
				  `
				: css`
						display: block;
				  `}
	}
`;

export const ModalMenu = styled.div<IProps>`
	background: #f5f6fa;
	position: absolute;
	top: 40px;
	right: 0px;
	width: fit-content;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 3px 12px 1px rgba(44, 55, 130, 0.15);
	${(props) => css`
		border-top: 3px solid ${props.secundary_color};
		border-bottom: 3px solid ${props.secundary_color};
	`}
	color: #003967;
	animation: animateBlur 0.2s ease-in-out;
	z-index: 9;

	@media (max-width: 415px) {
		top: 25px;
		right: 0;
	}

	@keyframes animateBlur {
		from {
			filter: blur(3px);
			opacity: 0;
		}
		to {
			filter: blur(1px);
			opacity: 1;
		}
	}

	.btnMenu {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		border: 0px;
		z-index: -10;
	}
`;

export const UserProfile = styled.div<IProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: #e5e9f2 1px solid;
	padding: 18px;
	z-index: 15;

	.userPhoto {
		${(props) =>
			props.secundary_color &&
			css`
				background: ${props.secundary_color};
			`}
		background-size: cover;
		/* filter: blur(0.5px); */
		width: 42px;
		height: 42px;
		border: 1px solid #fff;
		border-radius: 50%;
		color: #fff;
		font-weight: bolder;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

		svg {
			position: relative;
			top: 47%;
			left: 48.5%;
			transform: translate(-47%, -48.5%) scale(1.4);
			filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
		}
	}

	.userName {
		flex: 1;
		margin-left: 15px;
		display: flex;
		flex-direction: column;

		p:first-child {
			position: relative;
			font-size: 14px;
			font-weight: bolder;
			padding-bottom: 7px;
			text-transform: capitalize;
		}

		p:nth-child(2) {
			width: 100%;
			font-size: 12px;
			color: #8094ae;
		}
	}
`;

export const UserSetting = styled.div`
	background: #fff;
	padding: 24px;
	border-bottom: #e5e9f2 1px solid;
	color: #003967;
	font-size: 12px;

	div {
		transition: 0.2s ease-in-out;
		cursor: pointer;

		svg {
			margin-right: 7.5px;
			vertical-align: middle;
			font-size: 18px;
			transition: 0.2s ease-in-out;
		}

		:hover {
			transform: translateX(5px);
			transition: 0.2s ease-in-out;

			svg,
			a {
				color: #0089c9;
				transition: 0.2s ease-in-out;
			}
		}

		a {
			width: 100%;
			color: #003967;
			transition: 0.2s ease-in-out;
		}
	}

	div:nth-child(n + 2) {
		margin: 24px 0px;
	}

	div:last-child {
		margin: 12px 0 0;
	}
`;

export const ModalNotify = styled.div`
	background: #f5f6fa;
	position: absolute;
	top: 54px;
	left: -295px;
	width: 340px;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 3px 12px 1px rgba(44, 55, 130, 0.15);
	border-top: 3px solid #0089c9;
	border-bottom: 3px solid #0089c9;
	color: #003967;
	animation: animateBlur 0.2s ease-in-out;
	z-index: 20;

	.btnNotify {
		background: transparent;
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		border: 0px;
		z-index: -10;
		cursor: pointer;
	}

	a:hover {
		color: #0089c9;
		transition: 0.2s;
	}

	a:link {
		transition: 0.2s;
	}

	@media screen and (max-width: 375px) {
		top: 25px;
		left: -280px;
	}

	@keyframes animateBlur {
		from {
			filter: blur(3px);
			opacity: 0;
		}
		to {
			filter: blur(1px);
			opacity: 1;
		}
	}
`;

export const HeaderNotify = styled.div`
	background: #f5f6fa;
	padding: 12px 24px;
	color: #003967;
	font-size: 12px;
	display: flex;
	justify-content: space-between;
	border-bottom: #e5e9f2 1px solid;

	div:last-child {
		cursor: pointer;
		transition: 0.2s;

		:hover {
			color: #0089c9;
			transition: 0.2s;
		}
	}
`;

export const SectionNotify = styled.section`
	background: #fff;
	font-size: 14px;
	height: 200px;
	overflow-y: auto;

	.eachNotify {
		display: flex;
		justify-content: space-between;
		padding: 24px;
		border-bottom: #e5e9f2 1px solid;

		span:first-child {
			font-style: italic;
		}

		span:last-child {
			font-size: 10px;
			color: #cecece;
			text-align: right;
		}
	}
`;
export const FooterNotify = styled.div`
	background: #f5f6fa;
	padding: 12px 24px;
	color: #003967;
	font-size: 12px;
	display: flex;
	justify-content: center;
	cursor: pointer;

	div {
		width: 100%;
		text-align: center;
		transition: 0.2s;

		:hover {
			color: #0089c9;
			transition: 0.2s;
		}
	}
`;

export const UserLogout = styled.div`
	background: #f5f6fa;
	margin: 10px 24px;
	color: #003967;
	font-size: 12px;

	div {
		transition: 0.2s ease-in-out;
		cursor: pointer;

		svg {
			margin-right: 7.5px;
			vertical-align: middle;
			font-size: 18px;
		}

		:hover {
			color: #0089c9;
			transform: translateX(5px);
			transition: 0.2s ease-in-out;
		}
	}
`;
