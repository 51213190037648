import React, { useEffect, useState } from "react";
import {
	Container,
	Content,
	TransactionSection,
	StatusSection,
	TransactionValues,
	InstallmentsModule,
	InstallmentsContent,
	InstallmentsPaid,
} from "./styles";
import HeaderComponent from "../../components/Header";
import FooterComponent from "../../components/Footer";
import LoaderComponent from "../../components/Loader";
import api from "../../services/api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import formatValue from "../../utils/formatValue";
import cardBrand from "../../utils/CardBrand";
import { useToast } from "../../hooks/toast";
import { setStatusColor, setStatusTranslation } from "../../utils/setStatus";
import { useAuth } from "../../hooks/auth";
import { redirectOnlyUserSale } from "../../utils/verifyOnlySaleUser";
import { FiArrowLeftCircle } from "react-icons/fi";
import useQuery from "../../utils/useQuery";

interface ITransactionDetail {
	amountPaid: number;
	antecipated_amount: number;
	brand: string;
	card_number: string;
	cashback_taxid: "";
	date: string;
	gross_amount: number;
	id: string;
	installments: number;
	installmentsPaid: number;
	last_status_date: any;
	isSplit?: boolean
	percentageSplitApplied?: number
	percentageMdrApplied?: number
	splitType?: string
	master?: string
	participant?: string
	net_amount: number;
	nsu: string;
	serial_number: string;
	operations: [
		{
			antecipated_amount: number;
			gross_amount: number;
			installment_number: number;
			net_amount: number;
			payment_prevision_date: string;
			status: string;
			status_date: string;
		}
	];
	payment_reference: string;
	status: string;
	terminal: string;
	type_sale: string;
}

const Transaction: React.FC = () => {
	const history = useHistory();
	const { addToast } = useToast();
	const { user } = useAuth();
	const [transactionDetail, setTransactionDetail] =
		useState<ITransactionDetail>();
	const [loading, setLoading] = useState(true);


	const params = useParams<{id: string, isSplit: string}>();
	const query = useQuery()

	useEffect(() => {
		redirectOnlyUserSale(history, user);
		try {
			const isSplit = query.get('isSplit');
			const { id } = params;
			let url = `/transacoes/${id}`;

			if(isSplit) {
				url = `split/${id}/installments`
			}

			api
				.get(url)
				.then((response) => {
					if(isSplit) {
						setTransactionDetail(response.data);
					}
					else {
						setTransactionDetail(response.data.result);
						api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
					}					
					
				})
				.finally(() => setLoading(false));
		} catch (err) {
			addToast({
				type: "error",
				title: "Erro ao atualizar informações",
				description:
					"Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
			});
		}
	}, [addToast, history, user]);
	return (
		<Container>
			<HeaderComponent linkValue="extrato" />
			{!loading ? (
				<Content>
					{transactionDetail && (
						<TransactionSection>
							<p
								className="goBackBtn"
								onClick={() => {
									history.goBack()
								}}
							>
								<FiArrowLeftCircle>Voltar</FiArrowLeftCircle>Voltar
							</p>
							<div className="transactionTitle">
								<p>Detalhes da transação</p>
								<p>Venda Split: {query.get('isSplit') ? 'Sim' : 'Não'}</p>
								<p>
									Status da Transação: {' '}
									<span>
										{
											<span
												style={{
													background: `${setStatusColor(
														transactionDetail.status
													)}`,
												}}
											>
												{setStatusTranslation(transactionDetail.status)}
											</span>
										}
									</span>
								</p>
							</div>

							<TransactionValues>
								<ul>
									<li>
										<label>NSU</label>
										<span>{transactionDetail?.nsu}</span>
									</li>
									<li>
										<label>Valor Bruto</label>
										<span>{formatValue(transactionDetail?.gross_amount)}</span>
									</li>
									<li>
										<label>Valor Líquido</label>
										<span>{formatValue(transactionDetail?.net_amount)}</span>
									</li>
									<li>
										<label>Valor Antecipado</label>
										<span>
											{formatValue(transactionDetail?.antecipated_amount)}
										</span>
									</li>
								</ul>
								<ul>
									
									<li>
										<label>Realizada em</label>
										<span>
											{moment(transactionDetail?.date).format(
												"DD/MM/YYYY HH:mm"
											)}
										</span>
									</li>
									<li>
										<label>Número do Cartão</label>
										<span>{transactionDetail?.card_number}</span>
									</li>
									<li>
										<label>Número de Série</label>
										<span>
											{ transactionDetail?.terminal || '---' }
										</span>
									</li>
									<li>
										<label>Origem</label>
										<span>
											<img
												src={cardBrand(transactionDetail?.brand)}
												alt="Bandeira cartão"
											/>
										</span>
									</li>
									<li>
										<label>Tipo</label>
										<span>
											{setStatusTranslation(transactionDetail?.type_sale)}{" "}
											{transactionDetail.installments}x
										</span>
									</li>
									<li>
										<label>Valor Recebido</label>
										<span>{formatValue(transactionDetail.amountPaid)}</span>
									</li>
								</ul>
								{
									query.get('isSplit') && (
										<ul>
											<li>
												<label>Master</label>
												<span>
													{transactionDetail.master}
												</span>
											</li>
											<li>
												<label>Participante</label>
												<span>
													{transactionDetail.participant}
												</span>
											</li>
											<li>
												<label>Tipo de Split</label>
												<span>
													{transactionDetail.splitType}
												</span>
											</li>
											<li>
												<label>Porcentagem Split</label>
												<span>
													{transactionDetail.percentageSplitApplied?.toFixed(2)} %
												</span>
											</li>
											<li>
												<label>Porcentagem MDR</label>
												<span>
													{transactionDetail.percentageMdrApplied?.toFixed(2)} %
												</span>
											</li>
										</ul>
									)
								}
							</TransactionValues>
						</TransactionSection>
					)}
					{transactionDetail && (
						<StatusSection>
							<div className="titleStatus">
								<p>Status do Recebimento</p>
								{transactionDetail?.installmentsPaid}/
								{transactionDetail?.installments}
							</div>

							<InstallmentsPaid
								cancelled={
									transactionDetail?.status === "refunded" ? true : false
								}
								paid={
									(transactionDetail?.installmentsPaid /
										transactionDetail?.installmentsPaid) *
									100
								}
							/>
						</StatusSection>
					)}
					<InstallmentsModule>
						<div className="titleStatus">
							<p>Parcelas</p>
						</div>

						<InstallmentsContent
							cancelled={
								transactionDetail?.status === "refunded" ? true : false
							}
						>
							<table>
								<tbody>
									<tr>
										<th>Nº DA PARCELA</th>
										<th>DATA RECEBIMENTO</th>
										<th>VALOR BRUTO</th>
										<th>VALOR LÍQUIDO</th>
										<th>VALOR ANTECIPADO</th>
										<th>STATUS</th>
									</tr>
									{transactionDetail &&
										transactionDetail.operations.map((value, key) => (
											<tr key={key}>
												<td>{value.installment_number}</td>
												<td>
													{value.status_date
														? moment(value.status_date).format("DD/MM/YYYY")
														: moment(value.payment_prevision_date).format(
																"DD/MM/YYYY"
														  )}
												</td>
												<td>{formatValue(value.gross_amount)}</td>
												<td>{formatValue(value.net_amount)}</td>
												<td>{formatValue(value.antecipated_amount)}</td>
												<td>{setStatusTranslation(value.status)}</td>
											</tr>
										))}
								</tbody>
							</table>
						</InstallmentsContent>
					</InstallmentsModule>
				</Content>
			) : (
				<LoaderComponent />
			)}
			<FooterComponent />
		</Container>
	);
};

export default Transaction;
