import styled,{css} from "styled-components";

interface IProps{
    primary_color?: string;
    name?: string;
    width?: number;
    onlySale?:boolean
}

export const Footer = styled.footer<IProps>`
     ${(props) =>
    props.primary_color &&
    css`
       background: ${props.primary_color};
    `}
    padding: 10px 44px;
    font-family: 'Roboto';
    color: #fff;

    @media (max-width: 500px) {
        padding: 10px 24px;
    }
`;

export const FooterContent = styled.footer<IProps>`
    display: flex;
    max-width: 1185px;
    margin: auto;
    justify-content: center;
     ${(props) =>
        props.onlySale ?
        css`
        justify-content: center;
        ` :
        css`
        justify-content: space-between;
        `
    } 
    @media screen and (max-width: 935px) {
        flex-direction: column;
    }
    .componentHelp {
        padding: 0px;
        margin: 0px;
        
        h2 {
            font-size: 14px;
            font-weight: bolder;
            margin: 10px 0px;
        }
        span {
            font-size: 12px;
        }
        @media screen and (max-width: 935px) {
            margin: 25px auto;
        }
    }
    .componentContact {
        text-align: center;
        margin: auto 0;
        img {
            ${(props) =>
            props.name?.toLowerCase() === 'aqpago'?
            css`
            width: 100px !important;
            height:auto;
            `
            :
            css`
            width: ${props.width}
            height: auto;
            `
            }
       
        }
        h2 {
            font-size: 14px;
            font-weight: 700;
            margin: 10px 0px;
        }
        span {
            font-size: 12px;
        }
        
        @media screen and (max-width: 935px) {
            justify-items: center;
        }
    }
`;