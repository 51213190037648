/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  Content,
  LinkDetailsContent,
  LinkTransactionsContent,
  TransactionsList,
  InstallmentsPaid,
} from "./styles";
import HeaderComponent from "../../../components/Header";
import FooterComponent from "../../../components/Footer";
import { useHistory, useParams } from "react-router-dom";
import { useToast } from "../../../hooks/toast";
import { AiOutlineDownload, AiOutlineFileSearch } from "react-icons/ai";
import { BiFirstPage, BiLastPage, BiUnlink } from "react-icons/bi";
import { FiArrowLeftCircle, FiCopy } from "react-icons/fi";
import Button from "../../../components/Button";
import DisableLink from "../../../components/DisableLink";
import moment from "moment";
import formatValue from "../../../utils/formatValue";
import api from "../../../services/api";
import LoaderComponent from "../../../components/Loader";
import { setStatusColor, setStatusTranslation } from "../../../utils/setStatus";
import cardBrand from "../../../utils/CardBrand";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import ICompany from "../../../@types/company";
import generateTheme from "../../../utils/generateTheme";
import LoaderContent from "../../../components/LoaderContents";
import { RiWhatsappLine } from "react-icons/ri";
import downloadFile from "../../../utils/downloadCSVFile";

interface ILinkPay {
  status: string;
  data_criacao: string;
  nome: string;
  cpf_cnpj: string;
  valor: number;
  id: string;
  formaPagamento: string;
}

interface ILinkData {
  status: string;
  link: string;
  id: string;
  clientMerchantID: string;
  code: string;
  createdAt: string;
  description: string;
  expirationAt: string;
  fee: number;
  installments: number;
  isActive: boolean;
  customerName: string;
  transactionList: null;
  amount: number;
  isPaid: boolean;
}

interface ITransactionsList {
  paymentReference: string;
  capture_method: string;
  status: string;
  cashbackAmount: number;
  installments: number;
  installments_paid: number;
  netAmount: number;
  AntecipatednetAmount: number;
  type_sale: number;
  serial: any;
  date: string;
  grossAmount: number;
  id: string;
  mdrAmount: number;
  PaidAmount: number;
  brand: string;
  aproval: number;
  card_number: string;
}

const DetailsLinks: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const linkRef = useRef<HTMLInputElement>(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDeactivation, setLoadingDeactivation] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [linkTransactions, setLinkTransactions] = useState<ITransactionsList[]>(
    []
  );
  const [linkDetail, setLinkDetail] = useState<ILinkData>();
  const [linkIsActive, setLinkIsActive] = useState(false);
  const guid: any = useParams();
  const [link, setLink] = useState<ICompany>();
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setLink(generateTheme);
    try {
      setLoading(true);
      api
        .get(`/pagamentos/links/${guid.id}`)
        .then((response) => {
          setLinkDetail(response.data);
          setLinkIsActive(response.data.isActive);
        })
        .finally(() => setLoading(false));

      setTableLoading(true);
      api
        .get("/vendas", {
          params: {
            perpage: 10,
            page: 1,
            linkId: guid.id,
            orderdirection: "desc",
            orderby: "date",
            dashboard: false
          },
        })
        .then((response) => {
          if (response.data.result.items.length > 0) {
            setPage(response.data.result.Page);
            setTotalPages(response.data.result.TotalPage);
            setPageSize(response.data.result.PageSize);
            setLinkTransactions(response.data.result.items);
          }
        })
        .finally(() => {
          setTableLoading(false);
        });
    } catch (err) {
      addToast({
        title: "Erro ao carregar Link",
        description:
          "Ocorreu um erro ao carregar o link solicitado, tente novamente mais tarde.",
        type: "error",
      });
    }
  }, [addToast, guid]);

  const handleLinkInactivation = useCallback(async () => {
    try {
      setLoadingDeactivation(true);
      await api
        .put(`/pagamentos/links/${guid.id}/inativacao`, {})
        .then((response) => {
          if (response.status === 200) setLinkIsActive(false);
        });
      setLoadingDeactivation(false);
    } catch (err) {
      addToast({
        title: "Erro ao inativar link.",
        description:
          "Ocorreu um erro ao inativar o link, tente novamente mais tarde",
        type: "error",
      });
    }
  }, [guid, addToast]);

  const toggleDeactivateModal = useCallback(() => {
    if (document.documentElement.style.overflowY === "hidden")
      document.documentElement.style.overflowY = "scroll";
    else document.documentElement.style.overflowY = "hidden";
    setModalOpen(!modalOpen);
  }, [modalOpen, setModalOpen]);

  const copyToClipboard = useCallback(
    (e: any) => {
      try {
        if (linkRef.current) linkRef.current.select();
        document.execCommand("copy");
        addToast({
          title: "Link copiado!",
          type: "info",
        });
      } catch (err) {
        console.log(err);
      }
    },
    [addToast]
  );

  const urlHandler = useCallback(
    (id: any) => {
      history.push({
        pathname: `/detalhes-transacao/${id}`,
        state: id,
      });
    },
    [history]
  );

  const handlePageChange = useCallback(
    (value) => {
      if (value <= 0 || value > totalPages) return;
      setPage(value);
      try {
        setTableLoading(true);
        api
          .get("/vendas", {
            params: {
              perpage: pageSize,
              orderby: "date",
              orderdirection: "desc",
              page: value,
              linkId: guid.id,
              dashboard: false
            },
          })
          .then((response) => {
            if (response.data.result.items.length > 0) {
              setPage(response.data.result.Page);
              setTotalPages(response.data.result.TotalPage);
              setPageSize(response.data.result.PageSize);
              setLinkTransactions(response.data.result.items);
              api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
            }
          })
          .finally(() => setTableLoading(false));
      } catch (err) {
        addToast({
          type: "error",
          title: "Erro ao atualizar informações",
          description:
            "Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
        });
      }
    },
    [pageSize, addToast, guid.id, totalPages]
  );

  const getTransactionData = async () => {
    try {
      setLoadingExcel(true)
      await api.get('', {
        responseType: 'blob',
        params: {
          linkId: guid.id,
          page: 1,
          download: 1,
          typefile: "excel",
        }
      }).then(response => {
        downloadFile(`transacoes${linkDetail?.code}.xlsx`, response)
      }).finally(() => { setLoadingExcel(false) })
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao baixar informações',
        description:
          'Ocorreu um erro ao baixar a planilha, tente novamente mais tarde.',
      });
    }
  }

  return (
    <Container>
      <DisableLink
        linkInactivation={handleLinkInactivation}
        setIsOpen={toggleDeactivateModal}
        isOpen={modalOpen}
      />
      <HeaderComponent linkValue="links" />
      {!loading ? (
        <Content>
          {linkDetail ? (
            <LinkDetailsContent>
              <div className="goBackBtn">
                <p
                  onClick={() => {
                    history.push("/links");
                  }}
                >
                  <FiArrowLeftCircle />
                  Voltar
                </p>
              </div>

              <div className="linkHeader">
                <section>
                  <h2>Link de pagamento - {linkDetail?.code}</h2>
                  <span className="createdAt">
                    {moment(linkDetail?.createdAt).format(
                      "[Criado em] DD/MM/YYYY [às] HH:mm"
                    )}
                  </span>
                  {linkIsActive ? (
                    <div className="linkStatus">
                      <span style={{ background: "#1ee0ac" }} /> Ativo
                    </div>
                  ) : (
                    <div className="linkStatus">
                      <span style={{ background: "#e85347" }} /> Inativo
                    </div>
                  )}
                </section>
                {linkIsActive ? (
                  <Button
                    loading={loadingDeactivation}
                    onClick={toggleDeactivateModal}
                  >
                    Desativar link <BiUnlink />
                  </Button>
                ) : (
                  <Button
                    className="inactive"
                    style={{ cursor: "not-allowed" }}
                  >
                    Inativo
                  </Button>
                )}
              </div>

              <h4>Valor total</h4>
              <p>{formatValue(linkDetail.amount)}</p>

              <h4>Url do link</h4>
              <div id="linkArea" onClick={copyToClipboard} title="Copiar">
                  <FiCopy style={{ color: "#0087e5" }} />
                  <input ref={linkRef} value={linkDetail?.link.toString()} />

              </div>
              <p id="whatsAppLink"
                onClick={() =>
                  window.open(
                    `https://wa.me/?text=${linkDetail?.link.toString()}`,
                    "_blank"
                  )
                }
              >
                Enviar link via Whats App
                    <RiWhatsappLine style={{ color: "#1ebea5" }} />
              </p>

              {
                !!linkDetail?.customerName &&

                <>
                     <h4>Pagador</h4>
                  <p>{linkDetail?.customerName}</p>
                </>
              }
              <h4>Expiração do link</h4>
              {linkDetail?.expirationAt ? (
                <p>
                  {moment(linkDetail?.expirationAt).format(
                    "[Expira em] DD/MM/YYYY"
                  )}
                </p>
              ) : (
                <p>Link sem data de expiração</p>
              )}

              {
                !!linkDetail?.description &&

                <>
                  <h4>Descrição do link</h4>
                  <p>{linkDetail?.description}</p>
                </>
              }

              <hr />

              <h2>Detalhes de pagamento</h2>

              <h4>Meios de pagamento aceitos</h4>
              <p>Cartão de crédito</p>

              <h4>Tipo</h4>
              {linkDetail?.installments > 1 ? (
                <>
                  <p>Parcelado {linkDetail.installments}x</p>

                  <h4>Valor parcelado</h4>
                  <p>
                    {linkDetail.installments}x{" "}
                    {formatValue(linkDetail.amount / linkDetail.installments)}
                  </p>
                </>
              ) : (
                <p>À vista</p>
              )}
            </LinkDetailsContent>
          ) : (
            <LinkDetailsContent>
              <p>Detalhes do link não encontrado</p>
            </LinkDetailsContent>
          )}

          {linkDetail ? (
            <LinkTransactionsContent primary_color={link?.style.primary_color}>
              <div className="exportExcel">
                <h3>Transações</h3>
                <Button
                  className="btnExcel"
                  loading={loadingExcel}
                  onClick={() => { getTransactionData() }}
                >
                  Exportar Excel <AiOutlineDownload />
                </Button>
              </div>
              <TransactionsList>
                {!tableLoading &&
                  linkTransactions &&
                  linkTransactions.length > 0 ? (
                  <table>
                    <tbody>
                      <tr>
                        <th>Id</th>
                        <th>Status</th>
                        <th>Bandeira</th>
                        <th>Data de venda</th>
                        <th>Captura</th>
                        <th>Tipo</th>
                        <th>Valor bruto</th>
                        <th>Taxa cartão</th>
                        <th>Valor líquido</th>
                        <th>Valor recebido</th>
                        <th>Recebimento</th>
                      </tr>
                      {linkTransactions.map((value) => (
                        <tr key={value.id} onClick={() => urlHandler(value.id)}>
                          <td>{value.paymentReference}</td>
                          <div>
                            <span
                              style={{
                                background: `${setStatusColor(value.status)}`,
                              }}
                            />
                            <td>{setStatusTranslation(value.status)}</td>
                          </div>
                          <td>
                            <img
                              src={cardBrand(value.brand)}
                              alt={value.brand}
                            ></img>
                          </td>
                          <td>
                            {moment(value.date).format(`DD/MM/YYYY HH:mm`)}
                          </td>
                          <td>{value.capture_method}</td>
                          <td>
                            {value.type_sale} {value.installments}x
                          </td>
                          <td>{formatValue(value.grossAmount)}</td>
                          <td>{formatValue(value.mdrAmount)}</td>
                          <td>{formatValue(value.netAmount)}</td>
                          <td>
                            {value.status === "refunded"
                              ? "Cancelado"
                              : formatValue(value.PaidAmount)}
                          </td>
                          <td className="elementInstallments">
                            {value.installments_paid}/{value.installments}
                            <InstallmentsPaid
                              cancelled={
                                value.status === "refunded" ? true : false
                              }
                              paid={
                                (value.installments_paid / value.installments) *
                                100
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : tableLoading ? (
                  <LoaderContent />
                ) : (
                  <div className="noSales">
                    <p>Sem transações realizadas.</p>
                    <p>Compartilhe o link com seus clientes!</p>
                  </div>
                )}
              </TransactionsList>

              <div className="btnForPages">
                <button
                  onClick={() => {
                    if (page !== 1) handlePageChange(1);
                  }}
                  className={page === 1 ? "btnDefault" : "btnNext"}
                >
                  <BiFirstPage /> <p>Primeira Página</p>
                </button>

                <button
                  onClick={() => handlePageChange(page - 1)}
                  className={page === 1 ? "btnDefault" : "btnNext"}
                >
                  <GrFormPrevious /> <p>Anterior</p>
                </button>

                <p>
                  Página <span>{page}</span> de <span>{totalPages}</span>{" "}
                </p>

                <button
                  onClick={() => handlePageChange(page + 1)}
                  className={totalPages === page ? "btnDefault" : "btnNext"}
                >
                  <p>Próximo</p> <GrFormNext />
                </button>
                <button
                  onClick={() => {
                    if (page !== totalPages) handlePageChange(totalPages);
                  }}
                  className={totalPages === page ? "btnDefault" : "btnNext"}
                >
                  <p>Última página</p> <BiLastPage />
                </button>
              </div>
            </LinkTransactionsContent>
          ) : (
            <LinkTransactionsContent>
              <div>
                <p>
                  Não foram encontradas transações neste link de pagamentos.
                </p>
              </div>
            </LinkTransactionsContent>
          )}
        </Content>
      ) : (
        <LoaderComponent />
      )}
      <FooterComponent />
    </Container>
  );
};

export default DetailsLinks;
