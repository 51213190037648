import styled, { keyframes, css } from "styled-components";

interface IProps {
	primary_color?: string;
	secundary_color?: string;
}

const myrotate = keyframes`
    to {
        transform: rotate(360deg);
    }
`;
export const Container = styled.div<IProps>`
	button {
		cursor: pointer;
		${(props) =>
			props.primary_color &&
			css`
				background: ${props.primary_color};
			`}
		border-radius: 10px;
		height: 56px;
		border: 0;
		padding: 0 16px;
		color: white;
		width: 100%;
		font-weight: 500;
		transition: 0.2s;
		&:hover {
			${(props) =>
				props.secundary_color &&
				css`
					background: ${props.secundary_color};
				`}
		}
		#loader {
			margin-left: 10px;
			animation: ${myrotate} 2.5s infinite;

			${(props) =>
				props.secundary_color &&
				css`
					color: white;
				`}
			font-size: 15px;
			vertical-align: middle;
		}

		:disabled {
			cursor: not-allowed;
			background-color: #999;
		}
	}
`;
