import React, { useState, useEffect } from "react";
import {
	Container,
	Content,
	Info,
	Card,
	CardTitle,
	CardBody,
	Table,
	Link
} from "./styles";
import HeaderComponent from "../../../components/Header";
import FooterComponent from "../../../components/Footer";
import ProfileNav from "../../../components/ProfileNav";
import { useAuth } from "../../../hooks/auth";
import { cnpjMask } from "../../../utils/masks";
import api, { getBaseUrl } from "../../../services/api";
import cardBrand from "../../../utils/CardBrand";
import { AiFillCreditCard } from "react-icons/ai";
import { useToast } from "../../../hooks/toast";
import ICompany from "../../../@types/company";
import generateTheme from "../../../utils/generateTheme";
import { redirectOnlyUserSale } from "../../../utils/verifyOnlySaleUser";
import { useHistory } from "react-router-dom";
import downloadFile from "../../../utils/downloadCSVFile";
import moment from "moment";
import formatValue from "../../../utils/formatValue";
import { RentType } from "../../../@types/user";
import { Grid } from "../../Simulator/style";

interface IBankInfoData {
	bank_name: string;
	bank_branch_code: number;
	account_number: number;
	account_verification_code: number;
}

function BrandLogo({ type = "" }) {
	if (type.toLowerCase() === "outras") return <AiFillCreditCard fontSize="30px" />

	return <img style={{ width: '30px', verticalAlign: 'middle' }} src={cardBrand(type)} alt={type} />;
}

function RentalTable(props: { rent: RentType, primaryColor?: string }) {
	const { primaryColor, rent } = props;


	const exportRental = () => {
		api.get('estabelecimentos/aluguel', {
			params: {
				download: true
			},
			responseType: 'blob'
		}).then(x => downloadFile('aluguel.xlsx', x));
	}

	if (!rent) return null;

	if (rent.type === 'default') {
		return (
			<Card primaryColor={primaryColor}>
				<CardTitle>
					Aluguel Padrão
					<Link download onClick={exportRental}>Exportar</Link>
				</CardTitle>
				<Table>
					<thead>
						<tr>
							<th>Criado em</th>
							<th>Modelo do Terminal</th>
							<th>Cobrança Inicial</th>
							<th>Cobrança Final</th>
							<th>Valor do Aluguel</th>
							<th>Dias de Isenção</th>
						</tr>
						<tbody>
							{rent.rentals.map((value, index) => (
								<tr key={index}>
									<td>{moment(value.createdDate).format(`DD/MM/YYYY`)}</td>
									<td>{value.model}</td>
									<td>{moment(value.exemptionPeriodStartDate).format(`DD/MM/YYYY`)}</td>
									<td>{moment(value.exemptionPeriodEndDate).format(`DD/MM/YYYY`)}</td>
									<td>{formatValue(value.chargeAmount)}</td>
									<td>{value.initialExemptionDays}</td>
								</tr>
							))}
						</tbody>
					</thead>
				</Table>
			</Card>
		)
	}

	return (
		<Card primaryColor={primaryColor}>
			<CardTitle>
				Aluguel por faixa
				<Link download onClick={exportRental}>Exportar</Link>
			</CardTitle>
			<CardBody>
				<Table>
					<thead>
						<tr>
							<th>Descrição</th>
							<th>Volume Min.</th>
							<th>Volume Max.</th>
							<th>Valor do Aluguel</th>
						</tr>
					</thead>
					<tbody>
						{rent.rentals.map((value, index) => (
							<tr key={index}>
								<td>{value.description}</td>
								<td>{formatValue(value.min)}</td>
								<td>{formatValue(value.max)}</td>
								<td>{formatValue(value.rentAmount)}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</CardBody>
		</Card>
	)
}

const Profile: React.FC = () => {
	const [infoBankData, setInfoBankData] = useState<IBankInfoData>();
	// const [loading, setLoading] = useState(true);
	const history = useHistory();
	const { user } = useAuth();
	const { addToast } = useToast();
	const [link, setLink] = useState<ICompany>();

	useEffect(() => {
		redirectOnlyUserSale(history, user);
		setLink(generateTheme);

		// setLoading(true);
		try {
			api.get("/estabelecimentos/informacoes").then((response) => {
				api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
				setInfoBankData(response.data.result.bank_info);
			});
			// .then(() => setLoading(false));
		} catch (err) {
			addToast({
				type: "error",
				title: "Erro ao atualizar informações",
				description:
					"Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
			});
		}
	}, [addToast, user, history]);


	function getTerminalExport(e: React.MouseEvent<HTMLAnchorElement>) {
		e.preventDefault();

		api.get('estabelecimentos/terminais', { responseType: 'blob' })
			.then(x => {
				downloadFile(
					`terminais-${moment().format("DD-MM-YYYY")}.xlsx`,
					x
				);
			})
	}

	return (
		<Container>
			<HeaderComponent linkValue={"perfil"} />
			<Content>
				<ProfileNav />
				<Grid style={{ marginTop: '20px' }} spacing={8} container>
					<Grid col={6}>
						<Card primaryColor={link?.style.primary_color}>
							<CardTitle>Informações Cadastrais</CardTitle>
							<CardBody>
								<Info>
									<p>Codigo: <span>{user.merchant_id}</span></p>
								</Info>
								<Info>
									<p>
										Razão Social: <span>{user.name}</span>
									</p>
								</Info>
								<Info>
									<p>
										CNPJ/CPF: <span>{cnpjMask(user.tax_id)}</span>
									</p>
								</Info>
							</CardBody>
						</Card>
					</Grid>

					<Grid col={6}>
						<Card primaryColor={link?.style.primary_color}>
							<CardTitle>Informações Bancárias</CardTitle>
							<CardBody>
								<Info>
									<p>
										Banco: {
											infoBankData?.bank_name
												? <span>{infoBankData?.bank_name}</span>
												: <span>Sem Banco</span>
										}
									</p>
								</Info>
								<Info>
									<p>
										Agência: {
											infoBankData?.bank_branch_code
												? <span>{infoBankData?.bank_branch_code}</span>
												: <span>Sem Agência</span>
										}
									</p>
								</Info>
								<Info>
									<p>
										Conta: {
											infoBankData?.account_number
												? <span>{infoBankData?.account_number}-{infoBankData?.account_verification_code}</span>
												: <span>Sem Conta</span>
										} 
									</p>
								</Info>
							</CardBody>
						</Card>
					</Grid>

					<Grid col={6}>
						<Card primaryColor={link?.style.primary_color}>
							<CardTitle>Taxa de Antecipação A/M.</CardTitle>
							<CardBody>
								<Info>
									<p>
										Taxa de antecipação pontual: <span>{user.rates.antecipation_rate}%</span>
									</p>
								</Info>
								<Info>
									<p>
										Garantia: <span>{user.rates.guarantee_rate}%</span>
									</p>
								</Info>
								<Info>
									<p>
										Taxa de antecipação automática: <span>{user.rates.automatic_rate}%</span>
									</p>
								</Info>
							</CardBody>
						</Card>
					</Grid>

					<Grid col={6}>
						<Card primaryColor={link?.style.primary_color}>
							<CardTitle>Taxas</CardTitle>
							<CardBody>
								<Table>
									<thead>
										<tr>
											<th>Bandeira</th>
											<th>Tipo</th>
											<th>Cartão</th>
										</tr>
									</thead>
									<tbody>
										{user.rates.brand_rates.map((value) =>
											value.taxes.map((taxValue, index) => (
												<tr key={index}>
													<td>
														<BrandLogo type={value.name} />
													</td>
													<td>{taxValue.name}, POS</td>
													<td>{taxValue.rate}</td>
												</tr>
											))
										)}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</Grid>

					<Grid col={12}>
						<RentalTable rent={user.rent} primaryColor={link?.style.primary_color} />
					</Grid>

					<Grid col={12}>
						<Card primaryColor={link?.style.primary_color}>
							<CardTitle>
								Terminais
								<Link download onClick={getTerminalExport}>Exportar</Link>
							</CardTitle>
							<CardBody>
								<Table>
									<tbody>
										<tr>
											<th>Tipo</th>
											<th>Marca</th>
											<th>Modelo</th>
											<th>Total</th>
										</tr>
										{user.terminals.map((value, index) => (
											<tr key={index}>
												<td>{value.type}</td>
												<td>{value.manufacturer}</td>
												<td>{value.terminal_model}</td>
												<td>{value.total}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</Grid>
				</Grid>


			</Content>

			<FooterComponent />
		</Container>
	);
};

export default Profile;
