import img1 from '../assets/junte-se.png';
import img2 from '../assets/negocio.png';
import img3 from '../assets/area-especial.png';

interface ICarouselData {
  description: string;
  content: string;
  isActive: boolean;
  img?: string;
  id: number;
}
  
  export const carouselData: ICarouselData[] = [{
    description: "O portal está com um design novo!",
    content: "O portal foi reformulado para você, visando uma interface mais amigável com novas funcionalidades.",
    isActive: true,
    id: 0,
    img: img1
  },
  {
    description: " Acesse com o seu CPF ou CNPJ.",
    content: "Para facilitar seu acesso trocamos o acesso antes feito via código de estabelecimento para o CPF ou CNPJ de seu negócio, mantendo a mesma senha já cadastrada.",
    isActive: false,
    id: 1,
    img: img2

  },
  {
    description: "Nossos contatos agora estão no rodapé.",
    content: "Visando a facilidade para contatar nosso suporte, movemos os contatos da página de perfil para o rodapé de nossas páginas.",
    isActive: false,
    id: 2,
    img: img3
  },]