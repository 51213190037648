import styled from "styled-components";

export const IsEmpty = styled.section`
	display: flex;
	height: 100%;
	width: 100%;
	text-align: center;
	p {
		flex: 1;
		align-self: center;
		width: fit-content;
		font-size: 15px;
		font-weight: lighter;
		color: #526484;
		font-style: italic;
	}
`;
