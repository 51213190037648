import { Container } from "@nivo/core";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import HeaderComponent from "../../components/Header";
import { Content } from "../Dashboard/styles";
import FooterComponent from "../../components/Footer";
import SalesNonSplit from "./SalesNonSplit";
import SalesSplit from "./SalesSplit";
import { ButtonTab } from "./styles";
import useQuery from "../../utils/useQuery";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

const Sales = () => {

    const query = useQuery();

    const tab = useMemo(() => query.get('tab') || 'normal', [query.get('tab')])

    const auth = useAuth();

    const history = useHistory();

    const setTab = useCallback((selectedTab: string = '') => {
        history.push(`/vendas?tab=${selectedTab}`);
    }, [history])

    return (

        <Container>
            <HeaderComponent linkValue={"vendas"} />
            <Content>
                <nav className="split">
                    <ButtonTab active={tab === 'normal'} onClick={() => setTab('normal')}>Normal</ButtonTab>
                    <ButtonTab active={tab === 'split'}  onClick={() => setTab('split')}>Splitada</ButtonTab>
                </nav>
                <section aria-hidden={tab !== 'split'} hidden={tab !== 'split'}>
                    <SalesSplit/>                  
                </section>
                <section aria-hidden={tab !== 'normal'} hidden={tab !== 'normal'}>
                    <SalesNonSplit/>
                </section>
            </Content>
            <FooterComponent />
        </Container>

    )
}

export default Sales;