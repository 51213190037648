import styled, { keyframes, css } from "styled-components";

interface IProps {
	isActive?: boolean;
	primaryColor?: string;
	secundaryColor?: string;
}

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 100vh;
	@media (max-width: 768px) {
		justify-content: center;
		height: 100vh;
		width: 100vw;
		position: fixed;
	}
	a:visited,
	a:hover,
	a:active {
		color: inherit;
	}
	a {
		text-decoration: none;
	}
`;

export const Content = styled.div`
	display: flex;
	overflow-y: hidden;
	flex: 1;
	width: 100%;
`;

const appearFromLeft = keyframes`
to {
  opacity: 1;
  transform: translateX(0px);
}
from {
  opacity: 0;
  transform: translateX(-200px);
}
`;

export const LoginFormContent = styled.div<IProps>`
	padding: 3.2em;
	flex: 1 40em;
	justify-self: center;
	align-self: center;
	animation: ${appearFromLeft} 0.4s;
	img {
		width: 150px;
		margin-bottom: 20px;
		margin-left: -2px;
	}

	p {
		font-size: 14px;
	}

	h1 {
		text-align: left;
		font-family: Nunito;
		font-size: 20px;
		margin-bottom: 10px;
		color: #364a63;
	}

	form {
		display: flex;
		align-self: center;
		flex-direction: column;

		#eye {
			font-size: 14px;
			text-align: right;
			padding: 10px;
			padding-right: 0;

			input {
				margin-right: 15px;
				vertical-align: middle;
			}

			input[type="checkbox"] {
				position: relative;
				cursor: pointer;
			}

			input[type="checkbox"]:before {
				content: "";
				display: block;
				position: absolute;
				width: 20px;
				height: 20px;
				top: -5px;
				left: 0;
				background-color: #fff;
				border: 1px solid #dbdfea;
				border-radius: 4px;
				transition: 0.2s ease-in-out;
			}

			input[type="checkbox"]:checked:before {
				content: "";
				display: block;
				position: absolute;
				width: 20px;
				height: 20px;
				top: -5px;
				left: 0;
				border-radius: 10px;
				${(props) =>
					props &&
					css`
						background-color: ${props.primaryColor};
					`}
				transition: .2s ease-in-out;
			}

			input[type="checkbox"]:checked:after {
				content: "";
				display: block;
				width: 5px;
				height: 10px;
				border: solid white;
				border-width: 0 2px 2px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				position: absolute;
				top: -2px;
				left: 8px;
				transition: 0.2s ease-in-out;
			}
		}
	}

	a {
		align-self: flex-end;
		margin-top: 10px;
		cursor: pointer;
		font-size: 14px;
		width: fit-content;

		:hover {
			${(props) =>
				props &&
				css`
					color: ${props.secundaryColor};
				`}
		}
	}
`;

export const LoginCarroselContent = styled.div<IProps>`
	@media (max-width: 812px) {
		display: none;
	}
	flex: 1 70%;
	height: 100%;
	display: flex;
	${(props) =>
		props &&
		css`
			background-color: ${props.primaryColor};
		`}

	div {
		align-self: center;
		position: relative;
		margin: 0 auto;
	}
`;
