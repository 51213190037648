import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 37.5%;
  bottom: 0;
  padding: 30px;
  overflow: hidden;
  z-index:100000;
  
  @media screen and (max-width: 935px) {
  position: fixed;
  bottom: 50%;
  right: 0%;
  overflow: hidden;
    }
`;
