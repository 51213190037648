import React, {
  useRef,
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link } from "react-router-dom";
import {
  Container,
  Content,
  LoginFormContent,
  LoginCarroselContent,
} from "./styles";
import * as Yup from "yup";
import Input from "../../components/Input";
import api from "../../services/api";
import { handleTaxIDInput } from "../../utils/masks";
import getValidationErrors from "../../utils/getValidationErrors";
import { useToast } from "../../hooks/toast";
import Button from "../../components/Button";
import Carousel from "../../components/CarouselImg";
import { validateCPF_CNPJ } from "../../utils/CPF_CNPJValidation";
import ICompany from "../../@types/company";
import generateTheme from "../../utils/generateTheme";
import { carouselData } from "../../utils/CarouselData";

interface ILoginFormData {
  cpf_cnpj: string;
  email: string;
}

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [inputMask, setInputMask] = useState("");
  const [link, setLink] = useState<ICompany>();
  useEffect(() => {
    setLink(generateTheme);
  }, []);

  const handleSubmit = useCallback(
    async (data: ILoginFormData) => {
      try {
        let schema: any;
        let bollCPF_CNPJ = validateCPF_CNPJ(data.cpf_cnpj);
        if (data.cpf_cnpj !== "") {
          schema = Yup.object().shape({
            cpf_cnpj: Yup.string()
              .required("CPF ou CNPJ obrigatório.")
              .test("valid", "CPF/CNPJ inválido", () => bollCPF_CNPJ === true),
            email: Yup.string()
              .required("E-mail obrigatório")
              .email("E-mail inválido"),
          });
        } else {
          schema = Yup.object().shape({
            cpf_cnpj: Yup.string().required("CPF ou CNPJ obrigatório."),
            email: Yup.string()
              .required("E-mail obrigatório")
              .email("E-mail inválido"),
          });
        }
        setLoading(true);
        formRef.current?.setErrors({});
        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .post("/estabelecimentos/esqueci_minha_senha", {
            tax_id: data.cpf_cnpj,
            email: data.email,
          })
          .then((response) => {
            if (response.status === 200)
              addToast({
                title: "Código de redefinição de senha enviado!",
                type: "success",
                description:
                  "O código de redefinição de senha foi enviado para o e-mail cadastrado.",
              });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          title: "Erro no envio de código de redefinição de senha!",
          type: "error",
          description: `Ocorreu um erro ao enviar o código de acesso, cheque suas credencias ou entre em contato conosco ${link?.metadata.contact_block.contact_phone}`,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, link]
  );

  return (
    <Container>
      <Content>
        <LoginFormContent
          secundaryColor={link?.style.secundary_color}
          primaryColor={link?.style.primary_color}
        >
          <img
            className="navLogo"
            src={link?.style.url_logo_default}
            alt="logo"
          ></img>
          <h1>Esqueceu sua senha?</h1>
          <p>
            Confirme seu CPF/CNPJ e e-mail cadastrado, iremos enviar um código
            de mudança de senha em seu e-mail.
          </p>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input name="email" placeholder="Email" />
            <Input
              value={inputMask}
              name="cpf_cnpj"
              placeholder="CPF ou CNPJ"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInputMask(handleTaxIDInput(e.currentTarget.value));
              }}
            />

            <Button type="submit" loading={loading}>
              Confirmar dados
            </Button>
            <Link style={{ alignSelf: "center" }} to="/">
              Voltar para login
            </Link>
          </Form>
        </LoginFormContent>
        <LoginCarroselContent
          secundaryColor={link?.style.secundary_color}
          primaryColor={link?.style.primary_color}
        >
          <div>
            <Carousel data={carouselData} />
          </div>
        </LoginCarroselContent>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
