import styled from "styled-components";

export const SalesList = styled.div`
	@media (max-width: 1250px) {
		overflow-x: auto;
	}
	overflow-x: auto;
	table {
		width: 100%;
		font-size: 13.5px;
		@media (max-width: 568px) {
			font-size: 12px;
		}

		font-weight: lighter;
		color: #526484;
		transition: 0.2s;

		tr {
			display: flex;
			align-items: center;
			padding: 5px;
			border-bottom: 1px solid #e5e9f2;

			:hover {
				font-weight: 900;
			}

			:first-child {
				text-transform: capitalize;
			}

			:nth-child(n + 2) {
				cursor: pointer;
			}

			:last-child {
				border: 0px;
				margin-bottom: 10px;
			}

			img {
				width: 30px;
				vertical-align: middle;
			}

			td,
			th {
				width: 110px;
				text-align: center;
				padding: 5px;
				margin-right: 5px;
				font-weight: lighter;
			}

			div {
				position: relative;

				span {
					content: "";
					position: absolute;
					width: 6px;
					height: 6px;
					left: 8px;
					top: 50%;
					transform: translateY(-50%);
					border-radius: 50%;
				}
			}

			td:last-child {
				position: relative;
			}
		}

		.noSales {
			display: flex;
			justify-content: center;
			height: 100px;
			font-style: italic;

			@media (max-width: 500px) {
				justify-content: left;
			}

			:hover {
				cursor: text;
				font-weight: normal;
			}
		}
	}
`;
