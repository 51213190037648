import React, { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { Content } from "../Dashboard/styles";
import HeaderComponent from '../../components/Header';
import FooterComponent from '../../components/Footer';
import { Box, CircularProgress } from '@material-ui/core';
import api from '../../services/api';
import {
    Affiliation,
    Affiliations,
    Available,
    AffiliationTitle,
} from './style'
import formatValue, { formatPercentage } from '../../utils/formatValue';
import { Backdrop, Button, ErrorMessage, FormGroup, Grid, Input, Label, Table, TableContainer } from '../Simulator/style';
import moment from 'moment';
import { Dashboard } from '../Sales/styles';
import {
    Action,
    CaptureMethod,
    AntecipationCalculate,
    initialState,
    reducer
} from './store'
import { useAuth } from '../../hooks/auth';


const AntecipationPage: React.FC = () => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const { user } = useAuth();

    const timeRef = useRef<HTMLSpanElement>(null);
    const minutesLeftRef = useRef(30);
    const intervalRef = useRef<any>(undefined);

    const getAffiliations = (abortController?: AbortController) => {
        dispatch({ type: 'ADD_LOADING' });
        api.get('antecipacao/pegar-afiliacoes', { signal: abortController?.signal })
            .then(res => {
                dispatch({ type: 'SET_AFFILIATIONS', payload: { affiliations: res.data } })
            })
            .finally(() => {
                dispatch({ type: 'SUB_LOADING' });
            })
    }

    const handleSimulate = () => {

        const errors = validationAll({ amount: state.amount });

        dispatch({ type: 'SET_ERRORS', payload: { errors } })

        if (Object.keys(errors).length > 0) return;

        dispatch({ type: 'ADD_LOADING' });

        api.post<AntecipationCalculate>('antecipacao/calcular', {
            amount: state.amount,
            captureMethodId: state.selectedCaptureMethodId,
            startDate: state.startDate,
            finalDate: state.finalDate
        }).then(res => {
            if (res.data.totalInstallments === 0) {
                alert('Não há parcelas para poder antecipar determinado valor');
                return;
            }

            dispatch({ type: 'SET_CALCULATE', payload: { antecipationCalculate: res.data } });

            startTimer();
        }).finally(() => {
            dispatch({ type: 'SUB_LOADING' });
        })
    }

    const resetTimer = () => {
        clearInterval(intervalRef.current);
    }

    const startTimer = useCallback(() => {
        resetTimer();
        minutesLeftRef.current = 30;

        intervalRef.current = setInterval(() => {

            if (minutesLeftRef.current <= 0) {
                resetTimer();
                dispatch({ type: 'RESET_CALCULATE' });
            }

            if (timeRef.current) timeRef.current.innerText = `${minutesLeftRef.current}s restante(s)`;

            minutesLeftRef.current = minutesLeftRef.current - 1;
        }, 1000);
    }, [dispatch])

    const handleAntecipation = () => {
        dispatch({ type: 'ADD_LOADING' });
        api.patch(`antecipacao/completar/${state.antecipationCalculate.antecipationRequestId}`)
            .then(res => {
                alert('Requisição de Antecipação realizada com sucesso');
                resetTimer();
                dispatch({ type: 'RESET_CALCULATE' });
                getAffiliations();
            })
            .finally(() => {
                dispatch({ type: 'SUB_LOADING' });
            })
    }

    useEffect(() => {
        const abortController = new AbortController();
        getAffiliations(abortController);

        return () => abortController.abort();
    }, [])

    const affiliation = useMemo(() => {
        return state.affiliations.find(x => x.captureMethodId == state.selectedCaptureMethodId);
    }, [state.affiliations, state.selectedCaptureMethodId])

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const value = ev.target.value;
        const amount = parseInt((value || '0').replace(/\D/gi, '')) / 100;

        dispatch({ type: 'SET_AMOUNT', payload: { amount } })
    }

    const validationAll = ({ amount = 0 }) => {
        const errors = {} as Record<string, string>;

        if (!amount || amount <= 0) errors.amount = 'Obrigatório';
        else if (amount > (affiliation?.available || 0)) errors.amount = 'Valor muito alto';
        else if (amount < (affiliation?.minimumAmount || 0)) errors.amount = 'Valor muito baixo';

        return errors;
    }

    const handleChangeAffiliation = (captureMethodId: number) => {
        if (captureMethodId === state.selectedCaptureMethodId) return;

        resetTimer();
        dispatch({ type: 'SET_SELECTED_AFFILIATION', payload: { captureMethodId } })
    }


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <HeaderComponent linkValue={"antecipacao"} />
            <Content>
                {
                    state.isLoading > 0 &&
                    <Backdrop>
                        <CircularProgress />
                    </Backdrop>
                }
                <h3>Selecione uma afiliação</h3>
                <p style={{ marginTop: '5px' }}>
                    Receba no mesmo dia para solicitações envidas até as {user.maxLiquidationTime}, após o horario será feito em até 1 dia útil.
                </p>
                <Affiliations>
                    {
                        state.affiliations.map(x => (
                            <Affiliation onClick={() => handleChangeAffiliation(x.captureMethodId)} active={x.captureMethodId == state.selectedCaptureMethodId} key={x.captureMethodId}>
                                <AffiliationTitle>{x.plataformType}: {x.mcc}</AffiliationTitle>
                                <Available>Disponivel: {formatValue(x.available)}</Available>
                                <Available>Taxa de Antecipação: {formatPercentage(x.antecipationRate)}</Available>
                                <Available>Minímo: {formatValue(x.minimumAmount)}</Available>
                            </Affiliation>
                        ))
                    }
                </Affiliations>
                <Grid container spacing={5}>
                    <Grid col={12}>
                        <FormGroup>
                            <Label>Qual valor você deseja antecipar?</Label>
                            <Input readOnly={state.canAntecipate} error={!!state.errors.amount} value={formatValue(state.amount)} name="amount" onChange={handleChange} />
                            {state.errors.amount && <ErrorMessage>{state.errors.amount}</ErrorMessage>}
                        </FormGroup>
                    </Grid>
                    <Grid col={6}>
                        <FormGroup>
                            <Label>Data de Previsão Inicial (Opcional)</Label>
                            <Input 
                                readOnly={state.canAntecipate} 
                                value={state.startDate} 
                                type='date'
                                name="startDate" 
                                onChange={e => dispatch({ type: 'SET_START_DATE', payload: { startDate: e.target.value } })} 
                            />
                        </FormGroup>
                    </Grid>
                    <Grid col={6}>
                        <FormGroup>
                            <Label>Data de Previsão Final (Opcional)</Label>
                            <Input 
                                readOnly={state.canAntecipate} 
                                value={state.finalDate} 
                                type='date'
                                name="finalDate" 
                                onChange={e => dispatch({ type: 'SET_FINAL_DATE', payload: { finalDate: e.target.value } })} 
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Button onClick={handleSimulate} style={{ marginTop: '10px', marginRight: '5px' }}>Simular</Button>
                {state.canAntecipate && <Button onClick={handleAntecipation} style={{ marginTop: '10px' }}>Antecipar</Button>}
                {state.canAntecipate && <span style={{ color: '#F00', marginLeft: '10px' }} ref={timeRef}></span>}
                <Dashboard.Container>
                    <Dashboard.Item>
                        <Dashboard.Header>
                            <Dashboard.Title>Valor Bruto</Dashboard.Title>
                        </Dashboard.Header>
                        <Dashboard.Body>
                            <Dashboard.NetAmount>{formatValue(state.antecipationCalculate.totalGrossAmount)}</Dashboard.NetAmount>
                        </Dashboard.Body>
                    </Dashboard.Item>
                    <Dashboard.Item>
                        <Dashboard.Header>
                            <Dashboard.Title>Valor Liquido</Dashboard.Title>
                        </Dashboard.Header>
                        <Dashboard.Body>
                            <Dashboard.NetAmount>{formatValue(state.antecipationCalculate.totalNetAmount)}</Dashboard.NetAmount>
                        </Dashboard.Body>
                    </Dashboard.Item>
                    <Dashboard.Item>
                        <Dashboard.Header>
                            <Dashboard.Title>Valor Antecipado</Dashboard.Title>
                        </Dashboard.Header>
                        <Dashboard.Body>
                            <Dashboard.NetAmount>{formatValue(state.antecipationCalculate.totalAntecipatedAmount)}</Dashboard.NetAmount>
                        </Dashboard.Body>
                    </Dashboard.Item>
                    <Dashboard.Item>
                        <Dashboard.Header>
                            <Dashboard.Title>Taxa de antecipação</Dashboard.Title>
                        </Dashboard.Header>
                        <Dashboard.Body>
                            <Dashboard.NetAmount>{formatValue(state.antecipationCalculate.totalAntecipatedFee)}</Dashboard.NetAmount>
                        </Dashboard.Body>
                    </Dashboard.Item>
                    <Dashboard.Item>
                        <Dashboard.Header>
                            <Dashboard.Title>Média de previsão de pagamento</Dashboard.Title>
                        </Dashboard.Header>
                        <Dashboard.Body>
                            <Dashboard.NetAmount>{state.antecipationCalculate.averagePrevisionPaymentDate} Mês(es)</Dashboard.NetAmount>
                        </Dashboard.Body>
                    </Dashboard.Item>
                </Dashboard.Container>
                <TableContainer style={{ maxHeight: '500px', overflow: 'auto' }}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Bandeira</th>
                                <th>Parcela</th>
                                <th>Data de Transação</th>
                                <th>Data da Previsão de Pagamento</th>
                                <th>Nsu</th>
                                <th>Código de Autorização</th>
                                <th>Valor Bruto</th>
                                <th>Valor Liquido</th>
                                <th>Valor Antecipado</th>
                                <th>Taxa de Antecipação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (state.antecipationCalculate?.installments || []).length === 0 && (
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={999}>Sem parcelas para serem antecipadas</td>
                                    </tr>
                                )
                            }
                            {
                                (state.antecipationCalculate?.installments || []).map(x => (
                                    <tr key={x.installmentId}>
                                        <td>
                                            <img src={`https://cdn.bcpag.com.br/app-brand/color/${x.brand.toLowerCase()}@2x.png`} title={x.brand} alt={x.brand} />
                                        </td>
                                        <td>
                                            {x.installmentNumber} / {x.installmentQtd}
                                        </td>
                                        <td>{moment(x.transactionDate).format("DD/MM/YYYY HH:MM")}</td>
                                        <td>{moment(x.previsionPaymentDate).format("DD/MM/YYYY")}</td>
                                        <td>{x.nsu}</td>
                                        <td>{x.authorizationCode}</td>
                                        <td>{formatValue(x.grossAmount)}</td>
                                        <td>{formatValue(x.netAmount)}</td>
                                        <td>{formatValue(x.antecipatedAmount)}</td>
                                        <td>{formatValue(x.antecipatedFee)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </TableContainer>
            </Content>
            <FooterComponent />
        </Box>
    )
}

export default AntecipationPage;