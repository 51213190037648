import styled from 'styled-components';

const ButtonTab = styled.button<{ active?: boolean }>`
    background-color: ${props => props.active ? '#8094ae' : '#003967'};
    padding: 10px 20px;
    outline: 0; 
    border: 0;
    margin-right: 5px;
    margin-bottom: 10px;
    color: #fff;
    border-radius: 5px;
    font-size: 1rem;
    letter-spacing: 1px;
    transition: all .2s;
    cursor: pointer;
`;

const DashboardContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0px;
    gap: 20px;
`;

const PaginationButton = styled.button`
    border: 0;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 12px;
    color: #526484;
    background-color: #f7f8fc;
    
    &:disabled {
        cursor: auto;
        filter: opacity(.7);
    }

    &:hover:not(:disabled) {
        background-color: #003967;
        color: #fff;
    }
`;


const PaginationToolbar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: .8rem;
    margin-top: 10px;
`;

const PaginationInfo = styled.div`

`;

const DashboardItem = styled.div<{ isLoading?: boolean, percentage?: number }>`
    display: flex;
    flex-direction: column;
    flex-basis: calc(${props => props.percentage ?? 33}% - 10px);
    background-color: #FFF;
    height: 140px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
`;

const DashboardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .9rem;
`;

const DashboardItemBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
`

const DashboardItemTitle = styled.strong`

`;

const DashboardItemTotalRows = styled.div`

`;

const DashboardItemGrossAmount = styled.div.attrs({ title: 'Valor Liquido' })`

`

const DashboardItemNetAmount = styled.div.attrs({ title: 'Valor Liquido' })`
    font-size: 2rem;
`

const Dashboard = {
    Container: DashboardContainer,
    Item: DashboardItem,
    Header: DashboardHeader,
    Total: DashboardItemTotalRows,
    GrossAmount: DashboardItemGrossAmount,
    NetAmount: DashboardItemNetAmount,
    Body: DashboardItemBody,
    Title: DashboardItemTitle
}

export {
    ButtonTab,
    Dashboard,
    PaginationButton,
    PaginationToolbar,
    PaginationInfo
}