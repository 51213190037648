import styled, { css } from 'styled-components';

export const Affiliations = styled.ul`
    display: flex;
    flex-direction: row;
    gap: 10px;
    list-style: none;
    margin-top: 20px;
    margin-bottom: 30px;
`;

export const Affiliation = styled.li<{ active: boolean }>`
    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(31,43,58,0.15);
    padding: 16px 20px;
    background-color: #FFF;
    cursor: pointer;

    ${props => props.active && css`
        background-color: #003967;
        color: #fff;
    `}
`;


export const AffiliationTitle = styled.div`
    margin-bottom: 10px;
    font-size: .8rem;
    overflow: hidden;
    width: 220px;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Available = styled.div`

    font-size: .8rem;
`;