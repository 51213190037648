import styled,{css} from "styled-components";

interface ICarouselProps{
  isActive: boolean;
  secundary_color?: string;
}

export const Container = styled.div`
  display: flex;
  background-color:transparent;
  width: fit-content;
  height:500px;
  `

export const Content = styled.div`
    flex:1;
    flex-wrap:wrap;
`

export const CarouselContent = styled.div<ICarouselProps>`
    display:none;
    color:white;


  ${(props) =>
    props.isActive &&
    css`
      display: flex;
      width:100%;
      flex-direction:column;
    `}
    h1{
      font-size: 18px;
    }

    p,h1{
      text-align:center;
        color:white;
        width:80%;
        align-self:center;
    }

    img{
      align-self:center;
        ${(props) =>
    props.isActive &&
    css`
      display: block;
    `}
      width:350px;
    }
 `
    
  export const Slider = styled.div`
    display: flex;
    flex:1;
    width:100%;
    margin-top:10px;
    justify-content:center;

  li {
      list-style: none;
      padding: 0 4px;
      :last-child {
        padding-right: 0px;
    }
  }
  `

export const Button = styled.button<ICarouselProps>`
 
    content: '';
    width: 8px;
    height: 8px;
    border: 0px;
    border-radius: 50%;
 
    cursor: pointer;

  ${(props) =>
    props &&
    css`
      background: ${props.secundary_color};
    `}

     ${(props) =>
    props.isActive &&
    css`
       background: white;
    `}

    :last-child {
      padding-right: 0px;
    }
`