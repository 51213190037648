import React, {
	InputHTMLAttributes,
	useEffect,
	useState,
	useRef,
	useCallback,
} from "react";
import { IconBaseProps } from "react-icons";
import { FiAlertCircle } from "react-icons/fi";
import { useField } from "@unform/core";
import { Container, Error } from "./styles";
import ICompany from "../../@types/company";
import generateTheme from "../../utils/generateTheme";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string;
	containerStyle?: object;
	icon?: React.ComponentType<IconBaseProps>;
	img?: React.ImgHTMLAttributes<HTMLImageElement>;
	urlImg?: string;
	hasImg?: boolean;
}

const Input: React.FC<InputProps> = ({
	name,
	containerStyle,
	icon: Icon,
	img,
	urlImg,
	hasImg,
	...rest
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [isFocused, setIsFocused] = useState(false);
	const [link, setLink] = useState<ICompany>();

	const [isFilled, setIsFilled] = useState(false);
	const { fieldName, defaultValue, error, registerField, clearError } =
		useField(name);

	const handleInputBlur = useCallback(() => {
		setIsFocused(false);
		setIsFilled(!!inputRef.current?.value);
	}, []);

	const handleFocus = useCallback(() => {
		setIsFocused(true);
	}, []);

	useEffect(() => {
		setLink(generateTheme);
		registerField({
			name: fieldName,
			ref: inputRef.current,
			path: "value",
		});
	}, [fieldName, registerField]);

	return (
		<Container
			secundaryColor={link?.style.secundary_color}
			style={containerStyle}
			isErrored={!!error}
			isFilled={isFilled}
			isFocused={isFocused}
		>
			{!hasImg && Icon && <Icon size={20} />}
			{hasImg && urlImg && <img src={urlImg} alt="" />}

			<input
				onFocus={() => {
					clearError();
					handleFocus();
				}}
				defaultValue={defaultValue}
				onBlur={handleInputBlur}
				ref={inputRef}
				{...rest}
			/>
			{error && (
				<Error isErrored={!!error} title={error}>
					<FiAlertCircle color="#c53030" size={20} />
				</Error>
			)}
		</Container>
	);
};
export default Input;
