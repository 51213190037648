import { JSEncrypt } from "./jsencrypt";

export function generateEncryption() {
  
  var key = '-----BEGIN PUBLIC KEY-----\n' +
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCyJbfEn6etRwy4AwpcwFtZKWro\n' +
  '0VV4x1f0CuS+RE8ccjswAbl4DtpSPS27E+KaVi+gB23UxXUN26mvkjkyy0n1LpLh\n' +
  'XXkMR/ZfzHk4hdCGsCpKeB6KvAdKXMizcxFfqtNj3YNaHEDOhDLSFbGfqCpEgUXN\n' +
  'kbPUiySOnhWO1hSJTQIDAQAB\n' +
  '-----END PUBLIC KEY-----';

  let dns = window.location.hostname;

  const jsEncrypt = new JSEncrypt();
  
  jsEncrypt.setPublicKey(key);

  return jsEncrypt.encrypt(dns);
}