import styled, { css } from 'styled-components';

type GridProps = {
    container?: boolean
    col?: number
    spacing?: number
}

type ToggleOptionProps = {
    active?: boolean
}

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 1273px;
	margin: auto;
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
	padding: 44px;
`;

export const Title = styled.h3`
    font-size: 1.17rem;
    color: #8094ae;
    text-transform: uppercase;
    margin-bottom: 20px;
`;

export const Card = styled.div`
    border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
    display: flex;
    flex-direction: column;
    background-color: #fff;
`;

export const CardTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
`;

export const CardBody = styled.div`
    flex: 1;
    padding: 20px;
`;

export const CardActions = styled.div`
    padding: 20px;
    text-align: end;
`;

export const Button = styled.button`
    background: #003967;
    border: 1px solid #c3cce1;
    color: #fff;
    border: 0px;
    padding: 15px 25px;
    border-radius: 10px;
    cursor: pointer;
`;

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

const Control = css`
    width: 100%;
    color: #8094ae;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
    padding: 10px;
    height: 40px;
    border-radius: 5px;
`;


export const Input = styled.input<{ error?: boolean }>`
    ${Control};

    ${props => props.error && css`
        border-color: red;
    `}
`;

export const Label = styled.label`
    margin-bottom: 5px;
`;

export const Select = styled.select<{ error?: boolean }>`
    ${Control};

    ${props => props.error && css`
        border-color: red;
    `}
`;

export const ErrorMessage = styled.span`
    color: red;
    font-size: .8rem;
    margin-top: 5px;
`;

export const Grid = styled.div<GridProps>`
    ${props => props.container && css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;
        --spacing: ${props.spacing || 0}px;
        margin: calc(var(--spacing) * -1);
    `};

    ${props => !props.container && css`
        flex-basis: ${(100 / 12) * (props.col || 1)}%;
        padding: var(--spacing);
    `};

    @media screen and (max-width: 800px) {
        ${props => !props.container && css`
            flex-basis: 100%;

        `};
    }
`;

export const Toggle = styled.ul`
    box-sizing: border-box;
    list-style: none;

    display: flex;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 10px;  

    width: 100%;

    max-width: 400px;
    
    white-space: nowrap;
`;

export const ToggleOption = styled.li<ToggleOptionProps>`
    ${props => props.active && css`
        background-color: #3A86FF;
        color: #fff;
    `};

    box-sizing: border-box;

    flex-basis: 50%;

    height: 50px;
    padding: 10px;
    display: flex;
    align-items:center;
    justify-content:center;
    cursor: pointer;
    
    &:first-child {
        border-radius: 10px 0px 0px 10px;
    }

    &:last-child {
        border-radius: 0px 10px 10px 0px;
    }

`;

export const TableContainer = styled.div`
    background-color: #FFF;
    white-space: nowrap;
    padding: 10px;
    border-radius: 10px;
    overflow: auto;
`

export const Table = styled.table`
    td, th {
        padding: 10px 5px;
        border-collapse: collapse;
        text-align: left;
    }
    width: 100%;
`

export const Backdrop = styled.div`
    z-index: 20000;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`