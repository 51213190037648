import React, { useState, useEffect, useMemo } from "react";
import { Container, Content, InfoContent, InfoTaxes } from "./styles";
import HeaderComponent from "../../components/Header";
import FooterComponent from "../../components/Footer";
import { useAuth } from "../../hooks/auth";

// import api from "../../services/api";
import cardBrand from "../../utils/CardBrand";
import { AiFillCreditCard } from "react-icons/ai";
import { useToast } from "../../hooks/toast";
import ICompany from "../../@types/company";
import generateTheme from "../../utils/generateTheme";
import { redirectOnlyUserSale } from "../../utils/verifyOnlySaleUser";
import { useHistory } from "react-router-dom";
import TabMenu, { TabPanel } from "../../components/TabMenu";
import { makeStyles } from "@material-ui/core";
import formatValue, { formatPercentage } from "../../utils/formatValue";

const useStyles = makeStyles(() => ({
	table: {
		display: "flex",
		width: "100%",
	},
}));

// interface IBankInfoData {
// 	bank_name: string;
// 	bank_branch_code: number;
// 	account_number: number;
// 	account_verification_code: number;
// }

const Taxes: React.FC = () => {
	const classes = useStyles();
	const { user } = useAuth();
	const [link, setLink] = useState<ICompany>();
	const [tabPanel, setTabPanel] = React.useState<number>(0);

	const plan = user.plans;

	const tabs = useMemo(() => {
		const content = [{ label: "Taxas" }];
		if (plan.hasPlan) content.push({ label: "Plano Mensal" })

		return content;
	}, [plan])


	const rates = () => {
		const rows: JSX.Element[] = []
		for (const product of plan.products) {
			const productRow = [<td scope="row">{product.name}</td>]
			for (const brand of plan.brands) {
				const rate = plan.rates
					.find(x => x.brandId == brand.brandId && x.saleTypeId == product.saleTypeId && x.installment == product.installment);

				const finalRate = rate?.finalRate || 0;

				productRow.push(<td>{finalRate ? formatPercentage(finalRate) : '-'}</td>)
			}
			rows.push(<tr>{productRow}</tr>);
		}
		return rows;
	}

	const antecipationFragment = (
		<InfoContent style={{ marginTop: 20 }}>
			<h3>Taxa de Antecipação A/M.</h3>
			<p>
				Taxa de antecipação pontual:{" "}
				<span>{user.rates.antecipation_rate}%</span>
			</p>
			<p>
				Garantia: <span>{user.rates.guarantee_rate}%</span>
			</p>
			<p>
				Taxa de antecipação automática:{" "}
				<span>{user.rates.automatic_rate}%</span>
			</p>
		</InfoContent>
	)
	return (
		<Container>
			<HeaderComponent linkValue={"taxas"} />
			<Content>
				{antecipationFragment}
				<TabMenu
					tabs={tabs}
					tabPanel={tabPanel}
					setTabPanel={setTabPanel}
				/>
				<TabPanel value={tabPanel} index={0}>
					<div className="flexInfo">
						<div className={classes.table}>
							<InfoTaxes primaryColor={link?.style.primary_color}>
								<div className="taxesTable">
									<table>
										<thead>
											<tr>
												<th>Bandeira</th>
												<th>Tipo</th>
												<th>Cartão</th>
											</tr>
										</thead>
										<tbody>											
											{user.rates.brand_rates.map((value) =>
												value.taxes.map((taxValue, index) => (
													<tr key={index}>
														{value.name.toLocaleLowerCase() === "outras" ? (
															<td>
																<AiFillCreditCard />
															</td>
														) : (
															<td>
																<img
																	src={cardBrand(value.name)}
																	alt={value.name}
																></img>
															</td>
														)}

														<td>{taxValue.name}, POS</td>
														<td>{taxValue.rate}</td>
													</tr>
												))
											)}
										</tbody>
									</table>
								</div>
							</InfoTaxes>
						</div>
					</div>
				</TabPanel>
				<TabPanel value={tabPanel} index={1}>
					<div className={classes.table}>
						<InfoTaxes primaryColor={link?.style.primary_color}>
							<div className="taxesTable">
								<table>
									<thead>
										<tr>
											<th style={{ position: 'sticky' }}>Produto</th>
											{
												plan.brands.map(x => (
													<th style={{ position: 'sticky' }} key={x.brandId}>
														{ x.name.toLowerCase() === 'outras' 
															? <AiFillCreditCard /> 
															: <img src={cardBrand(x.name)} alt={x.name}></img> 
														}														
													</th>
												))
											}
										</tr>
									</thead>
									<tbody>
										{rates()}
									</tbody>
								</table>
							</div>
						</InfoTaxes>
					</div>
				</TabPanel>
			</Content>
			<FooterComponent />
		</Container>
	);
};

export default Taxes;
