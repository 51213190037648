import styled, { css } from "styled-components";

interface IInstallmentsPaidProps {
	paid: number;
	cancelled?: boolean;
}

interface IProps {
	primary_color?: string;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: space-between;
	height: 100vh;
`;

export const Content = styled.div`
	max-width: 1273px;
	margin: auto;
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
	padding: 44px;

	@media (max-width: 500px) {
		padding: 24px;
	}

	.exportExcel {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		p {
			font-size: 14px;
			color: #8094ae;
		}

		.btnExcel {
			svg {
				margin-left: 10px;
			}

			:hover {
				background: #ccd3e5;
				color: #003967;
			}
		}
	}
`;

export const ModuleInfo = styled.div`
	margin: 20px 0;
	display: flex;
	justify-content: space-between;

	@media (max-width: 1045px) {
		flex-direction: column;
	}
`;

export const FilterSection = styled.div<IProps>`
	display: flex;
	flex-direction: column;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
	padding: 16px;

	p {
		font-size: 14px;
		color: #8094ae;
		width: 100%;
	}

	.multipleFilters {
		margin: 10px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		/* @media (max-width: 800px) {
      justify-content: flex-start;
    } */
	}

	.customDate {
		font-size: 14px;
		color: #8094ae;
		display: flex;
		width: 100%;

		@media (max-width: 500px) {
			flex-direction: column;
		}

		div {
			display: flex;
			justify-content: space-between;
			align-items: center;

			:first-child {
				margin-right: 20px;

				@media (max-width: 500px) {
					margin-bottom: 10px;
					margin-right: 0;
				}
			}
		}

		label {
			width: 50%;
		}

		input {
			width: 100%;
			color: #8094ae;
			background-color: #fff;
			border: 1px solid #ddd;
			box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
			height: 56px;
			padding: 0 5px;
			border-radius: 10px;
			text-align: center;
		}

		input[type="date"]::-webkit-calendar-picker-indicator {
			transition: 0.25s ease-in-out;
			filter: none;
			font-weight: bolder;
			outline: none;

			:hover {
				cursor: pointer;
				filter: invert(0.5) sepia(0.5) saturate(5) hue-rotate(152deg);
			}
		}
	}

	.filtersButton {
		padding: 0;
		margin: 0;
		display: flex;
		flex-basis: 100%;
		width: 100%;
		align-items: center;
		justify-items: flex-start !important;

		button {
			position: relative;
			top: -9px !important;
			left: 0;
		}

		@media (max-width: 500px) {
			align-items: flex-start;
			flex-direction: column;
		}
	}
`;
export const Filter = styled.div`
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	select {
		cursor: pointer;
		background: #fff;
		border: 0px;
		border-radius: 10px;
		padding: 5px;
		box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
		color: #8094ae;
	}

	input {
		border: 0px;
		padding: 5px;
		box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
		transition: 0.2s ease-in-out;
		outline: 0;
		background: transparent;
		flex: 1;
		border: 0;
		color: #3c4d62;
		&::placeholder {
			color: #3c4d62;
		}
		& + input {
			margin-top: 8px;
		}

		:focus {
			border-bottom: 1px solid #8094ae;
		}
	}
`;

export const SectionTransaction = styled.section<IProps>`
	width: 100%;
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);

	.titleTransactions {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		p {
			font-size: 13px;
			margin-right: 10px;
			color: #8094ae;
		}

		p:last-child {
			font-size: 11px;
			margin-right: 10px;
			color: #8094ae;
		}
	}

	.valueTransactions {
		margin-top: 20px;
		color: #526484;

		p:first-child {
			font-size: 14px;
		}

		p:last-child {
			font-size: 32px;

			svg {
				position: relative;
				top: -13px;
				font-size: 16px;
				line-height: -20px;
			}
		}
	}

	:first-child {
		${(props) => css`
			background: ${props.primary_color};
			border-bottom: 1px solid ${props.primary_color};
		`}

		p:nth-child(n) {
			color: #fff;
		}
	}

	:nth-child(2) {
		border-bottom-color: #1ee0ac;
		margin: 0 20px;

		@media (max-width: 1045px) {
			margin: 20px 0;
		}
	}

	:last-child {
		margin-right: 0px;
		border-bottom-color: #e85347;
	}
`;

export const ModuleExtract = styled.div<IProps>`
	display: flex;
	width: 100%;
	margin-right: 20px;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);

	button {
		background: #f7f8fc;
		border: 1px solid #c3cce1;
		font-size: 12px;
		color: #526484;
		border: 0px;
		padding: 5px 10px;
		border-radius: 10px;
		cursor: pointer;
		transition: 0.2s ease-in-out;
		display: flex;
		align-items: center;

		svg {
			margin: 0px;
			font-size: 15px;
		}
	}
	.btnForPages {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
		font-size: 12px;
		color: #526484;

		.btnPrevious,
		.btnNext {
			:hover {
				background: #ccd3e5;
				color: #003967;
			}
		}

		.btnDefault {
			cursor: default;
		}

		@media (max-width: 500px) {
			svg {
				padding: 0;
				margin: 0;
			}

			.btnPrevious,
			.btnNext,
			.btnDefault {
				p {
					display: none;
				}
			}
		}
	}
`;

export const SalesContent = styled.div`
	@media (max-width: 1250px) {
		overflow-x: auto;
	}
	overflow-x: auto;
	table {
		width: 100%;
		font-size: 13.5px;
		@media (max-width: 568px) {
			font-size: 12px;
		}

		font-weight: lighter;
		color: #526484;
		transition: 0.2s;

		tr {
			display: flex;
			align-items: center;
			padding: 5px;
			border-bottom: 1px solid #e5e9f2;

			:hover {
				font-weight: 900;
			}

			:first-child {
				text-transform: capitalize;
			}

			:nth-child(n + 2) {
				cursor: pointer;
			}

			:last-child {
				border: 0px;
				margin-bottom: 10px;
			}

			img {
				width: 30px;
				vertical-align: middle;
			}

			td,
			th {
				width: 100%;
				text-align: center;
				padding: 5px;
				margin-right: 5px;
				font-weight: lighter;
			}

			div {
				position: relative;

				span {
					content: "";
					position: absolute;
					width: 6px;
					height: 6px;
					left: 8px;
					top: 50%;
					transform: translateY(-50%);
					border-radius: 50%;
				}
			}

			td:last-child {
				position: relative;
			}
		}

		.noSales {
			display: flex;
			justify-content: center;
			height: 100px;
			font-style: italic;

			@media (max-width: 500px) {
				justify-content: left;
			}

			:hover {
				cursor: text;
				font-weight: normal;
			}
		}
	}
`;

export const InstallmentsPaid = styled.p<IInstallmentsPaidProps>`
	width: 100%;
	content: "";
	position: absolute;
	height: 5px;
	bottom: 0;
	left: 0;
	border: 0px;
	border-radius: 20px;

	${(props) =>
		!props.cancelled
			? css`
					background: #ddd;
			  `
			: css`
					background: #e85347;
			  `}

	:after {
		${(props) =>
			props.paid &&
			css`
				width: ${props.paid}%;
			`}

		content:'';
		position: absolute;
		height: 100%;
		top: 0px;
		left: 0px;
		border: 0px;
		border-radius: 20px;
		background: #526484;
	}
`;
