export default function buildCalendar(value:any) {
  
  const startDay = value?.locale('pt-br').clone().startOf('month').startOf('week')
  const endDay = value?.locale('pt-br').clone().endOf('month').endOf('week')
  const day = (startDay.locale('pt-br').clone().subtract(1, "day"));  
  const calendar: any = []
  
    while (day.isBefore(endDay, "day")) {
      calendar.push(
        Array(7).fill(0).map(() => day.add(1, "day").clone())
      )
    }
  return calendar
}