import { Grid, Tab, Tabs } from "@material-ui/core";
import React from "react";

interface ITabMenuProps {
	tabs: Array<{ label: string }>;
	tabPanel: number;
	setTabPanel: (index: number) => void;
}

const TabMenu = (props: ITabMenuProps) => {
	const { tabs, setTabPanel, tabPanel } = props;

	const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTabPanel(newValue);
	};
	return (
		<Tabs
			style={{
				marginBottom: 10
			}}			
			indicatorColor="primary"
			value={tabPanel}
			onChange={handleChangeTab}
			TabIndicatorProps={{
				style: {
					height: 4,
					borderTopLeftRadius: 8,
					borderTopRightRadius: 8,
					
				},
			}}
		>
			{tabs.map(({ label }) => (
				<Tab label={label} />
			))}
		</Tabs>
	);
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
	className?: string;
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, className, ...other } = props;

	return (
		<Grid
			role="tabpanel"
			hidden={value !== index}
			id={`tab-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			xs={12}
			{...other}
		>
			{value === index && <Grid className={className}>{children}</Grid>}
		</Grid>
	);
}

export default TabMenu;
