import React, { FormEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { Color } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from '../../services/api';

import ReactSignatureCanvas from 'react-signature-canvas';

import { validateCPF_CNPJ } from '../../utils/CPF_CNPJValidation';

import formatValue, { formatPercentage } from '../../utils/formatValue';
import { useAuth } from '../../hooks/auth';

import { detect } from 'detect-browser';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface Rate {
    rateId: number
    brand: string
    product: string
    rate: number
    daysToPay: string
}

interface Affiliation {
    affiliation: string
    antecipationRate: number
    automaticAntecipationRate: number
    minimumTariffAmount: number
    rates: Rate[]
}

export const SignContract: React.FC = () => {

    const [affiliations, setAffiliations] = useState<{[key: string]: Affiliation}>({});
    const [selectedPlataformType, setSelectedPlataformType] = useState('POS');
    const [isLoading, setIsLoading] = useState(true);
    const isSuccessful = useRef(false);

    const auth = useAuth();

    const [snackbar, setSnackbar] = useState({
        isOpen: false,
        title: '',
        message: '' as string | JSX.Element,
        color: 'success' as Color
    });

    const isAcceptedContractRef = useRef<HTMLInputElement>(null);
    const IsAcceptedConditionRef = useRef<HTMLInputElement>(null);

    const selectedAffiliation = useMemo(() => affiliations[selectedPlataformType] || {}, [affiliations, selectedPlataformType])

    const getAffiliations = useCallback((abort?: AbortController) => {
        api.get<{ affiliations: {[key: string]: Affiliation} }>('estabelecimentos/taxas', { signal: abort?.signal })
            .then(res => {
                
                const plataformType = Object.keys(res.data.affiliations)[0];

                setAffiliations(res.data.affiliations);
                setSelectedPlataformType(plataformType)
            })
            .finally(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        const abortController = new AbortController();

        getAffiliations(abortController);

        return () => {
            abortController.abort();
        }
    }, [])

    const handleSelectedPlataformType = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPlataformType(e.target.value);
    }, [selectedPlataformType])

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const request = {
            isAcceptedCondition: IsAcceptedConditionRef.current?.checked || false,
            isAcceptedContract: isAcceptedContractRef.current?.checked || false,
        };

        const errors: string[] = [];

        if(!request.isAcceptedCondition || !request.isAcceptedContract) {
            errors.push("Confirme o contrato e tente novamente");
        }

        if(errors.length) {
            setSnackbar({
                color: 'error',
                isOpen: true,
                message: 
                    <ul>
                        {
                            errors.map((x, i) => (
                                <li style={{ listStyle: 'none' }} key={i}>{x}</li>
                            ))
                        }
                    </ul>,
                title: 'Erro'
            })
            return;
        }

        const browser = detect();

        const formData = new FormData();        

        formData.append('browser.name', browser?.name || '');
        formData.append('browser.version', browser?.version || '');
        formData.append('browser.os', browser?.os || '');
        formData.append('isAcceptedContract', String(request.isAcceptedContract));
        formData.append('isAcceptedCondition', String(request.isAcceptedCondition));

        setIsLoading(true);

        api.post('estabelecimentos/assinar-contrato', formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
        .then(() => {          

            setSnackbar({
                color: 'success',
                title: 'Sucesso',
                message: 'Contrato Assinado com sucesso, você será redirecionado para o portal.',
                isOpen: true
            });

            isSuccessful.current = true;

            setTimeout(() => {
                const newUser = { ...auth.user, hasSignedContract: true };
                auth.updateUser(newUser);
            }, 6000)
        })
        .catch(() => {
            setSnackbar({
                color: 'error',
                title: 'Falha',
                message: 'Erro ao assinar o contrato',
                isOpen: true
            });
        })
        .finally(() => {
            setIsLoading(false);
        })
    }   

    const handleDocumentKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        if(e.key.length != 1) return;
        if(/\D/gi.test(e.key)) e.preventDefault();
    }, [])

    
    return (
        <Box marginTop={'70px'} height={'100vh'} marginBottom={'70px'} bgcolor='#FFF' sx={{ display: 'flex', flexDirection: 'column' }}>
            <AppBar position="fixed">
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" color="inherit">Contrato de afiliações</Typography>
                    <Button disabled={isLoading || isSuccessful.current} type="submit" form="frmContrato" color="inherit">Assinar Contrato</Button>                  
                </Toolbar>
            </AppBar>
            {
                !isLoading && (
                    <Box sx={{ flex: 1, padding: '20px' }}>
                        <Grid onSubmit={handleSubmit} component="form" id="frmContrato" container spacing={3}>
                            <Grid item xs={12}>
                                
                                <Paper>
                                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant='h5'>Taxas</Typography>
                                        <TextField value={selectedPlataformType} variant='outlined' size="small" style={{ width: '180px' }} onChange={handleSelectedPlataformType} select label="Afiliação">
                                            {
                                                Object.keys(affiliations).map(x => (
                                                    <MenuItem style={{ textTransform: 'capitalize' }} key={x} value={x}>{x}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Toolbar>
                                    <Toolbar>
                                        <Grid spacing={3} container>
                                            <Grid item xs={4}>
                                                <TextField label="Tarifa Minima" variant='outlined' size='small' fullWidth inputProps={{ readOnly: true }} value={formatValue(selectedAffiliation.minimumTariffAmount)} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField label="Antecipação Automática" variant='outlined' size='small' fullWidth inputProps={{ readOnly: true }} value={formatPercentage(selectedAffiliation.automaticAntecipationRate)} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField label="Antecipação Pontual" variant='outlined' size='small' fullWidth inputProps={{ readOnly: true }} value={formatPercentage(selectedAffiliation.antecipationRate)} />
                                            </Grid>
                                        </Grid>
                                    </Toolbar>
                                    <TableContainer style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Bandeira</TableCell>
                                                    <TableCell>Produto</TableCell>
                                                    <TableCell>Taxa</TableCell>
                                                    <TableCell>Dias para Pagamento</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    selectedAffiliation.rates.map(x => (
                                                        <TableRow key={x.rateId}>
                                                            <TableCell>{x.brand}</TableCell>
                                                            <TableCell>{x.product}</TableCell>
                                                            <TableCell>{formatPercentage(x.rate)}</TableCell>
                                                            <TableCell>{x.daysToPay}</TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <Link target='_blank' href="https://assets.brasilcash.com.br/docs/Contrato_Portal_Credenciamento_v1.pdf">Clique aqui</Link> para acessar o contrato de credenciamento
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <div>
                                <FormControlLabel
                                    control={<Checkbox inputRef={IsAcceptedConditionRef} />}
                                    label="Declaro que li e concordo com as condições do Termo de Credenciamento"
                                />
                                </div>


                                <FormControlLabel
                                    control={<Checkbox inputRef={isAcceptedContractRef} />}
                                    label="Declaro que li e concordo com os termos de uso e adesão ao Contrato de Credenciamento"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )
            }
            {
                isLoading && (
                    <Box width={'100%'} height={'100%'} display='flex' alignItems='center' justifyContent='center'>
                        <CircularProgress/>
                    </Box>
                )
            }
            <Snackbar 
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                
                autoHideDuration={10000} 
                onClose={() => setSnackbar(oldState => ({ ...oldState, isOpen: false}))}
                translate='yes'
                title={snackbar.title} 
                open={snackbar.isOpen} 
            >
                <Alert title={snackbar.title} style={{ width: '500px' }} severity={snackbar.color}>
                    <AlertTitle>{snackbar.title}</AlertTitle>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}