import styled, { css } from "styled-components";
import Tooltip from "../Tooltip";

interface ContainerProps {
	isFocused: boolean;
	secundaryColor?: string;
	isFilled: boolean;
	isErrored: boolean;
}

interface TooltipProps {
	isErrored?: boolean;
}

export const Container = styled.div<ContainerProps>`
	background: white;
	color: #3c4d62;
	background-color: #fff;
	border: 1px solid #dbdfea;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 56px;
	width: 100%;
	padding: 0.35rem 1rem;
	font-size: 0.8125rem;
	line-height: 1.25rem;
	margin-top: 10px;
	border-radius: 10px;
	box-shadow: 0px 0px 0px 10px, rgba(0, 0, 0, 0.1);

	& + div {
		margin-top: 8px;
	}

	${(props) =>
		props.isErrored &&
		css`
			border-color: #c53030;
		`}

	${(props) =>
		props.isFocused &&
		css`
			color: ${props.secundaryColor};
			border-color: ${props.secundaryColor};
		`}

  ${(props) =>
		props.isFilled &&
		css`
			color: ${props.secundaryColor};
		`}

  input {
		background: transparent;
		flex: 1;
		border: 0;
		color: #3c4d62;
		&::placeholder {
			color: #a3a3a3;
		}
		& + input {
			margin-top: 8px;
		}
	}
	svg {
		margin-right: 16px;
	}
`;

export const Error = styled(Tooltip)<TooltipProps>`
	height: 20px;
	margin-left: 16px;
	svg {
		margin: 0;
	}
	span {
		background: #c53030;
		color: #fff;

		&&::before {
			border-color: #c53030 transparent;
		}
	}
`;
