import { Skeleton } from "@material-ui/lab";
import { Container } from "@nivo/core";
import moment from "moment";
import React from "react";
import { IWeekPaymentAgenda } from "../../../@types/charts";
import { IsEmpty } from "../../../styles/IsEmpty";
import formatValue from "../../../utils/formatValue";
import { TableReceiveStyled, WeekTable } from "./styles";

interface ITableReceiveProps {
	weekPaymentAgenda: IWeekPaymentAgenda[];
	loading: boolean;
}

const TableReceive = (props: ITableReceiveProps) => {
	const { weekPaymentAgenda, loading } = props;

	if (loading) {
		return (
			<Container>
				<Skeleton variant="rect" height={50} animation="wave" />
				<br />
				<Skeleton variant="rect" height={50} animation="wave" />
				<br />
				<Skeleton variant="rect" height={50} animation="wave" />
				<br />
				<Skeleton variant="rect" height={50} animation="wave" />
			</Container>
		);
	}

	if (weekPaymentAgenda.length === 0) {
		return (
			<IsEmpty>
				<p>Sem dados para o período</p>
			</IsEmpty>
		);
	}

	return (
		<TableReceiveStyled>
			<div className="tableTitle">
				<span />
				<span>Recebíveis</span>
				<span>Antecipações</span>
				<span>Chargebacks</span>
				<span>Cancelamentos</span>
				<span>Outros</span>
				<span>Total</span>
			</div>
			{weekPaymentAgenda.map((value, key) => (
				<WeekTable key={key}>
					<span className={value.active ? "active" : ""}>
						{moment(value.date).format("DD") === moment().format("DD")
							? "HOJE"
							: moment(value.date).format("DD / MMM")}
						<br />
						<p>{moment(value.date).locale("pt-br").format("dddd")}</p>
					</span>
					<span className={value.active ? "active" : ""}>
						{formatValue(value.sales)}
					</span>
					<span className={value.active ? "active" : ""}>
						{formatValue(value.advance)}
					</span>
					<span className={value.active ? "active" : ""}>
						{value.chargebacks}
					</span>
					<span className={value.active ? "active" : ""}>
						{formatValue(value.refunds)}
					</span>
					<span className={value.active ? "active" : ""}>
						{formatValue(value.adjustments)}
					</span>
					<span className={value.active ? "active" : ""}>
						{formatValue(value.paid)}
						<br />
						<p>{value.status}</p>
					</span>
				</WeekTable>
			))}
		</TableReceiveStyled>
	);
};

export default TableReceive;
