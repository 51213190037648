import React, { useEffect, useState } from "react";
import moment from 'moment';
import { Footer, FooterContent } from "./styles";
import ICompany from "../../@types/company";
import generateTheme from "../../utils/generateTheme";
import { useAuth } from "../../hooks/auth";

const FooterComponent: React.FC = () => {

  const [link, setLink] = useState<ICompany>()
  const { user } = useAuth();
  useEffect(() => {
    setLink(generateTheme);
  }, [])

  return (
    <Footer primary_color={link?.style.primary_color}>
      <FooterContent name={link?.style.name} width={link?.style.logo_width} onlySale={user.only_sale}>
        {
          user && !user.only_sale &&
          <>
          <div className='componentHelp'>
          <h2>Precisa de ajuda?</h2>
              <span>{link?.metadata.contact_block.contact_phone} segunda à sexta, das {link?.metadata.contact_block.contact_start_date} às {link?.metadata.contact_block.contact_end_date}. Sábados
           , das {link?.metadata.contact_block.contact_weekend_start_date} às  {link?.metadata.contact_block.contact_weekend_end_date}.<br />Capitais e Regiões Metropolitanas e Demais Regiões<br /> {link?.metadata.email_suport}</span>
        <h2>Recebimento Antecipado de Vendas?</h2>
              <span>{link?.metadata.contact_block.contact_phone} segunda à sexta, das {link?.metadata.contact_block.antecipationt_start_date} às
           {link?.metadata.contact_block.antecipation_end_date}.
           <br />Capitais e Regiões Metropolitanas e Demais Regiões<br />{link?.metadata.contact_block.antecipation_email}</span>
          </div>
          </>
        }
        <div className='componentContact'>
          <img style={{ width: link?.style.logo_width }} src={link?.style.url_logo_white} alt="logo"></img>
          <h2>Copyright © {moment().year().toString()} {link?.metadata.white_label.name}</h2>
          <span>{link?.metadata.white_label.taxid}<br />
            {link?.metadata.white_label.addr}
          </span>
        </div>
      </FooterContent>
    </Footer>
  );
};

export default FooterComponent;
