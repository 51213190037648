import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`


* {
    font-family: Nunito, Roboto;
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    
    a {text-decoration: none ;
      text-decoration: none;
      color: black;
    }
    a:link {text-decoration: none}
    a:visited {text-decoration: none }
    a:active {text-decoration: none }

    ::-webkit-scrollbar {
      height: 7px;
      width: 7px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
      background: #ddd; 
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #ddd; 
    }
}

body {
    background: #f5f6fa;
    font-family:  Nunito, Roboto;
}
`;
