/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Container,
	Content,
	PaymentModule,
	PaymentTableContent,
	PaymentDetails,
	TotalMonthAmount,
	TotalDayAmount,
	ModuleExtract,
	SalesContent,
	CalendarContainer,
	Header,
	Current,
	Body,
	DayNames,
	Week,
	Day,
} from "./styles";

import HeaderComponent from "../../components/Header";
import FooterComponent from "../../components/Footer";
import "react-day-picker/lib/style.css";
import { AiOutlineDownload } from "react-icons/ai";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { BiFirstPage, BiLastPage } from "react-icons/bi";
import moment from "moment";
import "moment/locale/pt-br";
import api from "../../services/api";
import {
	FiChevronLeft,
	FiChevronRight,
	FiCreditCard,
	FiInfo,
	FiSearch,
} from "react-icons/fi";
import { MdFlipToFront } from "react-icons/md";
import buildCalendar from "../../components/Calendar/build";
import formatValue from "../../utils/formatValue";
import cardBrand from "../../utils/CardBrand";
import Button from "../../components/Button";
import downloadCSVFile from "../../utils/downloadCSVFile";
import removeDuplicateObject from "../../utils/removeDuplicateObject";
import { useToast } from "../../hooks/toast";
import { setStatusTranslation } from "../../utils/setStatus";
import generateCalendarStyle from "../../utils/generateCalendarDates";
import ICompany from "../../@types/company";
import generateTheme from "../../utils/generateTheme";
import { redirectOnlyUserSale } from "../../utils/verifyOnlySaleUser";
import { useAuth } from "../../hooks/auth";
import { Skeleton } from "@material-ui/lab";

interface ISummary {
	brand: string
	amount: number
	product: string
}

interface IMonthPayment {
	result: {
		calendar: [
			{
				amount: number;
				date: string;
				holliday: string;
				status: string;
				items: ISummary[];
				antecipatedAmount: number
				debtAdjustment: number
				creditAdjustment: number
			}
		];
	};
}

interface IPaymentList {
	result: {
		count: number;
		items: [
			{
				brand: string;
				date: string;
				grossAmount: number;
				id: number;
				saleId: number;
				installments: number;
				netAmount: number;
				paymentReference: number;
				status: string;
				statusDate: string;
				typeSale: string;
			}
		];
	};
}

const Payment: React.FC = () => {
	const { addToast } = useToast();
	const history = useHistory();
	const { user } = useAuth();
	const [loading, setLoading] = useState(true);
	const [loadingExcel, setLoadingExcel] = useState(false);
	const [monthPayment, setMonthPayment] = useState<IMonthPayment>();
	const [paymentDetail, setPaymentDetail] = useState<{
		antecipatedAmount: number
		amount: number
		items: ISummary[]
		debtAdjustment: number
		creditAdjustment: number
	}>({
		amount: 0,
		antecipatedAmount: 0,
		items: [],
		creditAdjustment: 0,
		debtAdjustment: 0
	});
	const [paymentList, setPaymentList] = useState<IPaymentList>();
	const [paymentDate, setPaymentDate] = useState("");
	const [calendar, setCalendar] = useState<any>([]);
	const [value, setValue] = useState(moment());
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [monthTotal, setMonthTotal] = useState(0);
	const [link, setLink] = useState<ICompany>();

	//CALENDAR FUNCTIONS
	const handleDaySelect = useCallback(
		(day) => {
			resetCalendarOptions();
			if (day.day() !== 6 && day.day() !== 0 && day.month() === value.month()) {
				setValue(day);
				setPaymentDate(day.format("YYYY-MM-DD"));
				// eslint-disable-next-line array-callback-return
				monthPayment?.result.calendar.map((value) => {
					if (
						moment(value.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")
					) {
						setPaymentDetail({
							amount: value.amount,
							items: value.items,
							antecipatedAmount: value.antecipatedAmount,
							creditAdjustment: value.creditAdjustment,
							debtAdjustment: value.debtAdjustment
						});
					}
				});
			}
		},
		[monthPayment, value]
	);

	function resetCalendarOptions() {
		setPaymentList(undefined);
		setPaymentDetail({
			amount: 0,
			antecipatedAmount: 0,
			items: [],
			creditAdjustment: 0,
			debtAdjustment: 0
		});
		setPage(1);
		setPaymentDate("");
		setTotalPages(0);
		setPageSize(10);
	}

	const previousMonth = useCallback(() => {
		resetCalendarOptions();
		setValue(value.clone().subtract(1, "month"));
		setLoading(true);
		api
			.get("/transacoes/calendario", {
				params: {
					startDate: value
						.clone()
						.subtract(1, "month")
						.startOf("month")
						.format("YYYY-MM-DD"),
					endDate: value
						.clone()
						.subtract(1, "month")
						.endOf("month")
						.format("YYYY-MM-DD"),
				},
			})
			.then((response) => {

				api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
				setMonthPayment(response.data);
			})
			.finally(() => setLoading(false));
		return value.clone().subtract(1, "month");
	}, [value]);

	const nextMonth = useCallback(() => {
		resetCalendarOptions();
		setValue(value.clone().add(1, "month"));

		setLoading(true);

		api
			.get("/transacoes/calendario", {
				params: {
					startDate: value
						.clone()
						.add(1, "month")
						.startOf("month")
						.format("YYYY-MM-DD"),
					endDate: value
						.clone()
						.add(1, "month")
						.endOf("month")
						.format("YYYY-MM-DD"),
				},
			})
			.then((response) => {
				api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
				let result = removeDuplicateObject(response.data);
				setMonthPayment(result);
			})
			.finally(() => setLoading(false));
		return value.clone().add(1, "month");
	}, [value]);

	const backToToday = useCallback(() => {
		resetCalendarOptions();
		if (value.format("MM") !== moment().format("MM")) {
			setLoading(true);
			api
				.get("/transacoes/calendario", {
					params: {
						startDate: moment().startOf("month").format("YYYY-MM-DD"),
						endDate: moment().endOf("month").format("YYYY-MM-DD"),
					},
				})
				.then((response) => {
					api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
					setMonthPayment(response.data);
				})
				.finally(() => setLoading(false));
		}
		return moment();
	}, [value]);

	function isSelected(day: any, value: any) {
		if (day.day() !== 6 && day.day() !== 0) return value.isSame(day, "day");
	}

	function isToday(day: any) {
		return day.isSame(new Date(), "day");
	}

	function isWeekendDay(day: any) {
		if (day.day() === 6 || day.day() === 0) return true;
	}

	function notThisMonth(day: any, value: any) {
		if (day.month() !== value.month()) return true;
	}

	function dayStyles(day: any, value: any) {
		if (isSelected(day, value) && paymentDate !== "") return "selected";
		if (isToday(day)) return "today";
		if (isWeekendDay(day)) return "weekend";
		if (notThisMonth(day, value)) return "notThisMonth";
		return "";
	}

	function currentYear() {
		return value.format("YYYY");
	}

	function currentMonthName() {
		return value.format("MMMM");
	}

	useEffect(() => {
		setCalendar(buildCalendar(value));
	}, [value]);

	const handleDayOperationsList = useCallback(() => {
		setLoading(true);
		try {
			api
				.get("/transacoes", {
					params: {
						startDate: value.format("YYYY-MM-DD"),
						endDate: value.format("YYYY-MM-DD"),
						perpage: pageSize,
						page: page,
					},
				})
				.then((response) => {
					api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
					setPaymentList(response.data);
					setPage(response.data.result.page);
					setTotalPages(response.data.result.totalPages);
					if (response.data.result.totalPages === 0) setPage(0);
					setPageSize(response.data.result.pageSize);
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (err) {
			addToast({
				type: "error",
				title: "Erro ao atualizar informações",
				description:
					"Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
			});
		}
	}, [addToast, value, pageSize, page]);

	const handlePageChange = useCallback(
		(pageHandler, date) => {
			if (pageHandler <= 0 || pageHandler > totalPages) return;

			setPage(pageHandler);
			setLoading(true);
			try {
				api
					.get("/transacoes", {
						params: {
							startDate: date,
							endDate: date,
							perpage: pageSize,
							page: pageHandler,
						},
					})
					.then((response) => {
						api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
						setPaymentList(response.data);
					})
					.finally(() => setLoading(false));
			} catch (err) {
				setLoading(false);
				addToast({
					type: "error",
					title: "Erro ao atualizar informações",
					description:
						"Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
				});
			}
		},
		[addToast, pageSize, totalPages]
	);

	const urlHandler = useCallback(
		(id: any) => {
			history.push({
				pathname: `/detalhes-transacao/${id}`,
				state: id,
			});
		},
		[history]
	);

	const getTransactionData = async (reference: string) => {
		let currentDate: any;
		let parameters;

		if (reference === "month") {
			currentDate = value.format("MMMM_YYYY");
			parameters = {
				startDate: value.startOf("month").format("YYYY-MM-DD"),
				perpage: pageSize,
				download: true,
				endDate: value.endOf("month").format("YYYY-MM-DD"),
			};
		} else if (reference === "day") {
			currentDate = value.format("YYYY-MM-DD");
			parameters = {
				startDate: value.format("YYYY-MM-DD"),
				endDate: value.format("YYYY-MM-DD"),
				perpage: pageSize,
				page: 1,
				download: true,
			};
		}

		try {
			setLoadingExcel(true);
			await api
				.get("/transacoes", {
					responseType: "blob",
					params: parameters,
				})
				.then((response) => {
					downloadCSVFile(`pagamentos_${currentDate}.xlsx`, response);
				})
				.finally(() => {
					setLoadingExcel(false);
				});
		} catch (err) {
			addToast({
				type: "error",
				title: "Erro ao baixar informações",
				description:
					"Ocorreu um erro ao baixar a planilha, tente novamente mais tarde.",
			});
		}
	};

	useEffect(() => {
		setLink(generateTheme);
		redirectOnlyUserSale(history, user);

		let monthValue = 0;
		if (monthPayment) {
			monthPayment?.result.calendar.map((val) => {
				if (moment(val.date).month() === value.month())
					monthValue = val.amount + monthValue;
			});
		}
		setMonthTotal(monthValue);
	}, [monthPayment, history, user, value]);

	//PAYMENT PAGE EFFECT
	useEffect(() => {
		setPaymentDate(moment().format("YYYY-MM-DD"));
		setPage(1);
		setLoading(true);
		const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
		const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

		try {
			api
				.get("/transacoes/calendario", {
					params: {
						startDate: startOfMonth,
						endDate: endOfMonth,
					},
				})
				.then((response: any) => {
					api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
					setMonthPayment(response.data);
				})
				.finally(() => setLoading(false));
		} catch (err) {
			addToast({
				type: "error",
				title: "Erro ao atualizar informações",
				description:
					"Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
			});
		}
	}, [addToast]);

	return (
		<Container>
			<HeaderComponent linkValue="pagamentos" />
			<Content>
				<PaymentModule>
					<PaymentTableContent primary_color={link?.style.primary_color}>
						<section className="calendarSection">
							<div className="buttonsCalendar">
								<p>SEUS PAGAMENTOS</p>
								<Button
									loading={loadingExcel}
									onClick={() => getTransactionData("month")}
								>
									Exportar Excel do Mês
									<AiOutlineDownload />
								</Button>
								<button
									className="btnExport"
									onClick={() => setValue(backToToday())}
								>
									Voltar para mês atual
									<MdFlipToFront />
								</button>
							</div>

							{loading ? (
								<Content
									style={{ background: "#fff", padding: 0, marginTop: 20 }}
								>
									<Header primary_color={link?.style.primary_color}>
										<FiChevronLeft onClick={() => setValue(previousMonth())} />
										<Current className="current">
											<span>
												{currentMonthName()} {currentYear()}
											</span>
										</Current>
										<FiChevronRight onClick={() => setValue(nextMonth())} />
									</Header>
									<DayNames className="day-names">
										{["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"].map(
											(d, key) => (
												<Week key={key} className="week">
													{d}
												</Week>
											)
										)}
									</DayNames>
									<Skeleton
										animation="wave"
										height="480px"
										width="100%"
										variant="rect"
									/>
								</Content>
							) : (
								<CalendarContainer>
									<Header primary_color={link?.style.primary_color}>
										<FiChevronLeft onClick={() => setValue(previousMonth())} />
										<Current className="current">
											<span>
												{currentMonthName()} {currentYear()}
											</span>
										</Current>
										<FiChevronRight onClick={() => setValue(nextMonth())} />
									</Header>
									<Body className="body">
										<DayNames className="day-names">
											{["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"].map(
												(d, key) => (
													<Week key={key} className="week">
														{d}
													</Week>
												)
											)}
										</DayNames>
										{calendar.map((week: any, key: any) => (
											<div key={key} className="calendar">
												{week.map((day: any, key: any) => (
													<Day
														key={key}
														onClick={() => handleDaySelect(day)}
														secundary_color={link?.style.secundary_color}
														name={link?.style.name}
													>
														<div className={dayStyles(day, value)}>
															<span className="calendarDay">
																{day.format("D").toString()}
															</span>
															{monthPayment &&
																monthPayment.result.calendar.map(
																	(value, index) =>
																		moment(value.date).format("YYYY-MM-DD") ===
																			day.format("YYYY-MM-DD") ? (
																			generateCalendarStyle(
																				value.status,
																				value.amount,
																				index
																			)
																		) : (
																			<></>
																		)
																)}
														</div>
													</Day>
												))}
											</div>
										))}
									</Body>
								</CalendarContainer>
							)}
						</section>
					</PaymentTableContent>
					<PaymentDetails>

						{loading ? (
							<Skeleton
								height="100px"
								width="100%"
								variant="rect"
								animation="wave"
							/>
						) : (
							<TotalMonthAmount>
								<p>Total do Mês</p>
								<p> {formatValue(monthTotal)}</p>
							</TotalMonthAmount>
						)}
						<TotalDayAmount secundary_color={link?.style.secundary_color}>
							{
								(paymentDetail.items.length == 0 && paymentDetail.antecipatedAmount  == 0) && (
									<div>
										<p>
											<FiSearch />
											Selecione um dia para exibir os detalhes do pagamento.
										</p>
									</div>
								)
							}
							{
								(paymentDetail.items.length > 0 || paymentDetail.antecipatedAmount > 0) && (
									<>
										<div>
											<p>{moment(paymentDate).format("D MMMM")}</p>
											<p>{moment(paymentDate).format("dddd")}</p>
										</div>
										<ul className="summary">
											{
												paymentDetail.items.map((item, i) => (
													<li className="summary-item" key={i}>
														<img
															className="brand"
															src={
																`https://cdn.bcpag.com.br/app-brand/color/${item.brand}@2x.png`
															}
															alt={`${item.brand} ${item.product}`}
														/>
														<span className="product">{item.product}</span>
														<strong className="amount">{formatValue(item.amount)}</strong>
													</li>
												))
											}

											{
												!!paymentDetail.antecipatedAmount && (
													<li className="summary-item">
														<span className="product">Antecipação</span>
														<strong className="amount">
															{formatValue(paymentDetail.antecipatedAmount)}
														</strong>
													</li>
												)
											}

											{
												!!paymentDetail.debtAdjustment && (
													<li className="summary-item">
														<span className="product">Ajuste a Débito</span>
														<strong className="amount">
															{formatValue(paymentDetail.debtAdjustment)}
														</strong>
													</li>
												)
											}

											{
												!!paymentDetail.creditAdjustment && (
													<li className="summary-item">
														<span className="product">Ajuste a Crédito</span>
														<strong className="amount">
															{formatValue(paymentDetail.creditAdjustment)}
														</strong>
													</li>
												)
											}


											<li className="summary-item">
												<span className="product">Saldo Final</span>
												<strong className="amount">
													{formatValue(paymentDetail.amount)}
												</strong>
											</li>

										</ul>
										<div>
											<section className="detailActionsSection">
												<button onClick={handleDayOperationsList}>
													<FiInfo /> Ver operações do dia
												</button>
												<button
													onClick={() => {
														history.push(
															`/movimentacoes?date=${value.format("YYYY-MM-DD")}`
														);
													}}
												>
													<FiCreditCard />
													Ir para movimentações do dia
												</button>
											</section>
										</div>
									</>
								)
							}
						</TotalDayAmount>
					</PaymentDetails>
				</PaymentModule>

				{paymentList && (
					<ModuleExtract>
						<div className="listHeader">
							<p>Operações Financeiras</p>
							<Button
								className={"btnExcel"}
								loading={loadingExcel}
								onClick={() => getTransactionData("day")}
							>
								<p>Exportar Excel do dia </p>
								<AiOutlineDownload />
							</Button>
						</div>
						<SalesContent>
							<table>
								<tbody>
									<tr>
										<th>Id</th>
										<th>Data de Venda</th>
										<th>Tipo</th>
										<th>Nº da Parcela</th>
										<th>Bandeira</th>
										<th>Valor Bruto</th>
										<th>Valor Líquido</th>
										<th>Último Status</th>
									</tr>

									{paymentList.result.items.length > 0 ? (
										paymentList.result.items.map((value) => (
											<tr
												key={value.id}
												style={{ cursor: "pointer" }}
												onClick={() => urlHandler(value.saleId)}
											>
												<td>{value.id}</td>
												<td>{moment(value.date).format("DD/MM/YYYY HH:mm")}</td>
												<td>{value.typeSale}</td>
												<td>{value.installments}ª</td>
												<td>
													<img src={cardBrand(value.brand)} alt={value.brand} />
												</td>
												<td>{formatValue(value.grossAmount)}</td>
												<td>{formatValue(value.netAmount)}</td>
												<td>
													{value.status}
													<br />
													{moment(value.statusDate).format("DD/MM/YYYY")}
												</td>
											</tr>
										))
									) : (
										<tr className="noReceivables">
											Sem recebíveis para o período
										</tr>
									)}
								</tbody>
							</table>
						</SalesContent>

						<div className="btnForPages">
							<button
								className={page === 1 ? "btnDefault" : "btnNext"}
								onClick={() => {
									if (page !== 1) handlePageChange(1, paymentDate);
								}}
							>
								<BiFirstPage />
								<p>Primeira Página</p>
							</button>
							<button
								className={page === 1 ? "btnDefault" : "btnNext"}
								onClick={() => handlePageChange(page - 1, paymentDate)}
							>
								<GrFormPrevious />
								<p>Anterior</p>
							</button>

							<p>
								Página <span> {page}</span> de <span>{totalPages}</span>{" "}
							</p>

							<button
								className={totalPages === page ? "btnDefault" : "btnNext"}
								onClick={() => handlePageChange(page + 1, paymentDate)}
							>
								<p>Próximo</p>
								<GrFormNext />
							</button>
							<button
								className={totalPages === page ? "btnDefault" : "btnNext"}
								onClick={() => {
									if (page !== totalPages)
										handlePageChange(totalPages, paymentDate);
								}}
							>
								<p>Última página</p>
								<BiLastPage />
							</button>
						</div>
					</ModuleExtract>
				)}
			</Content>
			<FooterComponent />
		</Container>
	);
};

export default Payment;
