export type CaptureMethod = {
    plataformType: string
    captureMethodId: number
    mcc: string
    antecipationRate: number
    available: number
    minimumAmount: number
}

export type AntecipationCalculate = {
    installments: Array<{
        saleId: number
        installmentId: number
        transactionDate: string
        previsionPaymentDate: string
        nsu: string
        authorizationCode: string
        brand: string
        grossAmount: number
        netAmount: number
        antecipatedAmount: number
        antecipatedFee: number
        installmentNumber: number
        installmentQtd: number
    }>
    averagePrevisionPaymentDate: number
    totalAntecipatedFee: number
    totalInstallments: number
    totalNetAmount: number
    totalGrossAmount: number
    totalAntecipatedAmount: number
    antecipationRequestId: number
}

export const initialState = {
    affiliations: [] as CaptureMethod[],
    selectedCaptureMethodId: 0,
    antecipationCalculate: {
        totalAntecipatedFee: 0,
        averagePrevisionPaymentDate: 0,
        installments: [],
        totalAntecipatedAmount: 0,
        totalNetAmount: 0,
        totalGrossAmount: 0,
        totalInstallments: 0,
        antecipationRequestId: 0
    } as AntecipationCalculate,
    canAntecipate: false,
    amount: 0,
    startDate: '',
    finalDate: '',
    errors: {} as Record<string, string>,
    isLoading: 0
};

export type Action = 
    { type: 'SET_AFFILIATIONS', payload: { affiliations: CaptureMethod[] } } |
    { type: 'SET_CALCULATE', payload: { antecipationCalculate: AntecipationCalculate } } |
    { type: 'RESET_CALCULATE' } |
    { type: 'SET_AMOUNT', payload: { amount: number } } | 
    { type: 'SET_START_DATE', payload: { startDate: string } } | 
    { type: 'SET_FINAL_DATE', payload: { finalDate: string } } | 
    { type: 'SET_ERRORS', payload: { errors: Record<string,string> } } |
    { type: 'SET_SELECTED_AFFILIATION', payload: { captureMethodId: number } } |
    { type: 'ADD_LOADING' } |
    { type: 'SUB_LOADING' };
    

export function reducer(state = initialState, action: Action) {

    if(action.type === 'SET_AFFILIATIONS') {
        const selectedCaptureMethodId = action.payload.affiliations[0]?.captureMethodId || 0;

        return {
            ...state,
            affiliations: action.payload.affiliations,
            selectedCaptureMethodId
        };
    }

    if(action.type === "SET_AMOUNT") {
        return {
            ...state,
            amount: action.payload.amount
        }
    }

    if(action.type === 'SET_ERRORS') {
        return {
            ...state,
            errors: action.payload.errors
        }
    }

    if(action.type === 'RESET_CALCULATE') {
        return {
            ...state,
            antecipationCalculate: {
                totalAntecipatedFee: 0,
                averagePrevisionPaymentDate: 0,
                installments: [],
                totalAntecipatedAmount: 0,
                totalNetAmount: 0,
                totalGrossAmount: 0,
                totalInstallments: 0,
                antecipationRequestId: 0
            } as AntecipationCalculate,
            canAntecipate: false,
            errors: {},
            startDate: '',
            finalDate: '',
            amount: 0
        }
    }

    if(action.type === 'SET_SELECTED_AFFILIATION') {
        return {
            ...state,
            antecipationCalculate: {
                totalAntecipatedFee: 0,
                averagePrevisionPaymentDate: 0,
                installments: [],
                totalAntecipatedAmount: 0,
                totalNetAmount: 0,
                totalGrossAmount: 0,
                totalInstallments: 0,
                antecipationRequestId: 0
            } as AntecipationCalculate,
            canAntecipate: false,
            errors: {},
            amount: 0,
            startDate: '',
            finalDate: '',
            selectedCaptureMethodId: action.payload.captureMethodId
        }
    }

    if(action.type === 'SET_CALCULATE') {
        return {
            ...state,
            antecipationCalculate: action.payload.antecipationCalculate || {},
            canAntecipate: true
        }
    }

    if(action.type === 'ADD_LOADING') {
        return {
            ...state,
            isLoading: state.isLoading + 1
        }
    }

    if(action.type === 'SUB_LOADING') {
        return {
            ...state,
            isLoading: state.isLoading - 1
        }
    }

    if(action.type === 'SET_START_DATE') {
        return {
            ...state,
            startDate: action.payload.startDate
        }
    }

    if(action.type === 'SET_FINAL_DATE') {
        return {
            ...state,
            finalDate: action.payload.finalDate
        }
    }

    return state;
}

