import { Skeleton } from "@material-ui/lab";
import { Container } from "@nivo/core";
import moment from "moment";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ISales } from "../../@types/charts";
import { IsEmpty } from "../../styles/IsEmpty";
import cardBrand from "../../utils/CardBrand";
import formatValue from "../../utils/formatValue";
import { setStatusColor, setStatusTranslation } from "../../utils/setStatus";
import { LastSalesContent } from "./styles";

interface ILastSalesContentProps {
	lastSales: Array<ISales>;
	loading: boolean;
}

const LastSalesContentData = (props: ILastSalesContentProps) => {
	const { lastSales, loading } = props;
	const history = useHistory();

	const urlHandler = useCallback(
		(id: any) => {
			history.push({
				pathname: `/detalhes-transacao/${id}`,
				state: id,
			});
		},
		[history]
	);

	if (loading) {
		return (
			<Container>
				<Skeleton animation="wave" variant="rect" width="100%" height="100px" />
			</Container>
		);
	}

	if (lastSales.length === 0) {
		return (
			<IsEmpty>
				<p>Sem vendas para o período</p>
			</IsEmpty>
		);
	}

	return (
		<LastSalesContent>
			<table>
				<tbody>
					<tr>
						<th>Horário</th>
						<th>Tipo</th>
						<th>Bandeira</th>
						<th>Parcelas</th>
						<th>Valor da Venda</th>
					</tr>

					{lastSales.map((value, index) => (
						<tr key={index} onClick={() => urlHandler(value.id)}>
							<td>{moment(value.date).format("DD/MM/YYYY HH:mm")}</td>
							<td>{value.type_sale}</td>
							<td>
								<img src={cardBrand(value.brand)} alt={value.brand} />
							</td>
							<td>{value.installments} x</td>
							<td>
								{formatValue(value.grossAmount)}
								<br />
								<p style={{ color: setStatusColor(value.status) }}>
									{setStatusTranslation(value.status)}
								</p>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</LastSalesContent>
	);
};

export default LastSalesContentData;
