import React from "react";

import { Content } from "./styles";

const LoaderContent: React.FC = () => {
	return (
		<Content>
			<p>
				<svg width="10" height="22" stroke="null">
					<g>
						<title>Brasil Cash</title>
						<path
							id="svg_12"
							d="m-0.20515,0.49007l10.48639,10.48782l-10.56248,10.53204l0,-5.11383c0,0.01195 5.46387,-0.65548 5.46387,-5.25224c0,-4.59676 -5.35734,-5.50954 -5.35734,-5.50954c0,0 0,-4.962 -0.01521,-4.97541"
							strokeWidth="1.5"
							fill="#003967"
						/>
					</g>
				</svg>
				<span>Carregando dados . . .</span>
			</p>
		</Content>
	);
};

export default LoaderContent;
