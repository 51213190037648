import React from "react";
import { Redirect, Switch } from "react-router-dom";
import Route from "./Route";
import Dashboard from "../pages/Dashboard";
import Sales from "../pages/Sales";
import OnlySales from "../pages/OnlySalesPage";
import LinkError from "../pages/Error";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import SignUp from "../pages/SignUp";
import Profile from "../pages/Profile/ProfileData";
import ProfileSecurity from "../pages/Profile/ProfilePassword";
import BankStatment from "../pages/BankStatment";
import Payments from "../pages/Payments/index";
import Transaction from "../pages/Transaction";
import LinkDetails from "../pages/Links/LinkDetails";
import Links from "../pages/Links";
import { useAuth } from "../hooks/auth";
import Taxes from "../pages/Taxes";
import Simulator from "../pages/Simulator";
import { SignContract } from "../pages/SignContract";
import AntecipationPage from "../pages/Antecipation";

const Routes: React.FC = () => {
	const { user } = useAuth();
	return (
		<Switch>
			<Route path="/" exact component={Login} />
			<Route path="/reiniciar-senha" exact component={ResetPassword} />
			<Route path="/esqueceu-senha" component={ForgotPassword} />
			<Route path="/primeiro-acesso" component={SignUp} />
			<Route path="/recebiveis" component={Payments} isPrivate />
			<Route path="/perfil" exact component={Profile} isPrivate />
			<Route path="/taxas" exact component={Taxes} isPrivate />
			<Route
				path="/perfil/seguranca"
				exact
				component={ProfileSecurity}
				isPrivate
			/>
			{/* <Route path='/perfil/notificacoes' exact component={Notifications} isPrivate /> */}
			<Route path="/movimentacoes" component={BankStatment} isPrivate />
			<Route path="/resumo" component={Dashboard} isPrivate />

			<Route path='/assinar-contrato' component={SignContract} isPrivate/>

			{user && user.show_payment_link && (
				<Route path="/links" component={Links} isPrivate />
			)}

			<Route
				path="/vendas"

				component={user && !user.only_sale ? Sales : OnlySales}
				isPrivate
			/>
			<Route
				path="/detalhes-transacao/:id"
				exact
				component={Transaction}
				isPrivate
			/>
			<Route
				path="/detalhes-link/:id"
				exact
				component={LinkDetails}
				isPrivate
			/>
			<Route
				path="/simulador"
				exact
				component={Simulator}
				isPrivate
			/>
			{
				user && user.showRequestAntecipation && (
					<Route
						path="/antecipacao"
						exact
						component={AntecipationPage}
						isPrivate
					/>
				)
			}
			<Route path="*" component={LinkError} />
		</Switch>
	);
};

export default Routes;
