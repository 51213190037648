import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Backdrop,
    Button,
    Card,
    CardActions,
    CardBody,
    CardTitle,
    FormGroup,
    Grid,
    Input,
    Label,
    Select,
    Table,
    TableContainer
} from '../../Simulator/style'
import { Dashboard, PaginationButton, PaginationInfo, PaginationToolbar } from '../styles';
import formatValue from '../../../utils/formatValue';
import { BiFirstPage, BiLastPage } from 'react-icons/bi';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import api from '../../../services/api';
import { IBrandList } from '../../../@types/sales';
import { Brand, SaleType } from '../../Simulator/types';
import { CircularProgress } from '@material-ui/core';
import downloadFile from '../../../utils/downloadCSVFile';
import { Link as RouterLink } from 'react-router-dom'

type DateFilter = {
    label: string
    type: string
    startDate: string
    endDate: string
}

type SaleStatus = {
    saleStatusId: number
    name: string
    code: string
}

const dateFilters: DateFilter[] = [
    {
        label: 'Este Mês',
        type: 'this_month',
        startDate: moment().startOf('month').format("YYYY-MM-DD"),
        endDate: moment().endOf('month').format("YYYY-MM-DD"),
    },
    {
        label: 'Hoje',
        type: 'today',
        startDate: moment().startOf('day').format("YYYY-MM-DD"),
        endDate: moment().endOf('day').format("YYYY-MM-DD"),
    },
    {
        label: 'Ontem',
        type: 'yesterday',
        startDate: moment().subtract(1, 'day').startOf('day').format("YYYY-MM-DD"),
        endDate: moment().subtract(1, 'day').endOf('day').format("YYYY-MM-DD"),
    },
    {
        label: 'Ultimos 7 dias',
        type: 'week',
        startDate: moment().subtract(7, 'day').startOf('day').format("YYYY-MM-DD"),
        endDate: moment().endOf('day').format("YYYY-MM-DD")
    },
    {
        label: 'Mês Passado',
        type: 'prev_month',
        startDate: moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD"),
        endDate: moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD")
    },
    {
        label: 'Customizado',
        type: 'custom',
        startDate: '',
        endDate: ''
    }
]

const defaultFilters = {
    label: 'Ontem',
    type: 'yesterday',
    startDate: moment().subtract(1, 'day').startOf('day').format("YYYY-MM-DD"),
    endDate: moment().subtract(1, 'day').endOf('day').format("YYYY-MM-DD"),
    nsu: '',
    startAmount: null as number | null,
    endAmount: null as number | null,
    saleTypeId: 0,
    saleStatusId: 0,
    brandId: []
}

type SaleSplitResponse = {
    items: {
        saleSplitId: number
        brand: string
        master: string
        participant: string
        nsu: string
        plataform: string
        splitType: string
        authorizationCode: string
        transactionDate: string
        saleType: {
            saleTypeId: number
            name: string
            code: string
        },
        installmentQtd: number
        cardNumber: string
        grossAmount: number
        fee: number
        netAmount: number
        paidAmount: number
        lastStatus: {
            saleTypeId: number
            name: string
            code: string
        },
        percentageSplitApplied: number,
        percentageMdrApplied: number
    }[],
    totalRows: number,
    totalPages: number,
}

type DashboardItem = {
    quantity: number,
    net_amount: number,
    gross_amount: number
}

type DashboardResponse = {
    captured: DashboardItem,
    compensated: DashboardItem,
    cancelled: DashboardItem
};

const SalesSplit: React.FC = () => {

    const [filters, setFilters] = useState({ ...defaultFilters });
    const [products, setProducts] = useState<SaleType[]>([]);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [statuses, setStatuses] = useState<SaleStatus[]>([])

    const [isLoading, setIsLoading] = useState(0);

    const [data, setData] = useState<SaleSplitResponse & { page: number }>({
        items: [],
        page: 1,
        totalPages: 20,
        totalRows: 1
    })

    const [dashboard, setDashboard] = useState<DashboardResponse>({
        captured: {
            gross_amount: 0,
            net_amount: 0,
            quantity: 0
        },
        compensated: {
            gross_amount: 0,
            net_amount: 0,
            quantity: 0
        },
        cancelled: {
            gross_amount: 0,
            net_amount: 0,
            quantity: 0
        }
    });

    const label = useMemo(() => filters.label, [data.items]);

    const handleSelectedDate = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const dateFilter = dateFilters.find(x => x.type == e.currentTarget.value)
        if (!dateFilter) return;

        setFilters(oldFilters => ({
            ...oldFilters,
            type: dateFilter.type,
            label: dateFilter.label,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate
        }))

    }, [filters.type])

    const changePage = useCallback((page: number) => {
        getAll(page);
    }, [data.page])

    const getAll = useCallback((page: number, abortController?: AbortController) => {
        setIsLoading(isLoading => isLoading + 1)

        api.get<SaleSplitResponse>('split', {
            params: {
                rowsPerPage: 10,
                page,
                ...filters
            },
            signal: abortController?.signal
        }).then(res => {
            setData({
                ...res.data,
                page
            })
        }).finally(() => {
            setIsLoading(isLoading => isLoading - 1)
        })
    }, [filters])

    const getResources = useCallback((abortController?: AbortController) => {
        setIsLoading(isLoading => isLoading + 1)

        const brandRequest = api.get<IBrandList>('vendas/bandeiras', { signal: abortController?.signal });
        const productRequest = api.get<SaleType[]>('vendas/produtos', { signal: abortController?.signal });
        const statusRequest = api.get<SaleStatus[]>('vendas/statuses', { signal: abortController?.signal });

        Promise.all([brandRequest, productRequest, statusRequest])
            .then(([brand, product, statuses]) => {
                setBrands(brand.data.result);
                setProducts(product.data);
                setStatuses(statuses.data)
            })
            .finally(() => setIsLoading(isLoading => isLoading - 1))
    }, [])

    const getDashboard = useCallback((abortController?: AbortController) => {
        setIsLoading(isLoading => isLoading + 1)

        api.get<DashboardResponse>('split/dashboard', {
            params: {
                ...filters
            },
            signal: abortController?.signal
        }).then(res => {
            setDashboard(res.data);
        }).finally(() => {
            setIsLoading(isLoading => isLoading - 1)
        })
    }, [filters])

    useEffect(() => {
        const abortController = new AbortController();

        getResources(abortController);
        getAll(1, abortController);
        getDashboard(abortController);

        return () => abortController.abort();
    }, [])

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        getAll(1);
        getDashboard();
    }

    const handleReset = (e: React.FormEvent) => {
        e.preventDefault();

        setFilters({
            ...defaultFilters
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value, name } = e.target

        setFilters(oldFilters => ({
            ...oldFilters,
            [name]: value
        }))
    }

    const handleExport = useCallback(() => {
        setIsLoading(isLoading => isLoading + 1)

        api.get<SaleSplitResponse>('split', {
            params: {
                download: true,
                ...filters
            },
            responseType: 'blob'
        }).then(res => {
            downloadFile('split.xlsx', res)
        }).finally(() => {
            setIsLoading(isLoading => isLoading - 1)
        })

    }, [filters])

    return (
        <div>
            {isLoading > 0 &&
                <Backdrop>
                    <CircularProgress />
                </Backdrop>
            }
            <form onReset={handleReset} onSubmit={handleSubmit}>
                <Card>
                    <CardBody>
                        <Grid spacing={10} container>
                            <Grid col={4}>
                                <FormGroup>
                                    <Label>Data de transação</Label>
                                    <Select multiple={false} onChange={handleSelectedDate} value={filters.type}>
                                        {
                                            dateFilters.map(dateFilter => (
                                                <option key={dateFilter.type} value={dateFilter.type}>{dateFilter.label}</option>
                                            ))
                                        }
                                    </Select>
                                </FormGroup>
                            </Grid>
                            {
                                filters.type === 'custom' && (
                                    <>
                                        <Grid col={4}>
                                            <FormGroup>
                                                <Label>Data inicial</Label>
                                                <Input name="startDate" value={filters.startDate} onChange={handleChange} type='date' />
                                            </FormGroup>
                                        </Grid>
                                        <Grid col={4}>
                                            <FormGroup>
                                                <Label>Data Final</Label>
                                                <Input name='endDate' value={filters.endDate} onChange={handleChange} type='date' />
                                            </FormGroup>
                                        </Grid>
                                    </>
                                )
                            }
                        </Grid>
                        <Grid spacing={10} container>
                            <Grid col={4}>
                                <FormGroup>
                                    <Label>Status</Label>
                                    <Select multiple={false} name='saleStatusId' value={filters.saleStatusId} onChange={handleChange}>
                                        <option value={0}>Todos</option>
                                        {
                                            statuses.map(x => (
                                                <option key={x.saleStatusId} value={x.saleStatusId}>{x.name}</option>
                                            ))
                                        }                                        
                                    </Select>
                                </FormGroup>
                            </Grid>
                            <Grid col={4}>
                                <FormGroup>
                                    <Label>Bandeira</Label>
                                    <Select multiple={false} name='brandId' value={filters.brandId} onChange={handleChange}>
                                        <option value={0}>Todos</option>
                                        {
                                            brands.map(x => (
                                                <option key={x.brand_id} value={x.brand_id}>{x.name}</option>
                                            ))
                                        }       
                                    </Select>
                                </FormGroup>
                            </Grid>
                            <Grid col={4}>
                                <FormGroup>
                                    <Label>Produto</Label>
                                    <Select multiple={false} name='saleTypeId' value={filters.saleTypeId} onChange={handleChange} >
                                        <option value={0}>Todos</option>
                                        {
                                            products.map(x => (
                                                <option key={x.saleTypeId} value={x.saleTypeId}>{x.name}</option>
                                            ))
                                        }       
                                    </Select>
                                </FormGroup>
                            </Grid>
                            <Grid col={2}>
                                <FormGroup>
                                    <Label>Valor Bruto Inicial</Label>
                                    <Input type='number' name='startAmount' value={filters.startAmount || ''} onChange={handleChange} />
                                </FormGroup>
                            </Grid>
                            <Grid col={2}>
                                <FormGroup>
                                    <Label>Valor Bruto Final</Label>
                                    <Input type='number' name='endAmount' value={filters.endAmount || ''} onChange={handleChange} />
                                </FormGroup>
                            </Grid>
                            <Grid col={4}>
                                <FormGroup>
                                    <Label>Nsu</Label>
                                    <Input name='nsu' value={filters.nsu} onChange={handleChange} />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </CardBody>
                    <CardActions>
                        <Button type='reset'>Limpar Formulário</Button>
                        <Button type='submit' style={{ marginLeft: '10px' }}>Pesquisar</Button>
                    </CardActions>
                </Card>
            </form>
            <Dashboard.Container>
                <Dashboard.Item>
                    <Dashboard.Header>
                        <Dashboard.Title>Confirmado</Dashboard.Title>
                        <Dashboard.Total>{dashboard.captured.quantity} venda(s)</Dashboard.Total>
                    </Dashboard.Header>
                    <Dashboard.Body>
                        <Dashboard.GrossAmount>
                            {formatValue(dashboard.captured.gross_amount)}
                        </Dashboard.GrossAmount>
                        <Dashboard.NetAmount>
                            {formatValue(dashboard.captured.net_amount)}
                        </Dashboard.NetAmount>
                    </Dashboard.Body>
                </Dashboard.Item>

                <Dashboard.Item>
                    <Dashboard.Header>
                        <Dashboard.Title>Recebido</Dashboard.Title>
                        <Dashboard.Total>{dashboard.compensated.quantity} venda(s)</Dashboard.Total>
                    </Dashboard.Header>
                    <Dashboard.Body>
                        <Dashboard.GrossAmount>
                            {formatValue(dashboard.compensated.gross_amount)}
                        </Dashboard.GrossAmount>
                        <Dashboard.NetAmount>
                            {formatValue(dashboard.compensated.net_amount)}
                        </Dashboard.NetAmount>
                    </Dashboard.Body>
                </Dashboard.Item>

                <Dashboard.Item>
                    <Dashboard.Header>
                        <Dashboard.Title>Cancelado</Dashboard.Title>
                        <Dashboard.Total>{dashboard.cancelled.quantity} venda(s)</Dashboard.Total>
                    </Dashboard.Header>
                    <Dashboard.Body>
                        <Dashboard.GrossAmount>
                            {formatValue(dashboard.cancelled.gross_amount)}
                        </Dashboard.GrossAmount>
                        <Dashboard.NetAmount>
                            {formatValue(dashboard.cancelled.net_amount)}
                        </Dashboard.NetAmount>
                    </Dashboard.Body>
                </Dashboard.Item>
            </Dashboard.Container>
            <Card>
                <CardTitle>
                    <span>
                        Data da pesquisa: {label}
                    </span>
                    <Button onClick={handleExport}>Exportar Excel</Button>
                </CardTitle>
                <CardBody>
                    <TableContainer>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Bandeira</th>
                                    <th>Master</th>
                                    <th>Participante</th>
                                    <th>Tipo do Split</th>
                                    <th>Nsu</th>
                                    <th>Plataforma</th>
                                    <th>Cód de Autorização</th>
                                    <th>Data de Venda</th>
                                    <th>Tipo</th>
                                    <th>Nº do Cartão</th>
                                    <th>Valor Bruto</th>
                                    <th>Taxa MDR</th>
                                    <th>Valor Liquido</th>
                                    <th>Valor Já Recebido</th>
                                    <th>Recebimento</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !data.items.length && (
                                        <tr>
                                            <td colSpan={999} style={{ textAlign: 'center' }}>Não há vendas para serem visualizadas</td>
                                        </tr>
                                    )
                                }
                                {
                                    data.items.map(item => (
                                        <tr key={item.saleSplitId}>
                                            <td>
                                                <RouterLink to={`/detalhes-transacao/${item.saleSplitId}?isSplit=true`}>Ver Mais</RouterLink>
                                            </td>
                                            <td>
                                                <img src={`https://cdn.bcpag.com.br/app-brand/color/${item.brand.toLowerCase()}@2x.png`} title={item.brand} alt={item.brand} />
                                            </td>
                                            <td>{item.master}</td>
                                            <td>{item.participant}</td>
                                            <td>{item.splitType}</td>
                                            <td>{item.nsu}</td>
                                            <td>{item.plataform}</td>
                                            <td>{item.authorizationCode}</td>
                                            <td>{moment(item.transactionDate).format('DD/MM/YYYY HH:MM')}</td>
                                            <td>{item.saleType.name} {item.saleType.code === 'credit' && `${item.installmentQtd}x`}</td>
                                            <td>{item.cardNumber}</td>
                                            <td>{formatValue(item.grossAmount)}</td>
                                            <td>{formatValue(item.fee)}</td>
                                            <td>{formatValue(item.netAmount)}</td>
                                            <td>{formatValue(item.paidAmount)}</td>
                                            <td>{item.lastStatus.name}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </TableContainer>
                    <PaginationToolbar>
                        <PaginationButton disabled={data.page === 1} onClick={() => changePage(1)}>
                            <BiFirstPage />
                            <span>Primeira Página</span>
                        </PaginationButton>
                        <PaginationButton disabled={data.page === 1} onClick={() => changePage(data.page - 1)}>
                            <GrFormPrevious />
                            <span>Anterior</span>
                        </PaginationButton>
                        <PaginationInfo>
                            Página {data.page} de {data.totalPages}
                        </PaginationInfo>
                        <PaginationButton disabled={data.page === data.totalPages} onClick={() => changePage(data.page + 1)}>
                            <span>Próximo</span>
                            <GrFormNext />
                        </PaginationButton>
                        <PaginationButton disabled={data.page === data.totalPages} onClick={() => changePage(data.totalPages)}>
                            <span>Última página</span>
                            <BiLastPage />
                        </PaginationButton>
                    </PaginationToolbar>
                </CardBody>
            </Card>
        </div>
    )
}

export default SalesSplit;