import styled, { css } from "styled-components";

interface IProps {
	primary_color?: string;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: space-between;
	height: 100vh;
`;

export const Content = styled.div`
	flex: 1;
	max-width: 1273px;
	margin: auto;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;
	padding: 44px;

	@media (max-width: 500px) {
		padding: 24px;
	}

	@media (max-width: 875px) {
		flex-direction: column;
	}
`;

export const InfoTitle = styled.div`
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	width: 100%;

	@media (max-width: 650px) {
		flex-direction: column;
		align-items: space-between;
	}

	.showTitles {
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-transform: uppercase;
		font-size: 14px;
		color: #8094ae;

		@media (max-width: 650px) {
			margin-bottom: 20px;
		}
	}

	.showValues {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		text-align: right;
		padding: 16px 20px;
		background: #fff;
		border: 0px;
		border-radius: 10px;
		box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
		border-bottom: 3px solid #1ee0ac;

		@media (max-width: 500px) {
			flex-direction: column;
			flex-basis: 100%;
		}

		section:first-child {
			margin-right: 20px;
			padding-right: 20px;
			border-right: 1px solid #ccc;

			@media (max-width: 500px) {
				border-right: 0px;
				border-bottom: 1px solid #ccc;
				margin-right: 0;
				margin-bottom: 20px;
				padding-right: 0px;
				padding-bottom: 10px;
			}
		}

		p {
			font-size: 12px;
		}

		span {
			font-size: 24px;
		}
	}
`;

export const InfoContent = styled.div<IProps>`
	display: flex;
	/* flex: 1; */
	position: relative;
	flex-direction: column;
	padding: 16px 20px;
	width: 100%;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
	${(props) => css``}

	section {
		flex: 1;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		margin-bottom: 0;
		padding-bottom: 0;

		@media (max-width: 572px) {
			justify-content: space-between;
			flex-direction: column;
		}

		div {
			display: flex;
			justify-content: space-between;

			@media (max-width: 500px) {
				flex-direction: column;
			}

			button {
				margin: 0 5px;
				padding: 5px 10px;
				width: fit-content;
				height: fit-content;

				@media (max-width: 500px) {
					width: 100%;
					margin: 5px 0;
				}
			}

			div {
				display: flex;
				flex-direction: column;
			}
		}

		:nth-child(2) {
			margin: 20px 0;

			div:first-child {
				@media (max-width: 500px) {
					display: none;
				}
			}
		}
	}

	.customDate {
		display: flex;
		background-repeat: "no-repeat";
		align-items: center;
		flex-direction: row;
		flex-wrap: wrap;
		font-size: 14px;
		color: #8094ae;

		button {
			padding: 5px 10px;
			width: fit-content;
			height: fit-content;
		}

		input {
			width: 137px;
			color: #8094ae;
			background-color: #fff;
			border: 1px solid #ddd;
			box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
			height: 56px;
			margin: 0 5px;
			padding: 0 5px;
			border-radius: 10px;
			text-align: center;

			@media (max-width: 500px) {
				width: 100%;
				margin: 5px 0;
			}
		}

		input[type="date"]::-webkit-calendar-picker-indicator {
			transition: 0.25s ease-in-out;
			filter: none;
			font-weight: bolder;
			outline: none;

			:hover {
				cursor: pointer;
				filter: invert(0.5) sepia(0.5) saturate(5) hue-rotate(152deg);
			}
		}
	}

	h3 {
		font-weight: normal;
		color: #8094ae;
		font-size: 13px;
		flex: 1;
		margin-bottom: 10px;
	}

	p {
		position: relative;
		font-size: 12px;
		color: #526484;
		font-weight: 900;
		text-transform: uppercase;
		border-bottom: 1px solid #526484;
		padding-bottom: 5px;
	}

	.extractTable {
		width: 100%;
		margin: 0;
		@media (max-width: 1145px) {
			overflow-y: auto;
		}
	}

	table {
		flex: 1;
		border: 0px;
		width: 100%;
		font-size: 14px;
		font-weight: lighter;
		color: #526484;
		transition: 0.2s;
		margin: 0;
		padding: 0;

		@media (max-width: 415px) {
			font-size: 12px;
			tr {
				width: 555px;
			}
		}

		tr {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #ddd;
			padding: 6px 0;

			:hover {
				font-weight: 900;
			}

			:first-child {
				padding: 0;
			}

			:last-child {
				border-bottom: 0px;
				padding-bottom: 0;

				td {
					padding-bottom: 0;
				}
			}

			td,
			th {
				width: 100%;
				text-align: left;
				padding: 5px 10px;
				font-weight: lighter;

				:nth-child(3) {
					position: relative;

					span {
						position: absolute;
						padding: 0;
						margin: 0;
						width: 15px;
						height: 15px;
						left: -10px;
					}
				}
			}

			th {
				font-weight: bold;
			}
		}

		.noMoves {
			display: flex;
			justify-content: center;
			height: 75px;
			font-style: italic;

			:hover {
				font-weight: normal;
			}
		}
	}
`;
