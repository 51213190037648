import styled from "styled-components";

export const TableReceiveStyled = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 15px;

	@media (max-width: 500px) {
		font-size: 12px;
	}

	@media (max-width: 812px) {
		overflow: scroll;
	}

	span {
		display: block;
		color: #8094ae;
		padding: 20px;
		border-bottom: 1.5px solid #e5e9f2;

		@media (max-width: 812px) {
			width: 114px;
		}
	}

	div {
		width: 100%;
	}

	.tableTitle {
		font-weight: bolder;
		margin-bottom: 2.5px;

		span {
			color: #526484;
			height: 60px;
			font-weight: 700;
		}

		span:first-child {
			border: 1.5px solid;
			border-top: 0;
			border-left: 0;
			border-right: 0;
			border-image-source: linear-gradient(90deg, #c24889, #e5e9f2 100%);
			border-image-slice: 1;
		}

		span:nth-child(2) {
			text-align: center;
			border: 1.5px solid;
			border-right: 0px;
			border-top: 0;
			border-image-source: linear-gradient(90deg, #c24889, #e5e9f2 100%);
			border-image-slice: 1;
			color: #c24889;
		}

		span:nth-child(3) {
			text-align: center;
			border: 1.5px solid;
			border-right: 0px;
			border-top: 0px;
			border-image-source: linear-gradient(90deg, #4889c2, #e5e9f2 100%);
			border-image-slice: 1;
			color: #4889c2;
		}

		span:nth-child(4) {
			text-align: center;
			border: 1.5px solid;
			border-right: 0px;
			border-top: 0px;
			border-image-source: linear-gradient(90deg, #89c248, #e5e9f2 100%);
			border-image-slice: 1;
			color: #89c248;
		}

		span:nth-child(5) {
			text-align: center;
			border: 1.5px solid;
			border-right: 0px;
			border-top: 0px;
			border-image-source: linear-gradient(90deg, #c2484c, #e5e9f2 100%);
			border-image-slice: 1;
			color: #c2484c;
		}

		span:nth-child(6) {
			text-align: center;
			border: 1.5px solid;
			border-right: 0px;
			border-top: 0px;
			border-image-source: linear-gradient(90deg, #323232, #e5e9f2 100%);
			border-image-slice: 1;
			color: #323232;
		}

		span:nth-child(7) {
			text-align: center;
			border: 1.5px solid;
			border-right: 0px;
			border-top: 0px;
			border-image-source: linear-gradient(90deg, #f8cf4a, #e5e9f2 100%);
			border-image-slice: 1;
			color: #f8cf4a;
		}
	}
`;

export const WeekTable = styled.div`
	text-align: center;
	margin-bottom: 2.5px;

	.active {
		font-weight: 900;
	}

	p {
		color: #c7c7c7;
		font-weight: normal;
		font-size: 12px;
		margin-bottom: 10px;
	}

	span {
		color: #003967;
		height: 60px;

		:first-child {
			padding: 0;
		}

		:last-child {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 3px;

			p {
				font-size: 12px;
				margin-top: 10px;
				color: #526484;
			}
		}
	}
`;
