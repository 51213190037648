import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {

  const { user } = useAuth();

  let firstRoute = '/resumo';

  if(user?.only_sale) {
    firstRoute = '/vendas';
  }

  if(isPrivate && !user) {
    return <Redirect to={isPrivate ? '/' : firstRoute}/>
  }

  if(isPrivate && rest.path?.includes('assinar-contrato') && user.hasSignedContract) {
    return <Redirect to={firstRoute}/>
  }

  if(isPrivate && user && !user.hasSignedContract && !rest.path?.includes("assinar-contrato")) {
    return <Redirect to={'/assinar-contrato'}/>
  }

  return (
    <ReactDOMRoute { ...rest} component={Component}/>
  )
};

export default Route;
