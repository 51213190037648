import React, {
  useEffect, useState,
} from "react";
import { ModalContainer, LinkContainer, LinkHeader, LinkContent } from "./styles";
import { FiCheckCircle, FiX } from "react-icons/fi";
import 'moment/locale/pt-br'
import Button from "../Button";


interface IInputProps {
  isOpen: boolean;
  setIsOpen: () => void;
  linkInactivation: () => void;
}


const DisableLink: React.FC<IInputProps> = ({ isOpen, setIsOpen, linkInactivation }: IInputProps) => {
  function setInactivation() {
    linkInactivation();
    setIsOpen();
  }
  const [modalStatus, setModalStatus] = useState(isOpen);

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);


  return (
    <>
      <ModalContainer isOpen={modalStatus} onClick={() => {
        setIsOpen()
      }} />
      <LinkContainer isOpen={modalStatus}>
        <LinkContent>
          <LinkHeader>
            <h3>
              Você deseja Desativar esse link?
            <FiCheckCircle style={{ color: '#1ee0ac' }} />
            </h3>

            <FiX style={{ cursor: 'pointer' }} onClick={() => {
              setIsOpen()
            }} />
          </LinkHeader>

          <p>Após desativar este link de pagamento não será
          possível reativá-lo e seus clientes não poderão
          realizar pagamentos através dele. </p>
          <section className="btnSection">
            <Button onClick={setInactivation}>Sim</Button>
            <Button className="cancelBtn" onClick={setIsOpen}> Cancelar</Button>
          </section>
        </LinkContent>
      </LinkContainer>
    </>
  );
};
export default DisableLink;
