import styled, { css } from "styled-components";

interface ILinkProps {
	isActive: boolean;
}

export const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
	height: fit-content;
	border-bottom: 1px solid #cecece;
	font-size: 14px;
	gap: 20px;
`;

export const LinkContent = styled.div<ILinkProps>`
	display: flex;

	${(props) =>
		props.isActive &&
		css`
			border-bottom: 3px solid #003967;
		`}

	a {
		margin-bottom: 10px;
		text-align: center;
		color: #003967;
	}
`;
