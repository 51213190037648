import React, { useRef, useCallback, useState, useEffect } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link, useHistory } from "react-router-dom";
import { Container, Content, LoginFormContent, LoginCarroselContent } from "./styles";
import * as Yup from "yup";
import Input from "../../components/Input";
import api from "../../services/api";
import getValidationErrors from "../../utils/getValidationErrors";
import { useToast } from "../../hooks/toast";
import Button from "../../components/Button";
import Carousel from "../../components/CarouselImg";
import ICompany from "../../@types/company";
import generateTheme from "../../utils/generateTheme";
import { carouselData } from '../../utils/CarouselData'

interface ILoginFormData {
  password_confirmation: string;
  password: string;
}

const ResetPassword: React.FC = () => {

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState<ICompany>()
  useEffect(() => {
    setLink(generateTheme);
  }, [])
  const handleSubmit = useCallback(
    async (data: ILoginFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().min(
            4,
            "Senha obrigatória, no mínimo 4 digitos."
          ),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), undefined],
            'As senhas devem ser iguais',
          ).required('Confirmação obrigatória'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        let token = document.URL;
        await api.post('/estabelecimentos/reset', {
          token: token.substring(token.indexOf('=') + 1),
          password: data.password,
          type: 'forgot',
          confirm_password: data.password_confirmation,
        }).then(response => {
          if (response.status === 200)
            addToast({
              title: "Senha redefinida com sucesso!",
              type: "success",
              description:
                "Senha redefinida, você será redirecionado para a tela de login.",
            })
        });
        setTimeout(() => {
          history.push('/')
        }, 3000)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: "Erro ao redefinir senha",
          type: "error",
          description:
            `Ocorreu um erro ao enviar o código de acesso, cheque suas credencias ou entre em contato conosco ${link?.metadata.contact_block.contact_phone}`,

        })
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, link]
  );

  return (
    <Container>
      <Content>
        <LoginFormContent secundaryColor={link?.style.secundary_color} primaryColor={link?.style.primary_color}>
          <img className='navLogo' src={link?.style.url_logo_default} alt="logo"></img>
          <h1>Digite sua nova senha</h1>
          <Form ref={formRef} onSubmit={handleSubmit}>

            <Input name="password" type="password" placeholder="Nova senha" />
            <Input name="password_confirmation" type="password" placeholder="Confirme a nova senha" />
            <Button type="submit" loading={loading}>
              Confirmar nova senha
            </Button>
            <Link style={{ alignSelf: "center" }} to="/">Voltar para login</Link>
          </Form>
        </LoginFormContent>
        <LoginCarroselContent secundaryColor={link?.style.secundary_color} primaryColor={link?.style.primary_color}>
          <div>
            <Carousel data={carouselData} />
          </div>
        </LoginCarroselContent>
      </Content>
    </Container >

  );
};

export default ResetPassword;
