/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import ICompany from "../../@types/company";
import generateTheme from "../../utils/generateTheme";
import { Container, Slider, CarouselButton, CarouselContent } from "./styles";

interface ICarouselData {
  title: string;
  description: string;
  content: string;
  isActive: boolean;
  id: number;
}

interface IProps {
  data: ICarouselData[];
}

const Carousel: React.FC<IProps> = ({ data }: IProps) => {
  const [timer, setTimer] = useState(0);
  const [carouselData, setCarouselData] = useState<ICarouselData[]>([]);
  const [currentID, setCurrentID] = useState(0);
  const [borderPercentage, setBorderPercentage] = useState(0);
  const [link, setLink] = useState<ICompany>();

  useEffect(() => {
    setLink(generateTheme);
    if (data) {
      setBorderPercentage((1 / data.length) * 100);
      setCarouselData(data);
    }
  }, [data]);

  function resetTimer() {
    setTimer(0);
  }

  useEffect(() => {
    let interval: any;
    interval = setInterval(() => {
      setTimer((timer) => timer + 1);
      if (timer === 2 && carouselData) {
        if (currentID === carouselData.length - 1) {
          handleSelection(0);
        } else handleSelection(currentID + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer, currentID, carouselData]);

  const handleSelection = useCallback(
    (id: number) => {
      setCurrentID(id);
      if (carouselData) {
        setBorderPercentage(((id + 1) / carouselData.length) * 100);

        let newData = carouselData.map(function (value) {
          // create a new object to store full name.
          var newObj: ICarouselData;

          newObj = {
            title: value.title,
            content: value.content,
            description: value.description,
            id: value.id,
            isActive: id === value.id ? true : false,
          };

          // return our new object.
          return newObj;
        });
        setCarouselData(newData);
        resetTimer();
      }
    },
    [carouselData]
  );

  return (
    <Container
      primaryColor={link?.style.primary_color}
      secundaryColor={link?.style.secundary_color}
      className="carousel"
      borderPercentage={borderPercentage}
    >
      <Slider className="slider">
        {carouselData.map((value) => (
          <li key={value.id}>
            <CarouselButton
              primaryColor={link?.style.primary_color}
              secundaryColor={link?.style.secundary_color}
              isActive={value.isActive}
              onClick={() => handleSelection(value.id)}
            ></CarouselButton>
          </li>
        ))}
      </Slider>
      {carouselData.map((value) => (
        <CarouselContent
          primaryColor={link?.style.primary_color}
          secundaryColor={link?.style.secundary_color}
          key={value.id}
          className="content"
          isActive={value.isActive}
        >
          <p>{value.title}</p>
          <h4>{value.description}</h4>
          <h1>{value.content}</h1>
        </CarouselContent>
      ))}
      <hr style={{ width: `${borderPercentage}%` }} />
    </Container>
  );
};
export default Carousel;
