import styled, { css } from "styled-components";
import { shade } from "polished";

interface IModalProps {
	isOpen: boolean;
}

export const ModalContainer = styled.div<IModalProps>`
	height: 100%;
	width: 100%;
	position: fixed;
	background-color: black;
	opacity: 0.4;
	z-index: 10;
	display: none;

	${(props) =>
		props.isOpen &&
		css`
			display: flex;
		`}
`;

export const LinkContainer = styled.div<IModalProps>`
	border-radius: 10px;
	position: fixed;
	top: 50%;
	left: 50%;
	/* bring your own prefixes */
	transform: translate(-50%, -50%);
	height: fit-content;
	opacity: 1 !important;
	width: 35%;
	background-color: #fff;
	z-index: 100;
	display: none;
	padding: 20px;
	font-size: 15px;

	${(props) =>
		props.isOpen &&
		css`
			display: flex;
		`}

	@media screen and (max-width: 935px) {
		position: fixed;
		width: 75%;
		top: 50%;
		left: 50%;
		/* bring your own prefixes */
		transform: translate(-50%, -50%);
	}
`;
export const LinkHeader = styled.header`
	display: flex;
	width: 100%;
	justify-content: space-around;
	h3 {
		width: 100%;
		margin-bottom: 20px;
	}
	svg {
		vertical-align: sub;
		margin-left: 10px;
		width: 30px;
		font-size: 20px;
	}

	@media screen and (max-width: 935px) {
		font-size: 13px;
	}
`;

export const LinkContent = styled.div`
	width: 100%;
	select {
		border: 1px solid #dbdfea;
		background: white;
		color: #3c4d62;
		width: 100%;
		background-color: #fff;
		display: flex;
		height: 56px;
		padding: 0.35rem 1rem;
		line-height: 1.25rem;
		margin-top: 10px;
		border-radius: 10px;
	}

	.LinkExp {
		text-transform: capitalize;
		font-size: 13px;
		font-weight: bold;
		margin-top: 20px;
	}
	@media screen and (max-width: 935px) {
		select,
		input {
			width: 100%;
			padding: 0.15rem 0.5rem;
			font-size: 10.5px;
		}
		svg {
			width: 15px;
			margin-right: 0px;
		}
		.LinkExp {
			font-size: 10px;
		}
	}

	.btnSection {
		display: flex;
		width: 100%;
		gap: 20px;
		button {
			margin-top: 20px;
			width: 100px;
		}
		.cancelBtn {
			background: #e85347;
			color: #fff;
			:hover {
				background: ${shade(0.2, "#e85347")};
				color: #fff;
			}
		}
	}
`;

export const LinkSuccess = styled.div`
	width: 100%;
	.generatedLink {
		word-break: break-all;
		z-index: 10;
		margin-top: 20px;
		padding: 20px;
		background-color: #e0e7ec;
		border-radius: 10px;
		height: fit-content;
		width: 100%;

		#linkArea {
			text-align: center;
			font-size: 16px;
			width: 100%;
			font-weight: bold;
			border: 0;
			cursor: default;
			background-color: transparent;
		}

		p {
			cursor: pointer;
			text-align: center;
			color: #003967;
			margin-top: 15px;
			font-size: 12px;
			width: 100%;

			:hover {
				backface-visibility: hidden;
				-webkit-font-smoothing: subpixel-antialiased;
				transform: translateZ(0);
				transform: scale(1.1);
				transition: 1s;
			}
			svg {
				vertical-align: sub;
				font-weight: bold;
				margin-left: 10px;
				font-size: 18px;
			}
			button {
				margin-top: 20px;
			}
		}
	}
	@media screen and (max-width: 935px) {
		.generatedLink {
			margin-bottom: 20px;
			#linkArea {
				font-size: 12px;
			}
			p {
				font-size: 10px;
			}
			svg {
				font-size: 14px;
			}
		}
	}
`;
