
export const setStatusTranslation = (value: string) => {
    switch (value.toLowerCase()) {
        case 'processing': {
            return 'Processando'
        }
        case 'authorized': {
            return 'Autorizado'
        }
        case 'refunded': {
            return 'Cancelado'
        }
        case 'paid': {
            return 'Pago'
        }
        case 'chargedback': {
            return 'Chargeback'
        }
        case 'receivable': {
            return 'À receber'
        }
        case 'compensated': {
            return 'Compensado'
        }
        case 'antecipated': {
            return 'Antecipado'
        }  
        case 'credit': {
            return 'Crédito'
        }
        case 'debit': {
            return 'Antecipado'
        }
        case 'feriado': {
            return 'Feriado'
        }    
        case 'waiting_payment': return 'Aguardando Pagamento';
        case 'analyze': return 'Análise';
        default:
            return
    }  
}

export const setStatusColor = (value: string) => {
       switch (value.toLowerCase()) {
        case 'processing': {
            return ' rgb(204,215,224)'
        }
        case 'authorized': {
            return ' rgb(204,215,224)'
        }
        case 'feriado': {
            return ' rgb(204,215,224)'
        }
        case 'refunded': {
            return ' rgb(232,83,71)'
        }
        case 'paid': {  
            return ' #1ee0ac'
        }
        case 'chargedback': {
            return ' rgb(232,83,71)'
        }
        case 'receivable': {
            return 'rgb(0,150,255)'
        }
        case 'compensated': {
            return '#1ee0ac'
        }
        case 'antecipated': {
            return 'rgb(0,150,255)'
        }  
        default:
            return
    }  
}

export const setBorderColor = (value: string) => {
    switch (value.toLowerCase()) {
        case 'processing': {
            return '2px solid rgb(204,215,224)'
        }
        case 'authorized': {
            return '2px solid rgb(204,215,224)'
        }
        case 'feriado': {
            return '2px solid rgb(204,215,224)'
        }
        case 'refunded': {
            return '2px solid rgb(232,83,71)'
        }
        case 'paid': {  
            return '2px solid #1ee0ac'
        }
        case 'chargedback': {
            return '2px solid rgb(232,83,71)'
        }
        case 'receivable': {
            return '2px solid rgb(0,150,255)'
        }
        case 'compensated': {
            return '2px solid #1ee0ac'
        }
        case 'antecipated': {
            return '2px solid rgb(0,150,255)'
        }  
        default:
            return
    }  
}

export const setAmountColor = (n: number) => {
    if (n < 1) return '#8094ae'
    else if(n>1)return '#18b389'
    else if(n<0)return '#f94144'
}