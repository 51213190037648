import React, {
	useRef,
	useCallback,
	useState,
	ChangeEvent,
	useEffect,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link } from "react-router-dom";
import {
	Container,
	Content,
	LoginFormContent,
	LoginCarroselContent,
} from "./styles";
import * as Yup from "yup";
import Input from "../../components/Input";
import api from "../../services/api";
import { handleTaxIDInput } from "../../utils/masks";
import getValidationErrors from "../../utils/getValidationErrors";
import { useToast } from "../../hooks/toast";
import Button from "../../components/Button";
import Carousel from "../../components/CarouselImg";
import { validateCPF_CNPJ } from "../../utils/CPF_CNPJValidation";
import ICompany from "../../@types/company";
import generateTheme from "../../utils/generateTheme";
import { carouselData } from "../../utils/CarouselData";

interface ILoginFormData {
	cpf_cnpj: string;
	password: string;
	password_confirmation: string;
	email: string;
}

const SignUp: React.FC = () => {
	const formRef = useRef<FormHandles>(null);
	const { addToast } = useToast();
	const [loading, setLoading] = useState(false);
	// const history = useHistory();
	const [inputMask, setInputMask] = useState("");
	const [link, setLink] = useState<ICompany>();
	useEffect(() => {
		setLink(generateTheme);
	}, []);

	const handleSubmit = useCallback(
		async (data: ILoginFormData) => {
			try {
				let bollCPF_CNPJ: any;
				let schema;
				if (data.cpf_cnpj !== "") {
					bollCPF_CNPJ = validateCPF_CNPJ(data.cpf_cnpj);
					schema = Yup.object().shape({
						cpf_cnpj: Yup.string()
							.required("CPF ou CNPJ obrigatório.")
							.test("valid", "CPF/CNPJ inválido", () => bollCPF_CNPJ === true),
						password: Yup.string().min(
							4,
							"Senha obrigatória, no mínimo 4 digitos."
						),
						email: Yup.string()
							.required("E-mail obrigatório")
							.email("E-mail inválido"),
						password_confirmation: Yup.string()
							.oneOf(
								[Yup.ref("password"), undefined],
								"As senhas devem ser iguais"
							)
							.required("Confirmação obrigatória"),
					});
				} else {
					schema = Yup.object().shape({
						cpf_cnpj: Yup.string().required("CPF ou CNPJ obrigatório."),
						password: Yup.string().min(
							4,
							"Senha obrigatória, no mínimo 4 digitos."
						),
						email: Yup.string()
							.required("E-mail obrigatório")
							.email("E-mail inválido"),
						password_confirmation: Yup.string()
							.oneOf(
								[Yup.ref("password"), undefined],
								"As senhas devem ser iguais"
							)
							.required("Confirmação obrigatória"),
					});
				}
				setLoading(true);
				formRef.current?.setErrors({});
				await schema.validate(data, {
					abortEarly: false,
				});

				await api
					.post("/estabelecimentos/registrar", {
						tax_id: data.cpf_cnpj.replace(/\D/gi, ''),
						email: data.email,
						password: data.password,
						confirm_password: data.password_confirmation,
					})
					.then((response) => {
						if (response.status === 200)
							addToast({
								title: "Primeiro acesso registrado!",
								type: "success",
								description:
									"Primeiro acesso registrado com sucesso, você será redirecionado para a página de login.",
							});

						setTimeout(() => {
							// history.push("/");
						}, 3000);
					});
			} catch (err) {
				if (err instanceof Yup.ValidationError) {
					const errors = getValidationErrors(err);
					formRef.current?.setErrors(errors);

					return;
				}
				const description = `Ocorreu um erro ao enviar o código de acesso, verifique se o seu Email e Documento estão corretos ou entre em contato conosco ${link?.metadata.contact_block.contact_phone}`;
				addToast({
					title: "Erro ao registrar primeiro acesso!",
					type: "error",
					description: description
				});
			} finally {
				setLoading(false);
			}
		},
		[addToast, link]
	);

	return (
		<Container>
			<Content>
				<LoginFormContent
					secundaryColor={link?.style.secundary_color}
					primaryColor={link?.style.primary_color}
				>
					<img
						className="navLogo"
						src={link?.style.url_logo_default}
						alt="logo"
					></img>
					<h1>Primeiro acesso</h1>
					<p>Configure sua conta</p>
					<Form ref={formRef} onSubmit={handleSubmit}>
						<Input
							value={inputMask}
							name="cpf_cnpj"
							placeholder="CPF ou CNPJ"
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setInputMask(handleTaxIDInput(e.currentTarget.value));
							}}
						/>

						<Input name="email" placeholder="Email" />
						<Input
							name="password"
							type="password"
							placeholder="Crie uma senha"
						/>
						<Input
							name="password_confirmation"
							type="password"
							placeholder="Repita sua senha"
						/>
						<Button type="submit" loading={loading}>
							Registrar
						</Button>
						<Link style={{ alignSelf: "center" }} to="/">
							Voltar para Login
						</Link>
					</Form>
				</LoginFormContent>
				<LoginCarroselContent
					secundaryColor={link?.style.secundary_color}
					primaryColor={link?.style.primary_color}
				>
					<div>
						<Carousel data={carouselData} />
					</div>
				</LoginCarroselContent>
			</Content>
		</Container>
	);
};

export default SignUp;
