import React, { createContext, useCallback, useState, useContext } from 'react';
import IUser, { RentType } from '../@types/user';
import api from '../services/api';
import { useHistory } from 'react-router-dom';

interface AuthState {
  token: string;
  user: IUser;
}

interface SignInCredentials {
  username: string;
  password: string;
  captcha: string
}

interface AuthContextData {
  user: IUser;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@PortalBC:token');
    const user = localStorage.getItem('@PortalBC:user');

    if (token && user) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      api.interceptors.response.use(
        response => {
          return response;
        },
        error => {
          const { response } = error;

          if (response.status === 401) {
            signOut();
          }

          return Promise.reject(error);
        },
      );

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });  

  const signOut = useCallback(() => {
    localStorage.removeItem('@PortalBC:token');
    localStorage.removeItem('@PortalBC:user');

    setData({} as AuthState);
  }, []);

  const history = useHistory();

  const setupInvalidSessionInterceptor = useCallback(() => {
    api.interceptors.response.use(

      response => {
        return response;
      },
      error => {
        const { response } = error;


        if (response.status === "TOKEN EXPIRED") {
          signOut();
        }

        return Promise.reject(error);
      },
    );
  }, [signOut]);

  const signIn = useCallback(async ({ username, password, captcha }) => {

    try {
      const response = await api.post('/estabelecimentos/login', {
        username,
        password,
        captcha
      })

      const user = response.data as IUser;
      const { token } = response.data;
      api.defaults.headers.Authorization = `Bearer ${token}`;

      localStorage.setItem('@PortalBC:token', token);
      localStorage.setItem('@PortalBC:user', JSON.stringify(user));
      setupInvalidSessionInterceptor();
      setData({ user, token });

      api.get<RentType>('estabelecimentos/aluguel')
        .then(x => user.rent = x.data)
        .catch(x => user.rent = null);

      return history.push("/resumo");
    }
    catch {
      console.log("Erro de Login");
    }

  }, [setupInvalidSessionInterceptor]);


  const updateUser = useCallback(
    (user: IUser) => {
      localStorage.setItem('@PortalBC:user', JSON.stringify(user));
      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an Auth provider');
  }
  return context;
}

export { AuthProvider, useAuth };
