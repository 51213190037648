import styled, { css } from "styled-components";

interface IProps {
	primary_color?: string;
	secundary_color?: string;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: space-between;
	height: 100vh;
`;
export const Content = styled.div`
	max-width: 1273px;
	margin: auto;

	flex: 1;
	flex-direction: column;
	width: 100%;
	padding: 44px;

	@media (max-width: 500px) {
		padding: 24px;
	}

	.widgetNext {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 20px;

		p {
			font-size: 14px;
			margin-right: 10px;
			color: #8094ae;
		}
	}
`;
export const ModuleTop = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;

	@media (max-width: 838px) {
		flex-direction: column;
	}
`;

export const FilterExtract = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	font-size: 14px;
	color: #8094ae;
	margin-right: 20px;
	margin-left: 20px;
	width: 350px;

	@media (max-width: 812px) {
		margin-right: 0;
		margin-left: 0;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	select {
		cursor: pointer;
		background: #fff;
		color: #8094ae;
		border: 0px;
		border-radius: 10px;
		padding: 5px;
		box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
		margin-top: 2.5px;
		margin-bottom: 2.5px;
		width: 200px;
	}

	.custom-date {
		margin-top: 10px;
		display: flex;
		align-items: flex-end;
		width: 360px;
		justify-content: space-between;
	}

	.form-group {
		display: flex;
		flex-direction: column;
		align-items: self-start;

		input {
			background-color: #fff;
			color: #8094ae;
			border: 1px solid #ddd;
			box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
			padding: 5px;
			border-radius: 10px;
		}
	}

	button {
		margin-bottom: 2px;
		padding: 5px 10px;
		height: fit-content;
		letter-spacing: 2px;
	}

	.custom-date {
		
	}
`;

export const ModuleAccount = styled.div`
	color: #8094ae;
	font-size: 14px;
	flex: .7;

	h1 {
		font-family: "Nunito", sans-serif;
		font-size: 40px;
		color: #364a63;
		font-weight: lighter;
		text-transform: uppercase;
		letter-spacing: 1px;
		line-height: 40px;
		margin: 10px 0px;

		button {
			position: relative;
			top: -5px;
			margin: 0px 20px;
			vertical-align: middle;
			border: 0px;
			font-size: 13px "Nunito";
			height: 36px;
			background: #fff;
			padding: 0px 18px;
			border: 1px solid #c3cce1;
			border-radius: 10px;
			cursor: pointer;
			transition: 0.2s ease-in-out;

			svg {
				margin-left: 10px;
				font-size: 18px;
				vertical-align: middle;
				transition: 0.2s ease-in-out;
			}

			:hover {
				background: #ccd3e5;
				border-color: #c3cce1;
			}
		}

		@media (max-width: 500px) {
			font-size: 30px;
			line-height: 30px;

			button {
				margin: 0px;
			}
		}
	}
`;
export const ModuleInfo = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;

	@media (max-width: 1045px) {
		flex-direction: column;
	}
`;

export const LastSales = styled.div<IProps>`
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
	margin-right: 20px;

	@media (max-width: 812px) {
		margin-bottom: 20px;
	}

	.refreshSales {
		${(props) => css`
			color: ${props.primary_color};
		`}
		opacity: .5;
		cursor: pointer;
		transition: 0.5s;

		:hover {
			${(props) => css`
				color: ${props.secundary_color};
			`}
			transform: scale(1.2) rotate(180deg);
			opacity: 1;
		}
	}

	.seeMore {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 10px;
		text-decoration: none;
		font-size: 14px;
		font-weight: 600;
	}
`;

export const LastSalesContent = styled.div<IProps>`
	flex: 1;

	@media (max-width: 500px) {
		overflow-y: scroll;
	}

	table {
		width: 100%;
	}

	td,
	th {
		width: 100%;
	}

	tr {
		display: flex;
		height: fit-content;
		justify-content: space-between;
		font-size: 15px;
		width: 100%;

		@media (max-width: 500px) {
			font-size: 12px;
			width: 460px;
		}

		font-weight: lighter;
		color: #526484;
		border-bottom: 1px solid #e5e9f2;
		box-shadow: white;
		list-style: none;
		/* padding: 10px; */
		cursor: pointer;

		:hover {
			font-weight: 900;
		}

		th {
			cursor: default;
			font-weight: 300;

			:first-child {
				text-align: left;
			}

			:nth-child(n + 2) {
				text-align: center;
			}

			:last-child {
				text-align: right;
			}
		}

		td {
			position: relative;
			padding: 20px 0px;
			vertical-align: middle;

			img {
				width: 30px;
				vertical-align: middle;
			}

			:nth-child(n + 2) {
				text-align: center;
			}

			:last-child {
				text-align: center;

				p {
					font-weight: 900;
					font-size: 12px;
					letter-spacing: 0px;
				}
			}
		}
	}

	.noSales {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 75px;
		font-style: italic;

		:hover {
			cursor: default;
			font-weight: normal;
		}
	}
`;

export const ModuleGraphic = styled.section`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: max-content;
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);

	.noData {
		display: flex;
		height: 100%;
		width: 100%;
		text-align: center;

		p {
			flex: 1;
			align-self: center;
			width: fit-content;
			font-size: 15px;
			font-weight: lighter;
			color: #526484;
			font-style: italic;
		}
	}
`;

export const InformationsContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;

	div {
		font-size: 14px;
		color: #526484;
	}
`;

export const ModuleTable = styled.section<IProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
`;
