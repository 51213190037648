import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import {
    Container,
    Content,
    Title,
    Card,
    CardBody,
    CardActions,
    Select,
    Input,
    FormGroup,
    Label,
    Button,
    Grid,
    Toggle,
    ToggleOption,
    Table,
    TableContainer,
    Backdrop,
    ErrorMessage
} from './style';
import HeaderComponent from '../../components/Header';
import formatValue, { formatPercentage } from '../../utils/formatValue';
import moment from 'moment';
import api from '../../services/api';
import { IBrandList } from '../../@types/sales';
import CircularProgress from '@material-ui/core/CircularProgress'
import {
    Brand,
    Plataform,
    SaleType,
    SimulateRequestModel,
    SimulateResponseModel
} from './types'

const Simulator: React.FC = () => {


    const [loading, setLoading] = useState(false);
    const [simulate, setSimulate] = useState<SimulateResponseModel | undefined>(undefined);

    const [brands, setBrands] = useState<Brand[]>([])
    const [products, setProducts] = useState<SaleType[]>([])
    const [plataforms, setPlataforms] = useState<Plataform[]>([])
    const [errors, setErrors] = useState<Record<string, string>>({})

    const [query, setQuery] = useState<SimulateRequestModel>({
        amount: 0,
        brandId: -1,
        fees: 'company',
        installments: 1,
        plataformTypeId: '',
        saleType: ''
    })

    const handleChange = (e: ChangeEvent & { target: { value: string, name: string } }) => {
        setQuery(oldQuery => ({ ...oldQuery, [e.target.name]: e.target.value }));
    }

    const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const amount = parseInt((value || '0').replace(/\D/gi, '')) / 100;

        setQuery(oldQuery => ({ ...oldQuery, amount }))
    }

    useEffect(() => {
        setLoading(true)

        const brandRequest = api.get<IBrandList>('vendas/bandeiras');
        const productRequest = api.get<SaleType[]>('vendas/produtos');
        const plataformTypesRequest = api.get<Plataform[]>('vendas/plataformas');

        Promise.all([brandRequest, productRequest, plataformTypesRequest])
            .then(([brand, product, plataform]) => {
                setBrands(brand.data.result);
                setProducts(product.data);
                setPlataforms(plataform.data);
                setLoading(false);
            })
    }, [])

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const currentErrors: Record<string, string> = {};

        if (query.amount <= 0) {
            currentErrors.amount = 'Valor inválido';
        }

        if (query.brandId < 0) {
            currentErrors.brandId = 'Selecione uma bandeira';
        }

        if (!query.plataformTypeId) {
            currentErrors.plataformTypeId = 'Selecione uma plataforma';
        }

        if (!query.saleType) {
            currentErrors.saleType = 'Selecione um produto';
        }

        setErrors(currentErrors);

        if (Object.keys(currentErrors).length) {
            return;
        }

        setLoading(true);

        api.get<SimulateResponseModel>('simulador', { params: query })
            .then(x => {
                setSimulate(x.data)
                setLoading(false);
            })
    }

    return (
        <>
            {loading &&
                <Backdrop>
                    <CircularProgress />
                </Backdrop>
            }
            <Container>
                <HeaderComponent linkValue="simulador" />
                <Content>
                    <Title>Simulador de vendas</Title>
                    <Card>
                        <CardBody>
                            <Grid onSubmit={handleSubmit} id="frmSimulador" spacing={10} container as="form">
                                <Grid col={12}>
                                    <Toggle>
                                    <ToggleOption
                                            active={query.fees === 'company'}
                                            onClick={() => setQuery(oldQuery => ({ ...oldQuery, fees: 'company' }))}
                                        >
                                            Quero Cobrar
                                        </ToggleOption>
                                        <ToggleOption
                                            onClick={() => setQuery(oldQuery => ({ ...oldQuery, fees: 'customer' }))}
                                            active={query.fees === 'customer'}>Quero Receber
                                        </ToggleOption>
                                    </Toggle>
                                </Grid>
                                <Grid col={4}>
                                    <FormGroup>
                                        <Label>Valor</Label>
                                        <Input error={!!errors.amount} value={formatValue(query.amount)} name="amount" onChange={handleChangeValue} />
                                        {errors.amount && <ErrorMessage>{errors.amount}</ErrorMessage>}
                                    </FormGroup>
                                </Grid>
                                <Grid col={4}>
                                    <FormGroup>
                                        <Label>Plataforma</Label>
                                        <Select error={!!errors.plataformTypeId} name="plataformTypeId" value={query.plataformTypeId} onChange={handleChange}>
                                            <option value={''}>Selecione uma plataforma</option>
                                            {
                                                plataforms.map(x => (
                                                    <option key={x.plataformTypeId} value={x.plataformTypeId}>{x.name}</option>
                                                ))
                                            }
                                        </Select>
                                        {errors.plataformTypeId && <ErrorMessage>{errors.plataformTypeId}</ErrorMessage>}
                                    </FormGroup>
                                </Grid>
                                <Grid col={4}>
                                    <FormGroup>
                                        <Label>Bandeira</Label>
                                        <Select error={!!errors.brandId} name="brandId" value={query.brandId} onChange={handleChange}>
                                            <option value={-1}>Seleciona uma bandeira</option>
                                            {
                                                brands.map(x => (
                                                    <option key={x.brand_id} value={x.brand_id}>{x.name}</option>
                                                ))
                                            }
                                        </Select>
                                        {errors.brandId && <ErrorMessage>{errors.brandId}</ErrorMessage>}
                                    </FormGroup>
                                </Grid>
                                <Grid col={8}>
                                    <FormGroup>
                                        <Label>Produto</Label>
                                        <Select error={!!errors.saleType} name="saleType" value={query.saleType} onChange={handleChange}>
                                            <option value={''}>Selecione um produto</option>
                                            {
                                                products.map(x => (
                                                    <option key={x.code} value={x.code}>{x.name}</option>
                                                ))
                                            }
                                        </Select>
                                        {errors.saleType && <ErrorMessage>{errors.saleType}</ErrorMessage>}
                                    </FormGroup>
                                </Grid>
                                {
                                    query.saleType === 'credit' && <Grid col={4}>
                                        <FormGroup>
                                            <Label>Parcelas</Label>
                                            <Select name="installments" value={query.installments} onChange={handleChange} >
                                                {Array.from({ length: 21 }).map((_, i) => (
                                                    <option key={i} value={i + 1}>{i + 1}</option>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    </Grid>
                                }
                            </Grid>
                        </CardBody>
                        <CardActions>
                            <Button form="frmSimulador" type='submit'>Simular</Button>
                        </CardActions>
                    </Card>
                    {
                        simulate ? (
                            <>
                                <Card style={{ margin: '20px 0px' }}>
                                    <CardBody>
                                        <Grid container spacing={10}>
                                            <Grid col={3}>
                                                <div style={{ fontWeight: 'bold' }}>Bandeira</div>
                                                <img src={`https://cdn.bcpag.com.br/app-brand/color/${simulate.brand.toLowerCase()}@2x.png`} alt={simulate.brand} />
                                            </Grid>
                                            <Grid col={3}>
                                                <div style={{ fontWeight: 'bold' }}>Plataforma</div>
                                                <div>{simulate.plataformType}</div>
                                            </Grid>
                                            <Grid col={3}>
                                                <div style={{ fontWeight: 'bold' }}>Produto</div>
                                                <div>{simulate.product}</div>
                                            </Grid>
                                            <Grid col={3}>
                                                <div style={{ fontWeight: 'bold' }}>
                                                    Deve cobrar
                                                </div>
                                                <div>{formatValue(simulate.grossAmount)}</div>
                                            </Grid>
                                            <Grid col={3}>
                                                <div style={{ fontWeight: 'bold' }}>
                                                    Vai receber
                                                </div>
                                                <div>{formatValue(simulate.netAmount)}</div>
                                            </Grid>
                                            <Grid col={3}>
                                                <div style={{ fontWeight: 'bold' }}>Taxa MDR</div>
                                                <div>{formatPercentage(simulate.mdr)}</div>
                                            </Grid>

                                            {
                                                simulate.saleTypeCode === 'credit' && (
                                                    <>
                                                        <Grid col={3}>
                                                            <div style={{ fontWeight: 'bold' }}>Deve cobrar (antecipado)</div>
                                                            <div>{formatValue(simulate.antecipatedAmount)}</div>
                                                        </Grid>
                                                        <Grid col={3}>
                                                            <div style={{ fontWeight: 'bold' }}>Taxa antecipação</div>
                                                            <div>{formatPercentage(simulate.antecipationRate)}</div>
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                        </Grid>
                                    </CardBody>
                                </Card>
                                {
                                    simulate.saleTypeCode === 'credit' && (
                                        <TableContainer>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Previsão de Pagamento</th>
                                                        <th>Parcela Nº</th>
                                                        <th>Valor Bruto</th>
                                                        <th>Valor Liquido</th>
                                                        <th>Valor Antecipado</th>
                                                        <th>Taxa Antecipação</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (simulate.installments || []).map(x => (
                                                            <tr key={x.installment}>
                                                                <td>
                                                                    {moment(x.previsionPaymentDate).format('DD/MM/YYYY')}
                                                                </td>
                                                                <td>
                                                                    {x.installment}
                                                                </td>
                                                                <td>
                                                                    {formatValue(x.grossAmount)}
                                                                </td>
                                                                <td>
                                                                    {formatValue(x.netAmount)}
                                                                </td>
                                                                <td>
                                                                    {formatValue(x.antecipatedAmount)}
                                                                </td>
                                                                <td>
                                                                    {formatPercentage(x.antecipationRate)}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </TableContainer>
                                    )
                                }
                            </>
                        ) : (
                            <Card style={{ margin: '20px 0px' }}>
                                <CardBody style={{ textAlign: 'center' }}>
                                    Não há venda sendo simulada.
                                </CardBody>
                            </Card>
                        )
                    }
                </Content>
            </Container>
        </>
    )
}

export default Simulator;