const formatValue = (value: number): string =>
  Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

export const formatPercentage = (val: number) =>
  Intl.NumberFormat("pt-BR", {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2

  }).format(val / 100);

export default formatValue;


