import React, {
  useRef,
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  Content,
  LoginFormContent,
  LoginCarroselContent,
} from "./styles";
import * as Yup from "yup";
import Input from "../../components/Input";
import getValidationErrors from "../../utils/getValidationErrors";
import Button from "../../components/Button";
import Carousel from "../../components/CarouselImg";

import { useToast } from "../../hooks/toast";
import { useAuth } from "../../hooks/auth";
import { handleTaxIDInput } from "../../utils/masks";
import { validateCPF_CNPJ } from "../../utils/CPF_CNPJValidation";
import ICompany from "../../@types/company";
import api from "../../services/api";
import { generateEncryption } from "../../utils/generateDNSEncryption";
import { carouselData } from "../../utils/CarouselData";
import MetaDecorator from "../../components/MetaDecorator";
import ReCAPTCHA from 'react-google-recaptcha'
// import MetaDecorator from "../../components/MetaDecorator";

interface ILoginFormData {
  user: string;
  password: string;
  cpf_cnpj: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { signIn, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [inputMask, setInputMask] = useState("");
  const [link, setLink] = useState<ICompany>();
  const [captcha, setCaptcha] = useState('');

  useEffect(() => {
    api
      .get("/acesso", {
        params: {
          encrypted: generateEncryption().toString(),
        },
      })
      .then((response) => {
        localStorage.setItem("@PortalBC:theme", JSON.stringify(response.data));
        setLink(response.data);
      });
  }, []);

  function showPassword() {
    var x = document.getElementById("passwordInput") as HTMLInputElement;
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const handleSubmit = useCallback(
    async (data: ILoginFormData) => {
      try {
        setLoading(true);
        let bollCPF_CNPJ: any;
        let schema;
        if (data.cpf_cnpj !== "") {
          bollCPF_CNPJ = validateCPF_CNPJ(data.cpf_cnpj);
          schema = Yup.object().shape({
            cpf_cnpj: Yup.string()
              .required("CPF/CNPJ obrigatório.")
              .test("valid", "CPF/CNPJ inválido", () => bollCPF_CNPJ === true),
            password: Yup.string().min(
              4,
              "Senha obrigatória, no mínimo 4 digitos."
            ),
          });
        } else {
          schema = Yup.object().shape({
            cpf_cnpj: Yup.string().required("CPF/CNPJ obrigatório."),
            password: Yup.string().min(
              8,
              "Senha obrigatória, no mínimo 4 digitos."
            ),
          });
        }

        formRef.current?.setErrors({});

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          username: data.cpf_cnpj.replace(/\D/g, ""),
          password: data.password,
          captcha
        });

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          title: "Falha ao logar",
          description:
            "Ocorreu um erro ao logar, verifique seu usuário e senha.",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, signIn]
  );

  return (
    <Container>
      <MetaDecorator />
      <Content>
        {link && (
          <>
            <LoginFormContent
              secundaryColor={link?.style.secundary_color}
              primaryColor={link?.style.primary_color}
            >
              <img
                className="navLogo"
                src={link?.style.url_logo_default}
                alt="logo"
              ></img>
              <h1>Entrar</h1>
              <p>Acesse o painel de vendas</p>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Input
                  value={inputMask}
                  name="cpf_cnpj"
                  placeholder="CPF ou CNPJ"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInputMask(handleTaxIDInput(e.currentTarget.value));
                  }}
                />
                <Link to="/esqueceu-senha">Esqueceu sua senha?</Link>
                <Input
                  id="passwordInput"
                  name="password"
                  type="password"
                  placeholder="Senha"
                />
                <label id="eye" htmlFor="eye">
                  <input type="checkbox" name="eye" onChange={showPassword} />
                  Mostrar senha
                </label>
                {
                  process.env.NODE_ENV === 'production' && (
                    <ReCAPTCHA onChange={e => setCaptcha(e || '')} style={{ margin: '10px 0px' }} sitekey="6LdtwVUmAAAAAGnh61wWgKkJe-pm3BDfsDSRczXB"/>
                  )
                }
                <Button disabled={process.env.NODE_ENV !== 'development' && !captcha} type="submit" loading={loading}>
                  Entrar
                </Button>
                <Link style={{ alignSelf: "center" }} to="/primeiro-acesso">
                  Primeiro acesso? Clique aqui
                </Link>
              </Form>
            </LoginFormContent>
            <LoginCarroselContent
              secundaryColor={link?.style.secundary_color}
              primaryColor={link?.style.primary_color}
            >
              <div>
                <Carousel data={carouselData} />
              </div>
            </LoginCarroselContent>
          </>
        )}
      </Content>
    </Container>
  );
};

export default Login;
