import styled, { css } from "styled-components";

interface ICarouselProps {
	isActive: boolean;
	primaryColor?: string;
	secundaryColor?: string;
}

interface IBorderProps {
	borderPercentage: number;
	primaryColor?: string;
	secundaryColor?: string;
}

export const Container = styled.div<IBorderProps>`
	flex: 0.4;
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	flex-direction: column;
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
	${(props) =>
		props &&
		css`
			border-bottom: 3px solid ${props.primaryColor};
		`}

	hr {
		transition: 3s ease-in-out;
		position: absolute;
		bottom: 0px;
		left: 0px;
		height: 3px;
		background: #1ee0ac;
		border-radius: 10px;
		border: 0;

		/* ${(props) =>
			props.borderPercentage &&
			css`
				width: ${props.borderPercentage};
			`} */
	}

	h1 {
		${(props) => css`
			color: ${props.primaryColor};
		`}
	}
`;

export const Slider = styled.div`
	display: flex;
	width: fit-content;
	flex-direction: row;
	position: relative;
	right: -100%;
	transform: translateX(-100%);
	align-items: center;
	justify-content: space-between;
	margin-bottom: 5px;

	li {
		list-style: none;
		padding: 0 4px;
		:last-child {
			padding-right: 0px;
		}
	}
`;

export const CarouselButton = styled.button<ICarouselProps>`
	content: "";
	width: 8px;
	height: 8px;
	border: 0px;
	border-radius: 50%;
	background: #dbdfea;
	cursor: pointer;

	${(props) =>
		props.isActive &&
		css`
			background-color: ${props.primaryColor};
		`}

	:last-child {
		padding-right: 0px;
	}
`;
export const CarouselContent = styled.div<ICarouselProps>`
	display: none;

	${(props) =>
		props.isActive &&
		css`
			display: block;
		`}

	${(props) =>
		props &&
		css`
			color: ${props.primaryColor};
		`}
    font-size: 18px;
	flex-wrap: wrap;
	transition: 0.2s ease-in-out;

	p {
		position: relative;
		top: -24px;
		width: fit-content;
		font-size: 16px;
		margin-right: 10px;
		color: #8094ae;
	}

	h4 {
		font-weight: lighter;
	}

	h1,
	h4 {
		position: relative;
		text-align: right;
	}
`;
