import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Content,
  FilterSection,
  ModuleInfo,
  SectionTransaction,
  Filter,
  SalesContent,
} from "./styles";
import HeaderComponent from "../../../components/Header";
import FooterComponent from "../../../components/Footer";
import { AiOutlineInfoCircle, AiOutlineDownload } from "react-icons/ai";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { BiFirstPage, BiLastPage } from "react-icons/bi";
import api from "../../../services/api";
import formatValue from "../../../utils/formatValue";
import moment from "moment";
import Button from "../../../components/Button";
import downloadFile from "../../../utils/downloadCSVFile";
import { useToast } from "../../../hooks/toast";
import ICompany from "../../../@types/company";
import generateTheme from "../../../utils/generateTheme";
import {
  ISerialList,
  ISalesList,
  IBrandList,
  ITransactionsStatus,
} from "../../../@types/sales";
import TableTransactions from "../../../components/Table/TableTransactions";
import { Skeleton } from "@material-ui/lab";

type Filter =
  {
    label: string
    type: string
    startDate: string
    endDate: string
  }

const filters: Filter[] = [
  {
    label: 'Este Mês',
    type: 'this_month',
    startDate: moment().startOf('month').format("YYYY-MM-DD"),
    endDate: moment().endOf('month').format("YYYY-MM-DD"),
  },
  {
    label: 'Hoje',
    type: 'today',
    startDate: moment().startOf('day').format("YYYY-MM-DD"),
    endDate: moment().endOf('day').format("YYYY-MM-DD"),
  },
  {
    label: 'Ontem',
    type: 'yesterday',
    startDate: moment().subtract(1, 'day').startOf('day').format("YYYY-MM-DD"),
    endDate: moment().subtract(1, 'day').endOf('day').format("YYYY-MM-DD"),
  },
  {
    label: 'Ultimos 7 dias',
    type: 'week',
    startDate: moment().subtract(7, 'day').startOf('day').format("YYYY-MM-DD"),
    endDate: moment().endOf('day').format("YYYY-MM-DD")
  },
  {
    label: 'Mês Passado',
    type: 'prev_month',
    startDate: moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD"),
    endDate: moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD")
  },
  {
    label: 'Customizado',
    type: 'custom',
    startDate: '',
    endDate: ''
  }
]

const defaultFilter = {
  label: 'Ontem',
  type: 'yesterday',
  startDate: moment().subtract(1, 'day').startOf('day').format("YYYY-MM-DD"),
  endDate: moment().subtract(1, 'day').endOf('day').format("YYYY-MM-DD"),
}



const SalesNonSplit: React.FC = () => {
  const { addToast } = useToast();
  //PAGE VALUES
  const [loading, setLoading] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [serialList, setSerialList] = useState<ISerialList>();
  const [salesList, setSalesList] = useState<ISalesList>({} as ISalesList);
  const [brandList, setBrandList] = useState<IBrandList>();
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);


  const [dateFilterType, setDateFilterType] = useState<Filter>(defaultFilter);

  const [serialNumber, setSerialNumber] = useState("todos");
  const [cancelledTransactions, setCancelledTransactions] =
    useState<ITransactionsStatus>({
      gross_amount: 0,
      net_amount: 0,
      quantity: 0,
    });
  const [receivableTransactions, setReceivableTransactions] =
    useState<ITransactionsStatus>({
      gross_amount: 0,
      net_amount: 0,
      quantity: 0,
    });
  const [capturedTransactions, setCapturedTransactions] =
    useState<ITransactionsStatus>({
      gross_amount: 0,
      net_amount: 0,
      quantity: 0,
    });

  const [salesStatus, setsalesStatus] = useState("todos");
  const [salesType, setSalesType] = useState("todos");
  const [salesID, setSalesID] = useState("");
  const [brandName, setBrandName] = useState("Todas");
  const [brandId, setBrandId] = useState(0);
  const [link, setLink] = useState<ICompany>({
    style: { primary_color: "#000" },
  } as ICompany);

  const getTransactionData = async () => {
    try {
      setLoadingExcel(true);

      const { startDate, endDate } = dateFilterType;
      await api
        .get("/vendas", {
          responseType: "blob",
          params: {
            perpage: pageSize,
            orderby: "date",
            orderdirection: "desc",
            datestart: startDate,
            brandid: brandId !== 0 ? brandId : null,
            dateend: endDate,
            serialnumber: serialNumber !== "todos" ? serialNumber : null,
            status: salesStatus !== "todos" ? salesStatus : null,
            typesale: salesType !== "todos" ? salesType : null,
            id: salesID ? salesID : null,
            download: 1,
            typefile: "excel",
          },
        })
        .then((response) => {
          downloadFile(
            `vendas_${startDate}_a_${endDate}.xlsx`,
            response
          );
        })
        .finally(() => {
          setLoadingExcel(false);
        });
    } catch (err) {
      addToast({
        type: "error",
        title: "Erro ao baixar informações",
        description:
          "Ocorreu um erro ao baixar a planilha, tente novamente mais tarde.",
      });
    }
  };

  const handlePageChange = useCallback(
    (value) => {
      if (value <= 0 || value > totalPages) return;

      setPage(value);
      try {
        const { startDate, endDate } = dateFilterType;
        setLoading(true);
        api
          .get("/vendas", {
            params: {
              perpage: pageSize,
              orderby: "date",
              orderdirection: "desc",
              datestart: startDate,
              dateend: endDate,
              brandid: brandId !== 0 ? brandId : null,
              serialnumber: serialNumber !== "todos" ? serialNumber : null,
              status: salesStatus !== "todos" ? salesStatus : null,
              typesale: salesType !== "todos" ? salesType : null,
              page: value,
              id: salesID ? salesID : null,
            },
          })
          .then((response) => {
            setSalesList(response.data);
            api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
          })
          .finally(() => setLoading(false));
      } catch (err) {
        addToast({
          type: "error",
          title: "Erro ao atualizar informações",
          description:
            "Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
        });
      }
    },
    [
      pageSize,
      addToast,
      dateFilterType,
      salesStatus,
      salesType,
      salesID,
      serialNumber,
      totalPages,
      brandId,
    ]
  );

  //FILTER
  const fetchAPI = useCallback(() => {
    try {
      const { startDate, endDate } = dateFilterType
      setLoading(true);
      api
        .get("/vendas", {
          params: {
            perpage: pageSize,
            orderby: "date",
            orderdirection: "desc",
            datestart: startDate,
            dateend: endDate,
            brandid: brandId !== 0 ? brandId : null,
            serialnumber: serialNumber !== "todos" ? serialNumber : null,
            status: salesStatus !== "todos" ? salesStatus : null,
            typesale: salesType !== "todos" ? salesType : null,
            page: 1,
            id: salesID ? salesID : null,
          },
        })
        .then((response) => {
          api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
          setSalesList(response.data);
          setPage(response.data.result.Page);
          if (response.data.result.TotalPage === 0) setPage(0);
          setTotalPages(response.data.result.TotalPage);
          setPageSize(response.data.result.pageSize);
          setCancelledTransactions(response.data.result.summary.cancelled);
          setReceivableTransactions(response.data.result.summary.compensated);
          setCapturedTransactions(response.data.result.summary.captured);
        })
        .finally(() => setLoading(false));
    } catch (err) {
      addToast({
        type: "error",
        title: "Erro ao atualizar informações",
        description:
          "Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
      });
    }
  }, [
    addToast,
    pageSize,
    dateFilterType,
    salesStatus,
    salesType,
    salesID,
    serialNumber,
    brandId,
  ]);


  useEffect(() => {
    fetchAPI();
  }, [])
  //FIRST CALL
  useEffect(() => {
    setLink(generateTheme);
    try {
      api.get("/vendas/serial").then((response) => {
        setSerialList(response.data);
      });

      api.get("/vendas/bandeiras").then((response) => {
        setBrandList(response.data);
      });
    } catch (err) {
      addToast({
        type: "error",
        title: "Erro ao atualizar informações",
        description:
          "Ocorreu um erro ao atualizar suas informações, tente novamente mais tarde.",
      });
    }
  }, [addToast, pageSize]);
  return (
    <>
      <FilterSection primary_color={link?.style.primary_color}>
        <p style={{ fontWeight: 900, textTransform: "uppercase" }}>
          Filtre suas vendas
        </p>

        <div className="multipleFilters">
          <Filter>
            <p>Filtrar Extrato</p>
            <select
              value={dateFilterType.type}
              onChange={e => {
                const type = e.target.value;

                const filter = filters.find(x => x.type == type);

                if (!filter) return;

                setDateFilterType({ ...filter });
              }}
            >
              {
                filters.map(x => (
                  <option key={x.type} value={x.type}>{x.label}</option>
                ))
              }
            </select>
          </Filter>
          <Filter>
            <p>Número de Série </p>
            <select
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.currentTarget.value)}
            >
              <option value={"todos"}>Todos</option>
              {serialList &&
                serialList.result.map((value) => (
                  <option
                    key={value.serial_number}
                    value={value.serial_number}
                  >
                    {value.serial_number}
                  </option>
                ))}
            </select>
          </Filter>
          <Filter>
            <p>Status do Pagamento </p>
            <select
              value={salesStatus}
              onChange={(e) => setsalesStatus(e.currentTarget.value)}
            >
              <option value={"todos"}>Todos</option>
              <option value={"receivable"}>A receber</option>
              <option value={"compensated"}>Compensado</option>
              <option value={"antecipated"}>Antecipado</option>
              <option value={"cancelled"}>Cancelado</option>
            </select>
          </Filter>
          <Filter>
            <p>Tipo de Venda </p>
            <select
              value={salesType}
              onChange={(e) => setSalesType(e.currentTarget.value)}
            >
              <option value={"todos"}>Todos</option>
              <option value={"debit"}>Débito</option>
              <option value={"credit"}>Crédito</option>
              <option value={"recharge"}>Recarga</option>
            </select>
          </Filter>
          <Filter>
            <p>ID da venda </p>
            <form>
              <input
                value={salesID}
                onChange={(e) => setSalesID(e.currentTarget.value)}
              ></input>
            </form>
          </Filter>
          <Filter>
            <p>Bandeira </p>
            <select
              value={brandName}
              onChange={(e) => {
                let selectedIndex: number = e.target.options.selectedIndex;
                if (
                  e.target.options[selectedIndex].getAttribute("data-key")
                ) {
                  const id =
                    e.target.options[selectedIndex].getAttribute("data-key");
                  if (id) setBrandId(parseInt(id));
                }

                setBrandName(e.currentTarget.value);
              }}
            >
              <option data-key={0} value={"todos"}>
                Todas
              </option>
              {brandList &&
                brandList.result.map((value) => (
                  <option
                    data-key={value.brand_id}
                    key={value.brand_id}
                    value={value.name}
                  >
                    {value.name}
                  </option>
                ))}
            </select>
          </Filter>
        </div>
        <div className="filtersButton">
          {dateFilterType.type === 'custom' && (
            <div className="customDate">
              <div>
                <label>Data inicial</label>
                <input
                  type="date"
                  value={dateFilterType.startDate}
                  onChange={x => setDateFilterType(o => ({ ...o, startDate: x.target.value }))}
                />
              </div>
              <div>
                <label>Data final</label>
                <input
                  type="date"
                  value={dateFilterType.endDate}
                  onChange={x => setDateFilterType(o => ({ ...o, endDate: x.target.value }))}
                />
              </div>
            </div>
          )}
          <Button onClick={() => fetchAPI()} style={{ marginTop: "16px" }}>
            {" "}
            Filtrar
          </Button>
        </div>
      </FilterSection>
      <ModuleInfo>
        <TransactionInfoCard
          title={"Confirmado"}
          quantity={capturedTransactions.quantity}
          gross_amount={capturedTransactions.gross_amount}
          net_amount={capturedTransactions.net_amount}
          loading={loading}
          primary_color={link?.style.primary_color}
        />
        <TransactionInfoCard
          title={"Recebido"}
          quantity={receivableTransactions.quantity}
          gross_amount={receivableTransactions.gross_amount}
          net_amount={receivableTransactions.net_amount}
          loading={loading}
          primary_color={link?.style.primary_color}
        />
        <TransactionInfoCard
          title={"Cancelado"}
          quantity={cancelledTransactions.quantity}
          gross_amount={cancelledTransactions.gross_amount}
          net_amount={cancelledTransactions.net_amount}
          loading={loading}
          primary_color={link?.style.primary_color}
        />
      </ModuleInfo>
      <SalesContent primary_color={link?.style.primary_color}>
        <div className="exportExcel">
          <p>Últimas vendas: {dateFilterType.label}</p>
          <Button
            className="btnExcel"
            loading={loadingExcel}
            onClick={getTransactionData}
          >
            Exportar Excel <AiOutlineDownload />
          </Button>
        </div>
        <TableTransactions salesList={salesList}>
          <div className="btnForPages">
            <button
              onClick={() => {
                if (page !== 1) handlePageChange(1);
              }}
              className={page === 1 ? "btnDefault" : "btnNext"}
            >
              <BiFirstPage /> <p>Primeira Página</p>
            </button>

            <button
              onClick={() => handlePageChange(page - 1)}
              className={page === 1 ? "btnDefault" : "btnNext"}
            >
              <GrFormPrevious /> <p>Anterior</p>
            </button>

            <p>
              Página <span>{page}</span> de <span>{totalPages}</span>{" "}
            </p>

            <button
              onClick={() => handlePageChange(page + 1)}
              className={totalPages === page ? "btnDefault" : "btnNext"}
              disabled={loading}
            >
              <p>Próximo</p>
              <GrFormNext />
              {/* <LoaderSpinner /> */}
            </button>
            <button
              onClick={() => {
                if (page !== totalPages) handlePageChange(totalPages);
              }}
              className={totalPages === page ? "btnDefault" : "btnNext"}
            >
              <p>Última página</p> <BiLastPage />
            </button>
          </div>
        </TableTransactions>
      </SalesContent>
    </>
  );
};

interface ITransactionInfoCardProps {
  title: string;
  quantity: number;
  gross_amount: number;
  net_amount: number;
  loading: boolean;
  primary_color: string;
}

const TransactionInfoCard = (props: ITransactionInfoCardProps) => {
  const { title, gross_amount, net_amount, quantity, loading, primary_color } =
    props;

  if (loading) {
    return (
      <SectionTransaction>
        <Skeleton width="100%" height={133} animation="wave" />
      </SectionTransaction>
    );
  }

  return (
    <SectionTransaction primary_color={primary_color}>
      <div className="titleTransactions">
        <p>{title}</p>
        {quantity <= 1 ? <p>({quantity} venda)</p> : <p>({quantity} vendas)</p>}
      </div>
      <div className="valueTransactions">
        <p>
          {formatValue(gross_amount)}
          <AiOutlineInfoCircle title="valor bruto" />
        </p>
        <p>
          {formatValue(net_amount)}
          <AiOutlineInfoCircle title="valor líquido" />
        </p>
      </div>
    </SectionTransaction>
  );
};
export default SalesNonSplit;
