import styled, { css } from "styled-components";

interface IInstallmentsPaidProps {
	paid: number;
	cancelled?: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: space-between;
	height: 100vh;
`;

export const Content = styled.div`
	max-width: 1273px;
	margin: auto;
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
	padding: 44px;
	@media (max-width: 500px) {
		padding: 24px;
	}

	.exportExcel {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		p {
			font-size: 14px;
			color: #8094ae;
		}
		.btnExcel {
			svg {
				margin-left: 10px;
			}

			:hover {
				background: #ccd3e5;
				color: #003967;
			}
		}
	}
`;

export const ModuleInfo = styled.div`
	display: flex;
	justify-content: space-between;

	@media (max-width: 1045px) {
		flex-direction: column;
	}
`;

export const FilterSection = styled.div`
	display: flex;
	margin-bottom: 20px;
	flex-wrap: wrap;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	background: #fff;
	border: 0px;
	border-radius: 4px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);
	padding: 5px 0;

	button {
		width: fit-content;
	}
`;

export const CreateLink = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;

	.title {
		font-size: 16px;
		font-weight: 900;
		color: #526484;
		margin-bottom: 10px;
	}

	p {
		margin-bottom: 10px;
	}

	button {
		border: 0px;
		padding: 5px 10px;
		padding-right: 0px;
		border-radius: 10px;
		cursor: pointer;
		transition: 0.2s ease-in-out;
		display: flex;
		align-items: center;

		svg {
			margin: 0px 10px;
			font-size: 18px;
		}
	}
`;

export const Filter = styled.div`
	position: relative;
	width: 100%;
	padding: 16px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	color: #8094ae;

	.customFilters {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-top: 10px;

		@media (max-width: 645px) {
			div {
				margin-top: 10px;
				width: 100%;
			}
		}
	}

	span {
		font-size: 14px;
		margin-bottom: 5px;
		color: #8094ae;
		width: 100%;
		font-weight: 900;
		text-transform: uppercase;
	}

	p {
		text-transform: none;
		font-size: 14px;
		margin-bottom: 5px;
		font-weight: 500;
	}

	input[type="date"] {
		width: 100%;
		color: #8094ae;
		background-color: #fff;
		box-shadow: 0 1px 4px rgb(31 43 58 / 15%);
		border: 0;
		height: 56px;
		padding: 0 5px;
		margin-top: 5px;
		border-radius: 10px;
		text-align: center;
	}

	input[type="date"]::-webkit-calendar-picker-indicator {
		transition: 0.25s ease-in-out;
		filter: none;
		font-weight: bolder;
		outline: none;

		:hover {
			cursor: pointer;
			filter: invert(0.5) sepia(0.5) saturate(5) hue-rotate(152deg);
		}
	}

	.customDate {
		font-size: 14px;
		color: #8094ae;
		display: flex;
		flex-direction: column;
		div {
			flex-direction: row;
			width: 100%;
			display: flex;
			margin-top: 10px;
			flex: 1;

			input {
				margin: 0;
			}
			label {
				align-self: center;
				width: 30%;
			}
		}
	}

	.customDescription {
		div {
			display: flex;
			padding: 5px;
			width: 320px;
			border-radius: 10px;
			box-shadow: 0 1px 4px rgb(31 43 58 / 15%);
			border: 0;
			color: #8094ae;
			cursor: pointer;

			:hover {
				svg {
					cursor: pointer;
					filter: invert(0.5) sepia(0.5) saturate(5) hue-rotate(152deg);
				}
			}

			svg {
				align-self: center;
				width: 18px;
				height: 18px;
				margin-right: 10px;
			}

			input[type="text"] {
				position: relative;
				padding: 5px;
				cursor: pointer;
				border: 0;
				width: 220px;
				width: 250px;
				color: #8094ae;
			}
		}
		@media (max-width: 645px) {
			div {
				margin-top: 10px;
				width: 100%;
			}
		}
	}
	.customActivation {
		select {
			background: white;
			color: #3c4d62;
			align-self: center;
			width: 100%;
			cursor: pointer;
			background-color: #fff;
			display: flex;
			height: 56px;
			padding: 0.35rem 1rem;
			border-radius: 10px;
			border: 0;
			box-shadow: 0 1px 4px rgb(31 43 58 / 15%);
		}
	}

	.expirationSection {
		font-size: 14px;
		color: #8094ae;
		display: flex;
		flex-direction: column;

		.expirationDate {
			font-size: 14px;
			color: #8094ae;
			display: flex;
			flex-direction: column;
			div {
				margin-top: 10px;
				flex-direction: row;
				width: 100%;
				display: flex;
				flex: 1;

				input {
					margin: 0;
				}
				label {
					align-self: center;
					width: 30%;
				}
			}
		}

		.hasExpiration {
			align-items: center;

			input,
			label {
				cursor: pointer;
			}

			label {
				width: 100%;
				margin-left: 5px;
				align-self: center;
			}
		}
	}

	button {
		background: #ccd3e5;
		border: 1px solid #c3cce1;
		color: #003967;
		border: 0px;
		padding: 5px 10px;
		border-radius: 10px;
		cursor: pointer;
		transition: 0.2s ease-in-out;

		:hover {
			background: #526484;
			color: #f7f8fc;
		}
	}
`;

export const ModuleLink = styled.div`
	display: flex;
	width: 100%;
	margin-right: 20px;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px 20px;
	background: #fff;
	border: 0px;
	border-radius: 10px;
	box-shadow: 0 1px 4px rgba(31, 43, 58, 0.15);

	button {
		background: #f7f8fc;
		border: 1px solid #c3cce1;
		font-size: 12px;
		color: #526484;
		border: 0px;
		padding: 5px 10px;
		border-radius: 10px;
		cursor: pointer;
		transition: 0.2s ease-in-out;
		display: flex;
		align-items: center;

		svg {
			margin: 0px;
			font-size: 15px;
		}
	}
	.btnForPages {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
		font-size: 12px;
		color: #526484;

		.btnPrevious,
		.btnNext {
			:hover {
				background: #ccd3e5;
				color: #003967;
			}
		}

		.btnDefault {
			cursor: default;
		}

		@media (max-width: 500px) {
			svg {
				padding: 0;
				margin: 0;
			}

			.btnPrevious,
			.btnNext,
			.btnDefault {
				p {
					display: none;
				}
			}
		}
	}
`;

export const LinksContent = styled.div`
	@media (max-width: 1250px) {
		overflow-x: auto;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
		table-layout: auto;
		width: 100%;
		font-size: 14px;

		@media (max-width: 568px) {
			font-size: 12px;
		}

		td,
		th {
			text-align: center;
			padding: 20px;
			font-weight: lighter;
		}

		tr {
			height: 50px;
			border-bottom: 1px solid #e5e9f2;

			:first-child {
				border-bottom: 1px solid #526484;
			}

			:nth-child(n + 2) {
				cursor: pointer;
				:hover {
					font-weight: 900;
				}
			}

			:last-child {
				border: 0px;
				margin-bottom: 20px;
			}
		}

		td {
			:first-child {
				position: relative;

				span:first-child {
					content: "";
					position: absolute;
					top: 50%;
					left: 5px;
					transform: translateY(-50%);
					width: 7px;
					height: 7px;
					border-radius: 50%;
				}

				span:last-child {
					margin-left: 10px;
				}
			}
		}
	}
`;

export const InstallmentsPaid = styled.p<IInstallmentsPaidProps>`
	width: 100%;
	content: "";
	position: absolute;
	height: 25%;
	top: 22px;
	left: 10px;
	border: 0px;
	border-radius: 20px;

	${(props) =>
		!props.cancelled
			? css`
					background: #ddd;
			  `
			: css`
					background: #e85347;
			  `}

	:after {
		${(props) =>
			props.paid &&
			css`
				width: ${props.paid}%;
			`}

		content:'';
		position: absolute;
		height: 100%;
		top: 0px;
		left: 0px;
		border: 0px;
		border-radius: 20px;
		background: #526484;
	}
`;
